import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [flag, setFlag] = useState(false);
  const [flag1, setFlag1] = useState(false);
  const [filters, setFilters] = useState({
    dealerId: "",
    priceBookId: [],
    categoryId: "",
  });
  const [filtersCategoryTab1, setFiltersCategoryTab] = useState({
    dealerId: "",
    priceBookId: [],
    categoryId: "",
  });
  const [filtersClaimCategory, setFiltersClaimCategory] = useState({
    dealerId: "",
    priceBookId: [],
    categoryId: "",
    servicer: "",
    primary: "category",
  });
  const [filtersClaimServicer, setFiltersClaimServicer] = useState({
    dealerId: "",
    priceBookId: [],
    categoryId: "",
    servicer: "",
    primary: "servicer",
  });
  const [filtersClaimDealer, setFiltersClaimDealer] = useState({
    dealerId: "",
    priceBookId: [],
    categoryId: "",
    servicer: "",
    primary: "dealer",
  });

  const location = useLocation();
  const [routeHistory, setRouteHistory] = useState(() => {
    const savedHistory = localStorage.getItem("routeHistory");
    return savedHistory ? JSON.parse(savedHistory) : [];
  });


  // Load route history from localStorage when the component mounts
  useEffect(() => {
    const savedHistory = localStorage.getItem("routeHistory");
    console.log(savedHistory,'-----------------')
    if (savedHistory) {
      setRouteHistory(JSON.parse(savedHistory));
    }
  }, []);

  // Update route history whenever the location changes
  useEffect(() => {
    setRouteHistory((prevHistory) => {
      const updatedHistory = [...prevHistory, location.pathname];
      if (updatedHistory.length > 500) {
        updatedHistory.shift();
      }
      localStorage.setItem("routeHistory", JSON.stringify(updatedHistory));

      return updatedHistory;
    });
  }, [location]);

  const toggleFlag = () => {
    setFlag((prevFlag) => !prevFlag);
  };

  const toggleFilterFlag = () => {
    setFlag1((prevFlag) => !prevFlag);
  };

  const setAppliedFilters = (newFilters) => {
    setFlag1(true);
    setFilters(newFilters);
  };
  const setFiltersForCategory = (newFilters) => {
    console.log("newFilters", newFilters);
    setFlag1(true);
    setFiltersCategoryTab(newFilters);
  };
  const setFiltersForClaimCategory = (newFilters) => {
    setFlag1(true);
    setFiltersClaimCategory(newFilters);
  };
  const setFiltersForClaimDealer = (newFilters) => {
    setFiltersClaimDealer(newFilters);
    setFlag1(true);
  };
  const setFiltersForClaimServicer = (newFilters) => {
    setFlag1(true);
    console.log(newFilters);
    setFiltersClaimServicer(newFilters);
  };

  const resetAllFilters = () => {
    setFlag1(false);
    setFilters({
      dealerId: "",
      priceBookId: [],
      categoryId: "",
    });
    setFiltersCategoryTab({
      dealerId: "",
      priceBookId: [],
      categoryId: "",
    });
    setFiltersClaimCategory({
      dealerId: "",
      priceBookId: [],
      categoryId: "",
      servicer: "",
      primary: "category",
    });
    setFiltersClaimServicer({
      dealerId: "",
      priceBookId: [],
      categoryId: "",
      servicer: "",
      primary: "servicer",
    });
    setFiltersClaimDealer({
      dealerId: "",
      priceBookId: [],
      categoryId: "",
      servicer: "",
      primary: "dealer",
    });
  };

  // Function to get the second-to-last visited route
  const getSecondToLastRoute = () => routeHistory[routeHistory.length - 2] || null;
  const getFourthToLastRoute = () => routeHistory[routeHistory.length - 4] || null;
  const getSixthToLastRoute = () => routeHistory[routeHistory.length - 6] || null;
  const routeHistoryStack = () => routeHistory;

  return (
    <MyContext.Provider
      value={{
        flag,
        toggleFlag,
        filters,
        setAppliedFilters,
        flag1,
        filtersCategoryTab1,
        filtersClaimCategory,
        filtersClaimServicer,
        filtersClaimDealer,
        toggleFilterFlag,
        setFiltersForCategory,
        setFiltersForClaimServicer,
        setFiltersForClaimDealer,
        setFiltersForClaimCategory,
        resetAllFilters,
        routeHistory,
        getSecondToLastRoute,
        getFourthToLastRoute,
        getSixthToLastRoute,
        routeHistoryStack,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => {
  return useContext(MyContext);
};