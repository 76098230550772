import React, { useState } from "react";
import Button from "../../../common/button";
import Grid from "../../../common/grid";
import Input from "../../../common/input";

// Media Includes
import BackImage from "../../../assets/images/icons/backArrow.svg";
import claimed from "../../../assets/images/contract/Claimed.svg";
import status from "../../../assets/images/contract/Status.svg";
import labourWarranty from "../../../assets/images/contract/Labour Warranty.svg";
import partsWarranty from "../../../assets/images/contract/Part Warranty.svg";
import Purchase from "../../../assets/images/contract/Purchase.svg";
import coverageStartDate from "../../../assets/images/contract/Coverage Start.svg";
import coverageEndDate from "../../../assets/images/contract/Coverage End.svg";
import category1 from "../../../assets/images/contract/OrderID.svg";
import claim from "../../../assets/images/icons/claimAmount.svg";
import contract from "../../../assets/images/contract/ContractID.svg";
import Eligibility from "../../../assets/images/contract/Eligible.svg";
import resellerName from "../../../assets/images/contract/reseller.svg";
import delaerName from "../../../assets/images/contract/Dealer.svg";
import ServicerName from "../../../assets/images/contract/Servicer.svg";
import CustomerName from "../../../assets/images/contract/Customer.svg";
import DealerPO from "../../../assets/images/contract/DealerPO.svg";
import AddDealer from "../../../assets/images/dealer-book.svg";
import Headbar from "../../../common/headBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  editContractById,
  getContractValues,
} from "../../../services/extraServices";
import { useEffect } from "react";
import { RotateLoader } from "react-spinners";
import SingleView from "../../../common/singleView";
import Modal from "../../../common/model";
import InActiveButton from "../../../common/inActiveButton";
import Card from "../../../common/card";
import { useMyContext } from "../../../context/context";
function EditContract() {
  const [showTooltip, setShowTooltip] = useState(false);
  const [contractDetails, setContractDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [timer, setTimer] = useState(3);
  const { id } = useParams();

  console.log(id);
  useEffect(() => {
    let intervalId;
    if (isModalOpen && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (timer === 0) {
      closeModal();
    }
    return () => clearInterval(intervalId);
  }, [isModalOpen, timer]);

  const closeModal = () => {
    setIsModalOpen(false);
    navigate(`/contractDetails/${id}`);
  };

  const validationSchema = Yup.object().shape({
    manufacture: Yup.string().trim().required("Required"),
    model: Yup.string().trim().required("Required"),
    serial: Yup.string().trim().required("Required"),
    productValue: Yup.number()
      .typeError("Must be a number")
      .required("Required")
      .min(1, "Product value must be at least 1"),
    condition: Yup.string().trim().required("Required"),
    coverageStartDate: Yup.date().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      manufacture: "",
      model: "",
      serial: "",
      productValue: "",
      condition: "",
      coverageStartDate: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const res = await editContractById(id, values);
        console.log(res);
        setIsModalOpen(true);

      } catch (error) {
        setLoading(false);
        console.error("Error editing contract:", error);
      } finally {
        setLoading(false);
      }
    },
  });
  const getContractDetails = async () => {
    setLoading(true);
    const result = await getContractValues(id);
    setContractDetails(result.result);
    // console.log()
    formik.setValues({
      manufacture: result.result.manufacture || "",
      model: result.result.model || "",
      serial: result.result.serial || "",
      productValue: result.result.productValue || "",
      condition: result.result.condition || "",
      coverageStartDate:
        result.result.order[0].productsArray[0].coverageStartDate1 || "",
    });
    console.log(result.result);
    setLoading(false);
  };

  useEffect(() => {
    getContractDetails();
  }, []);

  //add this
  const { getSecondToLastRoute, getFourthToLastRoute } = useMyContext();
  const secondToLastRoute = getSecondToLastRoute();
  const fourthToLastRoute = getFourthToLastRoute()
  const handleGOBack = () => {

    console.log(secondToLastRoute)
    // localStorage.removeItem("contractMenu");
    // // window.location.href = path;
    navigate(secondToLastRoute);
  };
  return (
    <>
      <div className="mb-8 ml-3">
        <Headbar />

        <div className="flex mt-2">
          <div
            onClick={handleGOBack}
            className="h-[60px] w-[60px] flex border-[1px] bg-white border-Light-Grey rounded-[20px]"
          >
            <img
              src={BackImage}
              className="m-auto my-auto self-center bg-white"
              alt="BackImage"
            />{" "}
          </div>
          <div className="pl-3">
            <p className="font-bold text-[36px] leading-9	mb-[3px]">
              {" "}
              Edit Contracts
            </p>
            <ul className="flex self-center">
              <li className="text-sm  font-Regular">
                <Link to={"/"}>Contracts /</Link>{" "}
              </li>
              <li className="text-sm  font-semibold ml-1">
                Edit Contracts
              </li>
            </ul>
          </div>
        </div>
        {loading ? (
          <div className=" h-[400px] w-full flex py-5">
            <div className="self-center mx-auto">
              <RotateLoader color="#333" />
            </div>
          </div>
        ) : (
          <>
            <SingleView className=" px-8 mt-8 mr-4 py-16 rounded-[30px]">
              <Grid className="mx-auto ">
                <div className="col-span-3 self-center py-2 border-r border-[#4e4e4e]">
                  <div className="flex">
                    <div className="self-center bg-[#FFFFFF08] backdrop-blur border-[#D1D9E24D] border rounded-[30%] p-3 mr-4">
                      <img
                        src={contract}
                        className="w-[22px] h-[24px]"
                        alt="category"
                      />
                    </div>
                    <div className="self-center">
                      <p className="text-base font-medium leading-5	">
                        Contract ID
                      </p>
                      <p className="opacity-50 text-sm	font-medium">
                        {contractDetails?.unique_key}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 self-center py-2 border-r border-[#4e4e4e]">
                  <div className="flex">
                    <div className="self-center bg-[#FFFFFF08] backdrop-blur  mr-4">
                      <img src={category1} alt="dealer" />
                    </div>
                    <div className="self-center">
                      <p className="text-base font-medium leading-5	">
                        Order ID
                      </p>
                      <p className=" opacity-50 text-sm	font-medium">
                        {contractDetails?.order?.[0]?.unique_key}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 self-center">
                  <div className="flex w-full py-2 border-r border-[#4e4e4e]">
                    <div className="self-center backdrop-blur  mr-4">
                      <img src={DealerPO} alt="terms" />
                    </div>
                    <div className="self-center">
                      <p className=" text-base font-medium leading-5">
                        Dealer P.O. #.
                      </p>
                      <p className=" opacity-50	text-sm font-medium">
                        {contractDetails?.order?.[0]?.venderOrder}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 self-center ">
                  <div className="flex">
                    <div className="self-center backdrop-blur  mr-4">
                      <img src={status} alt="product" />
                    </div>
                    <div className="self-center">
                      <p className=" text-base font-medium leading-5	">
                        Status
                      </p>
                      <p className=" opacity-50 text-sm	font-medium">
                        {contractDetails?.status}
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid className=" mx-auto border-t border-[#4e4e4e]">
                <div className="col-span-3 self-center">
                  <div className="flex py-2 border-r border-[#4e4e4e]">
                    <div className="self-center backdrop-blur  mr-4">
                      <img src={delaerName} alt="product" />
                    </div>
                    <div className="self-center">
                      <p className=" text-base font-medium leading-5	">
                        Dealer Name
                      </p>
                      <p className=" opacity-50 text-sm	font-medium">
                        {contractDetails?.order?.[0]?.dealer?.[0]?.name}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 self-center ">
                  <div className="flex w-full py-2 border-r border-[#4e4e4e]">
                    <div className="self-center bg-[#FFFFFF08] backdrop-blur border-[#D1D9E24D] border rounded-[30%] p-3 mr-4">
                      <img
                        src={resellerName}
                        className="w-[24px] h-[24px]"
                        alt="terms"
                      />
                    </div>
                    <div className="self-center">
                      <p className=" text-base font-medium leading-5">
                        Reseller Name
                      </p>
                      <p className=" opacity-50	text-sm font-medium">
                        {contractDetails?.order?.[0]?.reseller?.[0]?.name}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 self-center">
                  <div className="flex w-full py-2 border-r border-[#4e4e4e]">
                    <div className="self-center backdrop-blur  mr-4">
                      <img src={CustomerName} alt="category" />
                    </div>
                    <div className="self-center">
                      <p className=" text-base font-medium leading-5	">
                        Customer Name
                      </p>
                      <p className=" opacity-50 text-sm	font-medium">
                        {contractDetails?.order?.[0]?.customer?.[0]?.username}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 self-center">
                  <div className="flex w-full py-2">
                    <div className="self-center bg-[#FFFFFF08] backdrop-blur border-[#D1D9E24D] border rounded-[30%] p-3 mr-4">
                      <img
                        src={ServicerName}
                        className="w-[24px] h-[24px]"
                        alt="dealer"
                      />
                    </div>
                    <div className="self-center">
                      <p className=" text-base font-medium leading-5	">
                        Servicer Name
                      </p>
                      <p className=" opacity-50 text-sm	font-medium">
                        {contractDetails?.order?.[0]?.servicer?.[0]?.name}
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid className="mx-auto border-t border-[#4e4e4e] ">
                <div className="col-span-3 self-center">
                  <div className="flex w-full py-2 border-r border-[#4e4e4e]">
                    <div className="self-center backdrop-blur  mr-4">
                      <img src={coverageStartDate} alt="category" />
                    </div>
                    <div className="self-center">
                      <p className=" text-base font-medium leading-5	">
                        Coverage Start Date
                      </p>
                      <p className=" opacity-50 text-sm	font-medium">
                        {new Date(
                          contractDetails?.order?.[0]?.productsArray?.[0]?.coverageStartDate1
                        ).toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 self-center">
                  <div className="flex w-full py-2 border-r border-[#4e4e4e]">
                    <div className="self-center backdrop-blur   mr-4">
                      <img src={coverageEndDate} alt="dealer" />
                    </div>
                    <div className="self-center">
                      <p className=" text-base font-medium leading-5	">
                        Coverage End Date
                      </p>
                      <p className=" opacity-50 text-sm	font-medium">
                        {new Date(
                          contractDetails?.order?.[0]?.productsArray?.[0]?.coverageEndDate1
                        ).toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 self-center">
                  <div className="flex w-full py-2 border-r border-[#4e4e4e]">
                    <div className="self-center backdrop-blur   mr-4">
                      <img src={Purchase} alt="dealer" />
                    </div>
                    <div className="self-center">
                      <p className=" text-base font-medium leading-5	">
                        Purchase Date
                      </p>
                      <p className=" opacity-50 text-sm	font-medium">
                        {new Date(
                          contractDetails?.purchaseDate
                        ).toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 self-center">
                  <div className="flex w-full py-2 border-[#4e4e4e]">
                    <div className="self-center bg-[#FFFFFF08] backdrop-blur border-[#D1D9E24D] border rounded-[30%] p-3 mr-4">
                      <img
                        src={claimed}
                        className="w-[24px] h-[24px]"
                        alt="terms"
                      />
                    </div>
                    <div className="self-center">
                      <p className=" text-base font-medium leading-5">
                        Claimed Value
                      </p>
                      <p className=" opacity-50	text-sm font-medium">
                        $
                        {parseInt(contractDetails?.claimAmount).toLocaleString(
                          2
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid className="mx-auto border-t border-[#4e4e4e] ">
                <div className="col-span-3 self-center">
                  <div className="flex w-full py-2 border-r border-[#4e4e4e]">
                    <div className="self-center backdrop-blur   mr-4">
                      <img src={labourWarranty} alt="dealer" />
                    </div>
                    <div className="self-center">
                      <p className=" text-base font-medium leading-5	">
                        Labour Warranty <br /> Start Date
                      </p>
                      <p className=" opacity-50 text-sm	font-medium">
                        {new Date(
                          contractDetails?.labourWarranty
                        ).toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 self-center">
                  <div className="flex w-full py-2 border-r border-[#4e4e4e]">
                    <div className="self-center backdrop-blur   mr-4">
                      <img src={partsWarranty} alt="dealer" />
                    </div>
                    <div className="self-center">
                      <p className=" text-base font-medium leading-5	">
                        Part Warranty <br /> Start Date
                      </p>
                      <p className=" opacity-50 text-sm	font-medium">
                        {new Date(
                          contractDetails?.partsWarranty
                        ).toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 self-center py-2">
                  <div className="flex">
                    <div className="self-center backdrop-blur  mr-4">
                      <img src={Eligibility} alt="product" />
                    </div>
                    <div className="self-center">
                      <p className=" text-base font-medium leading-5	">
                        Eligibility
                      </p>
                      <p className=" opacity-50 text-sm	font-medium">
                        {contractDetails?.eligibilty === true
                          ? "Eligible"
                          : "Not Eligible "}
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </SingleView>

            <form className="mt-8 mr-4" onSubmit={formik.handleSubmit}>
              <Card className="px-8 pb-8 pt-6 drop-shadow-4xl   border-[1px] border-Light-Grey  rounded-3xl">
                <p className="pb-5 text-lg font-semibold">Contracts</p>
                <Grid className="!grid-cols-5">
                  <div className="col-span-1">
                    <Input
                      type="text"
                      name="manufacture"
                      className="!bg-white"
                      label="Manufacturer"
                      required={true}
                      placeholder=""
                      value={formik.values.manufacture}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.manufacture && formik.errors.manufacture
                      }
                    />
                    {formik.touched.manufacture &&
                      formik.errors.manufacture && (
                        <div className="text-red-500 text-sm pl-2 pt-2">
                          {formik.errors.manufacture}
                        </div>
                      )}
                  </div>
                  <div className="col-span-1">
                    <Input
                      type="text"
                      name="model"
                      className="!bg-white"
                      label="Model"
                      required={true}
                      placeholder=""
                      value={formik.values.model}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.model && formik.errors.model}
                    />
                    {formik.touched.model && formik.errors.model && (
                      <div className="text-red-500 text-sm pl-2 pt-2">
                        {formik.errors.model}
                      </div>
                    )}
                  </div>
                  <div className="col-span-1">
                    <Input
                      type="text"
                      name="serial"
                      className="!bg-white"
                      label="Serial"
                      required={true}
                      placeholder=""
                      value={formik.values.serial}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.serial && formik.errors.serial}
                    />
                    {formik.touched.serial && formik.errors.serial && (
                      <div className="text-red-500 text-sm pl-2 pt-2">
                        {formik.errors.serial}
                      </div>
                    )}
                  </div>

                  <div className="col-span-1">
                    <Input
                      type="number"
                      name="productValue"
                      className="!bg-white"
                      label="RetailPrice"
                      required={true}
                      placeholder=""
                      value={formik.values.productValue}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.productValue &&
                        formik.errors.productValue
                      }
                    />
                    {formik.touched.productValue &&
                      formik.errors.productValue && (
                        <div className="text-red-500 text-sm pl-2 pt-2">
                          {formik.errors.productValue}
                        </div>
                      )}
                  </div>
                  <div className="col-span-1">
                    <Input
                      type="text"
                      name="condition"
                      className="!bg-white"
                      label="Condition"
                      required={true}
                      placeholder=""
                      value={formik.values.condition}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.condition && formik.errors.condition
                      }
                    />
                    {formik.touched.condition && formik.errors.condition && (
                      <div className="text-red-500 text-sm pl-2 pt-2">
                        {formik.errors.condition}
                      </div>
                    )}
                  </div>
                </Grid>

                <div className="mt-8">
                  <InActiveButton
                    className='mr-3'
                    onClick={handleGOBack}
                  >
                    Cancel
                  </InActiveButton>
                  <Button type="submit">Update</Button>
                </div>
              </Card>
            </form>

            {/* Modal Email Popop */}
            <Modal isOpen={isModalOpen} onClose={closeModal}>
              <div className="text-center py-1">
                <img src={AddDealer} alt="email Image" className="mx-auto" />
                <>
                  <p className="text-3xl mb-0 mt-4 font-semibold">
                    Updated <span className=""> Successfully </span>
                  </p>
                  <p className="text-base font-medium mt-2">
                    <b> Contract </b> Updated successfully.{" "}
                  </p>
                  <p className="text-base font-medium mt-2">
                    {" "}
                    Redirecting you on Contract SingleView Page {timer} seconds.
                  </p>

                </>
              </div>
            </Modal>
          </>
        )}
      </div>
    </>
  );
}

export default EditContract;
