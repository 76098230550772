import React, { useEffect, useRef, useState } from "react";
import Button from "../../../common/button";
import Grid from "../../../common/grid";
import Input from "../../../common/input";
import {
  faFileImage,
  faFilePdf,
  faFileWord,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import Sendto from "../../../assets/images/double-arrow.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form, Field, ErrorMessage } from "formik";

// Media Includes
import AddDealer from "../../../assets/images/dealer-book.svg";
import DeleteImage from "../../../assets/images/icons/Delete.svg";
import Search from "../../../assets/images/icons/SearchIcon.svg";
import AddItem from "../../../assets/images/icons/addItem.svg";
import delaerName from "../../../assets/images/claim/Dealer.svg";
import ServicerName from "../../../assets/images/claim/servicer.png";
import CustomerName from "../../../assets/images/claim/Customer.svg";
import claimed from "../../../assets/images/claim/claim.png";
import status from "../../../assets/images/claim/Status.svg";
import download from "../../../assets/images/download.png";
import disapproved from "../../../assets/images/Disapproved.png";
import chat from "../../../assets/images/newEye.png";
import clearFilter from "../../../assets/images/icons/Clear-Filter-Icon-White.svg";
import arrowImage from "../../../assets/images/dropdownArrow.png";
import upload from "../../../assets/images/icons/upload.svg";
import Select from "../../../common/select";
import Cross from "../../../assets/images/Cross.png";
import Headbar from "../../../common/headBar";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "../../../common/model";
import {
  addClaimMessages,
  addClaimsRepairParts,
  addUploadCommentImage,
  editClaimServicerValue,
  editClaimStatus,
  editClaimTypeValue,
  getClaimList,
  getClaimListForCustomer,
  getClaimListForDealer,
  getClaimListForReseller,
  getClaimListForResellerPortal,
  getClaimListForServicer,
  getClaimMessages,
  getContractPrice,
  checkCoverageTypeDate,
  checkClaimAmount,
  getOptions,
  getCustomerData,
  exportDataForClaim,
  getReportingKeys,
  claimDetailReporting,
  addReportingKeys,
} from "../../../services/claimServices";
import { format } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RotateLoader } from "react-spinners";
import CustomPagination from "../../pagination";
import SelectSearch from "../../../common/selectSearch";
import Card from "../../../common/card";
import { downloadFile } from "../../../services/userServices";
import SingleView from "../../../common/singleView";
import InActiveButton from "../../../common/inActiveButton";
import { claimReportDownloadOptions } from '../../../claimFileGenrateJson';
import CollapsibleDiv from "../../../common/collapsibleDiv";

function ClaimList(props) {
  console.log(props, '---props')
  const location = useLocation();
  const [timer, setTimer] = useState(3);
  const [showDetails, setShowDetails] = useState(false);
  const [option, setOption] = useState(claimReportDownloadOptions)
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [isDetailsReportOpen, setIsDetailsReportOpen] = useState(false);
  const [pageValue, setPageValue] = useState(1);
  const [loaderType, setLoaderType] = useState(false);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [customerDetail, setCustomerDetail] = useState();
  const [completeLoader, setCompleteLoader] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);
  const [isCustomerOpen, setIsCustomerOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [role, setRole] = useState(null);
  const [isRejectOpen, setIsRejectOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [isShipped, setIsShipped] = useState(false);
  const [isReceived, setIsReceived] = useState(false);
  const [isAttachmentsOpen, setIsAttachmentsOpen] = useState(false);
  const [isDisapprovedOpen, setIsDisapprovedOpen] = useState(false);
  const [isThreshold, setIsThreshold] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [trackerView, setTrackerView] = useState(false);
  const [dropdownVisible2, setDropdownVisible2] = useState(false);
  const [dropdownVisible1, setDropdownVisible1] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const [claimList, setClaimList] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [price, setPrice] = useState("");
  const [serviceType, setServiceType] = useState([]);
  const isFormSubmittedRef = useRef(false);
  const [serviceType1, setServiceType1] = useState([
    { label: "Parts", value: "Parts" },
    { label: "labor", value: "Labour" },
    { label: "Shipping", value: "Shipping" },
  ]);
  const [claimId, setClaimId] = useState("");
  const [claimUnique, setClaimUnique] = useState("");
  const [claimType, setClaimType] = useState("");
  const [servicer, setServicer] = useState("");
  const [servicerList, setServicerList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [claimDetail, setClaimDetail] = useState({});

  const [error, setError] = useState("");
  const [coverageTypeError, setCoverageTypeError] = useState({});
  const [errorForCoverageType, setErrorForCoverageType] = useState(false);
  const [selfServicer, setSelfServicer] = useState("");
  const [showdata, setShowdata] = useState(false);
  const [reportSuccess, setreportSuccess] = useState(false);
  const [showdata1, setShowdata1] = useState(true);
  const [customerStatus, setCustomerStatus] = useState({
    status: "",
    date: "",
  });
  const [repairValue, repair_status] = useState({});
  const [customerValue, customer_status] = useState({});
  const [claimvalues, claim_status] = useState({});
  // add this one line 
  const [claimvaluesOptions, claim_statusOptions] = useState({});
  const [type, setType] = useState('')
  const [shipment, shipment_type] = useState({});
  const [claim, setClaim] = useState([
    { label: "Breakdown", value: "Breakdown" },
    { label: "Accidental", value: "Accidental" },
  ]);
  const navigate = useNavigate();


  const [claimStatus, setClaimStatus] = useState({ status: "", date: "" });
  const [repairStatus, setRepairStatus] = useState({ status: "", date: "" });
  const [initialValues, setInitialValues] = useState({
    repairParts: [{ serviceType: "", description: "", price: "" }],
    note: "",
    totalAmount: "",
  });
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1); // Move back 1 year
  oneYearAgo.setDate(oneYearAgo.getDate() + 1);
  console.log(oneYearAgo, 'days-----')

  const today = new Date();
  const capitalizedFlag = props.flag
    ? props.flag.charAt(0).toUpperCase() + props.flag.slice(1)
    : '';
  const [initialValues21, setInitialValues21] = useState({
    contractId: "",
    claimId: "",
    venderOrder: "",
    serial: "",
    productName: "",
    pName: "",
    dealerName: "",
    resellerName: "",
    customerName: "",
    dealerSku: "",
    servicerName: "",
    repairStatus: "",
    customerStatusValue: "",
    claimStatus: "",
    orderId: "",
    trackingNumber: "",
    dateFilter: "openDate",
    startDate: oneYearAgo.toISOString().split("T")[0],
    endDate: today.toISOString().split("T")[0],
    trackingType: "",
    claimPaidStatus: "",
    userId: props.id,
    flag: capitalizedFlag
  })
  const [projection, setProjection] = useState({
    unique_key: 1,
    orderId: 1,
    contractId: 1,
    customerStatus: 1,
    repairStatus: 1,
    claimStatus: 1,
    totalAmount: 1,
    diagnosis: 1,
    claimFile: 1,
    lossDate: 1,
    reason: 1,
    note: 1,
    trackingType: 1,
    claimType: 1,
    customerStatusDate: 1,
    claimStatusDate: 1,
    repairStatusDate: 1,
    trackingNumber: 1,
    submittedBy: 1,
    shippingTo: 1,
    getCoverClaimAmount: 1,
    customerClaimAmount: 1,
    createdAt: 1,
    approveDate: 1,
    venderOrder: 1,
    manufacture: 1,
    model: 1,
    serial: 1,
    condition: 1,
    retailPrice: 1,
    category: 1,
    pName: 1,
    dealerSku: 1,
    productName: 1,
    description: 1,
    priceType: 1,
    coverageStartDate: 1,
    coverageEndDate: 1,
    labourWarrantyDate: 1,
    partsWarrantyDate: 1,
    purchaseDate: 1,
    noOfClaimPerPeriod: 1,
    noOfClaim: 1,
    eligibilty: 1,
    isManufacturerWarranty: 1,
    isMaxClaimAmount: 1,
    dealerName: 1,
    resellerName: 1,
    servicerName: 1,
    customerUsername: 1,
    claimPaymentStatus: 1,
  });

  const [sendto, setSendto] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const { claimIdValue } = useParams();
  const [currentClaimId, setCurrentClaimId] = useState(claimIdValue);

  const excludedPaths = [
    "/customer/claimList",
    "/reseller/claimList",
    "/reseller/customerDetails/",
    "/dealer/customerDetails/",
    "/dealer/claimList",
    "/dealer/resellerDetails",
  ];

  const getStatus = (status) => {

    const matchedStatus1 = claimvaluesOptions.value?.find(s => s.value === status);
    return matchedStatus1 ? matchedStatus1.label : "Unknown";
  };

  const getCustomerStatus = (status) => {

    const matchedStatus1 = customerValue.value?.find(s => s.value === status);
    return matchedStatus1 ? matchedStatus1.label : "Unknown";
  };

  const isExcludedPath = excludedPaths.some((path) =>
    location.pathname.includes(path)
  );


  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    setRole(userDetails.role);
  }, [location.pathname]);

  const dropdownRef = useRef(null);
  const handleToggleDropdown = (value) => {
    setDropdownVisible(!dropdownVisible);
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    setModelLoading(false);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messageList, claimId]);

  const downloadImage = async (file) => {
    try {
      let data = {
        key: file.messageFile.fileName,
      };
      const binaryString = await downloadFile(data);
      const blob = new Blob([binaryString]);
      const blobUrl = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = file.messageFile.fileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error fetching the file:", error);
    }
  };

  useEffect(() => {
    let intervalId;
    if (isAttachmentsOpen && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setIsAttachmentsOpen(false);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isAttachmentsOpen, timer]);

  const handleSelectChange2 = (selectedValue, value) => {
    formik1.setFieldValue(selectedValue, value);
  };
  const handleSelectChange21 = (selectedValue, value) => {
    Shipment.setFieldValue(selectedValue, value);
  };

  // const getAllReportingPermission = async() =>{
  // const data =await getReportingKeys()
  // console.log(data.result.claimKeys)
  // setProjection(data.result.claimKeys)
  // }
  const filterClaimReportOptions = (options, apiResponse) => {
    return options.map((option) => {
      const filteredSections = option.sections.filter(
        (field) => apiResponse.hasOwnProperty(field.action)
      );

      return { ...option, sections: filteredSections };
    });
  };

  const getAllReportingPermission = async () => {
    const data = await getReportingKeys();
    const apiResponse = data.result.claimKeys;
    console.log("API Response:", apiResponse);

    const filteredOptions = filterClaimReportOptions(claimReportDownloadOptions, apiResponse);
    console.log("Filtered Options:", filteredOptions);

    setProjection(apiResponse);
    setOption(filteredOptions);
    console.log(filteredOptions)
  };

  const addAllReportingPermission = async () => {
    console.log("API Response:", claimReportDownloadOptions);
    const data = await addReportingKeys(projection);

  };

  useEffect(() => {
    if (currentClaimId != undefined) {
      formik1.setFieldValue("claimId", currentClaimId);
    } else {
      // handleFilterIconClick();
    }
  }, [location]);

  const handleSelectChange = async (selectedValue, value) => {
    setLoading1(true);
    if (selectedValue === "claimStatus") {
      if (value === "rejected") {
        setIsRejectOpen(true);
      } else if (value?.reason) {
        value.claimStatus = "rejected";
        editClaimRejectedValue(claimList.result[activeIndex]._id, value);
      }
      else if (value === "completed") {
        setIsComplete(true)

      }
      else {
        const updateAndCallAPI = (setter) => {
          setCompleteLoader(true);

          setter((prevRes) => ({ ...prevRes, status: value }));
          editClaimValue(
            claimList.result[activeIndex]._id,
            selectedValue,
            value.type == "completed" ? value.type : value
          );

        };

        switch (selectedValue) {
          case "claimStatus":
            updateAndCallAPI(setClaimStatus);
            break;
          default:
            console.error("here");
        }
      }
    }
    else if (selectedValue === "claimType") {
      setLoading1(true);
      let data = {
        claimId: claimList.result[activeIndex]._id,
        coverageType: value
      }
      checkCoverageTypeDate(data).then((res) => {
        if (res.code == 200) {
          const updateAndCallAPI = (setter) => {
            editClaimClaimType(
              claimList.result[activeIndex]._id,
              selectedValue,
              value
            );
          };
          updateAndCallAPI(setClaimType);
        }
        else {
          setCoverageTypeError(res)
          setErrorForCoverageType(true)
          setLoading1(false);
        }
      })
    } else if (selectedValue === "servicer") {
      setLoading1(true);
      const updateAndCallAPI = (setter) => {
        setter((prevRes) => ({ ...prevRes, status: value }));
        editClaimServicer(
          claimList.result[activeIndex]._id,
          selectedValue,
          value
        );
      };
      // Call updateAndCallAPI function to handle servicer
      updateAndCallAPI(setServicer);
    } else {
      if (value === 'servicer_shipped') {
        setIsShipped(true)
      }
      else if (value === 'product_received') {
        setIsReceived(true)
      }
      else {
        console.log(value)
        setLoading1(true);
        const updateAndCallAPI = (setter) => {
          setter((prevRes) => ({ ...prevRes, status: value }));
          editClaimValue(claimList.result[activeIndex]._id, selectedValue, (value.type === 'servicer_shipped' || value.type === "product_received") ? value.type : value);
        };
        switch (selectedValue) {
          case "customerStatus":
            updateAndCallAPI(setCustomerStatus);
            setIsReceived(false)
            break;
          case "claimStatus":
            updateAndCallAPI(setClaimStatus);
            break;
          case "repairStatus":
            updateAndCallAPI(setRepairStatus);
            setIsShipped(false)
            break;
          default:
            console.error("here");
        }
      }



    }
    setTimeout(() => {
      setLoading1(false);
    }, 3000);
  };

  const editClaimRejectedValue = (claimId, data) => {
    editClaimStatus(claimId, data).then((res) => {
      updateAndSetStatus(setClaimStatus, "claimStatus", res);
      updateAndSetStatus(setRepairStatus, "repairStatus", res);
      updateAndSetStatus(setCustomerStatus, "customerStatus", res);
      if (data.claimStatus == "rejected") {
        const updatedClaimListCopy = { ...claimList };
        updatedClaimListCopy.result[activeIndex][claimType] = "rejected";
      }
    });
    closeReject();
    closeCoveragType();
  };

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    console.log('check this', id, checked)
    setProjection((prev) => ({
      ...prev,
      [id]: checked ? 1 : 0,
    }));
  };

  const fileGenrateForm = useFormik({
    initialValues: {
      reportName: '',
      remark: '',
      category: 'claimReporting'
    }, validationSchema: Yup.object({
      reportName: Yup.string().required('Report name is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values, formik1.values);
      try {
        // Combine values from both forms
        setTimeout(() => {
          setreportSuccess(true)
          setSubmitting(false);

        }, 2000);

        const data = type == 'Summary' ? await exportDataForClaim({
          ...values,
          ...formik1.values,
        }) : await claimDetailReporting({
          ...values,
          ...formik1.values,
          projection: {
            ...projection
          }
        });


        // if (data.code === 200) {
        //   setreportSuccess(true);
        // }
      } catch (error) {
        console.error('Error exporting data:', error);
      } finally {

      }
    }

  });

  const updateAndSetStatus = (statusObject, name, res) => {
    if (res.code === 200) {
      const resultData = res.result || {};
      const updatedClaimListCopy = { ...claimList };
      if (updatedClaimListCopy.result) {
        updatedClaimListCopy.result[activeIndex][name] = resultData[`${name}`];
        updatedClaimListCopy.result[activeIndex][`reason`] = resultData.reason;
      }
      setClaimList(updatedClaimListCopy);
      statusObject({
        status: resultData[`${name}`][resultData[`${name}`].length - 1].status,
        date: resultData[`${name}`][resultData[`${name}`].length - 1].date,
      });
    }
  };

  const editClaimValue = (claimId, statusType, statusValue) => {
    setCompleteLoader(true);
    let data = {
      [statusType]: statusValue,
    };

    editClaimStatus(claimId, data).then((res) => {
      setIsComplete(false)
      updateAndSetStatus(setClaimStatus, "claimStatus", res);
      updateAndSetStatus(setRepairStatus, "repairStatus", res);
      updateAndSetStatus(setCustomerStatus, "customerStatus", res);
      setCompleteLoader(false);
    });

  };

  const editClaimServicer = (claimId, statusType, statusValue) => {
    let data = {
      servicerId: statusValue,
    };

    editClaimServicerValue(claimId, data)
      .then((res) => {
        setServicer(res.result.servicerId);
        const updatedClaimListCopy = { ...claimList };
        if (updatedClaimListCopy.result) {
          updatedClaimListCopy.result[activeIndex]["servicerId"] =
            res.result.servicerId;
          updatedClaimListCopy.result[activeIndex].selfServicer =
            res.result.isPureServicer;
        }
        setClaimList(updatedClaimListCopy);
        getAllClaims();
      })
      .catch((error) => {
        console.error("Error occurred while editing claim servicer:", error);
      });
  };

  const editClaimClaimType = (claimId, statusType, statusValue) => {
    let data = {
      claimType: statusValue,
    };

    editClaimTypeValue(claimId, data).then(async (res) => {
      setClaimType(res.result.claimType);
      const updatedClaimListCopy = { ...claimList };
      if (updatedClaimListCopy.result) {
        updatedClaimListCopy.result[activeIndex]["claimType"] =
          res.result.claimType;
        if (updatedClaimListCopy.result) {
          updatedClaimListCopy.result[activeIndex]["claimType"] =
            res.result.claimType;
          updatedClaimListCopy.result[activeIndex]["customerClaimAmount"] =
            res.result.customerClaimAmount;
          updatedClaimListCopy.result[activeIndex]["customerOverAmount"] =
            res.result.customerOverAmount;
          updatedClaimListCopy.result[activeIndex]["getCoverClaimAmount"] =
            res.result.getCoverClaimAmount;
          updatedClaimListCopy.result[activeIndex]["getcoverOverAmount"] =
            res.result.getcoverOverAmount;
          await getClaimOptions(res.result.claimType)
        }
      }
      setClaimList(updatedClaimListCopy);

    });
  };

  const handleAddClaim = () => {
    const path = window.location.pathname;
    let newPath = "/addClaim";
    if (path.includes("/dealer/claimList")) {
      newPath = "/dealer/addClaim";
    } else if (path.includes("/reseller/claimList")) {
      newPath = "/reseller/addClaim";
    } else if (path.includes("/customer/claimList")) {
      newPath = "/customer/addClaim";
    }
    navigate(newPath);
  };

  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const handlePageChange = async (page, rowsPerPage) => {
    setShowdata(false);
    setShowdata1(false);
    setRecordsPerPage(rowsPerPage);

    setLoading(true);
    try {
      if (props?.flag == "claim") {
        await getAllClaims(page, rowsPerPage);
      } else if (props?.flag != "") {
        await getAllClaims(page, rowsPerPage);
      } else {
        await getAllClaims(page, rowsPerPage);
      }

      setLoading(false);

      setTimeout(function () {
        setShowdata(true);
      }, 1000);
    } finally {
      localStorage.removeItem("activeIndex");
      setActiveIndex(null);
      setLoading(false);
      setTimeout(function () {
        setShowdata(true);
      }, 1000);
    }
  };

  const formatOrderValue = (orderValue) => {
    if (Math.abs(orderValue) >= 1e6) {
      return (orderValue / 1e6).toFixed(2) + "M";
    } else {
      return orderValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  };

  const handleToggleDropdown1 = () => {
    setDropdownVisible1(!dropdownVisible1);
  };

  const handleToggleDropdown2 = () => {
    setDropdownVisible2(!dropdownVisible2);
  };

  const closeDisapproved = () => {
    setIsDisapprovedOpen(false);
  };

  const openDisapproved = () => {
    getClaimOptions();
    setIsDisapprovedOpen(true);
  };

  const closeEdit = () => {
    formik.resetForm();
    setError("");
    setIsEditOpen(false);
  };

  const closeReject = () => {
    setIsRejectOpen(false);
    setShowForm(false);
  };
  const closeCoveragType = () => {
    setErrorForCoverageType(false);;
  };

  const handleYesClick = () => {
    setShowForm(true);
  };

  const handleSetActiveIndex = (index) => {
    setActiveIndex(index); // Update active index based on user action
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
        setDropdownVisible1(false);
        setDropdownVisible2(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const openEdit = (res, index) => {
    scrollToBottom();

    if (res.repairParts.length != 0) {
      const repairPartsValues = res?.repairParts?.map((part) => ({
        serviceType: part.serviceType || "",
        description: part.description || "",
        price: part.price || "",
        value: true,
      }));
      formik.setValues({
        repairParts: repairPartsValues,
        note: claimList.result[index].note || " ",
      });
    } else {
      formik.setValues({
        repairParts: [{ serviceType: "", description: "", price: "" }],
        note: claimList.result[index].note || " ",
      });
    }
    const getServiceType = (coverageType, shipping) => {
      switch (coverageType) {
        case "Parts":
          return shipping
            ? [
              { label: "Parts", value: "Parts" },
              { label: "Shipping", value: "Shipping" },
            ]
            : [{ label: "Parts", value: "Parts" }];
        case "Labour":
          return shipping
            ? [
              { label: "Labor ", value: "Labour" },
              { label: "Shipping", value: "Shipping" },
            ]
            : [{ label: "labor", value: "Labour" }];
        default:
          return shipping
            ? [
              { label: "Parts", value: "Parts" },
              { label: "Labor ", value: "Labour" },
              { label: "Shipping", value: "Shipping" },
            ]
            : [
              { label: "Parts", value: "Parts" },
              { label: "Labor ", value: "Labour" },
            ];
      }
    };
    setServiceType(
      getServiceType(
        res.contracts.orders.serviceCoverageType,
        res.contracts.orders.dealers.isShippingAllowed
      )
    );
    setClaimUnique(res.unique_key);
    setClaimId(res._id);
    setIsEditOpen(true);
    setError("");

    getClaimPrice(res.contractId);
  };

  const getClaimPrice = async (id) => {
    setClaimLoading(true);
    const response = await getContractPrice(id);
    setPrice(response);
    setTimeout(() => {
      setClaimLoading(false);
    }, 1000);
  };

  const calculateTotalCost = (cost1, cost2) => {
    const totalCost = cost1 + cost2;
    if (totalCost === 0) {
      return "N/A";
    }

    return `$${totalCost.toFixed(2)}`;
  };

  const openDetailsReport = () => {
    setIsDetailsReportOpen(true);
  }
  const closeDetailsReport = () => {
    setIsDetailsReportOpen(false)
  }

  const closeView = () => {
    formik.resetForm();
    setIsViewOpen(false);
  };

  const openAttachments = () => {
    setIsAttachmentsOpen(true);
    // setIsEditOpen(false);
  };

  const closeAttachments = () => {
    setIsAttachmentsOpen(false);
  };

  const closeComplete = () => {
    setIsComplete(false);
  };

  const closeShipped = () => {
    setIsShipped(false);
  };

  const closeReceived = () => {
    setIsReceived(false);
  };

  const closeCustomer = () => {
    setIsCustomerOpen(false);
  };

  const openReport = () => {
    closeDetailsReport();
    addAllReportingPermission();
    fileGenrateForm.resetForm();
    setreportSuccess(false)
    setIsReportOpen(true);
  }

  const closeReport = () => {
    setIsReportOpen(false)
  }

  const closeReport1 = () => {
    setIsReportOpen(false)
    setIsDetailsReportOpen(true)
  }


  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, ""); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match groups of 3 digits

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber; // Return original phone number if it couldn't be formatted
  };

  const openView = (claim) => {
    console.log('role', role)
    let typeValue = "";
    const isValidReseller = !!claim?.contracts.orders.resellerId;
    const selfServicer = claim?.selfServicer;
    const isAdminView = window.location.pathname.includes("/dealer/claimList") ||
      window.location.pathname.includes("/dealer/customerDetails") ||
      window.location.pathname.includes("/dealer/resellerDetails");
    const isResellerPath =
      window.location.pathname.includes("/reseller/claimList") ||
      window.location.pathname.includes("/reseller/customerDetails");
    const isCustomerPath = window.location.pathname.includes(
      "/customer/claimList"
    );

    if (role == "Super Admin") {
      typeValue = "Admin";
    } else if (role == "Dealer") {
      typeValue = "Dealer";
    } else if (role == "Reseller") {
      typeValue = "Reseller";
    } else if (role == "Customer") {
      typeValue = "Customer";
    }

    formik2.setFieldValue("type", typeValue);

    setSendto(
      [
        {
          label:
            role == "Super Admin"
              ? "Admin (To Self)"
              : "Admin ",
          value: "Admin",
        },
        {
          label:
            role == "Dealer"
              ? "Dealer (To Self)"
              : "Dealer ",
          value: "Dealer",
        },
        isValidReseller && {
          label:
            role == "Reseller"
              ? "Reseller (To Self)"
              : "Reseller",
          value: "Reseller",
        },
        !selfServicer ? { label: "Servicer", value: "Servicer" } : null,
        {
          label:
            role == "Customer"
              ? "Customer (To Self)"
              : "Customer",
          value: "Customer",
        },
      ].filter(Boolean)
    );

    setClaimDetail(claim);
    getClaimMessage(claim._id, true);
    setIsViewOpen(true);
  };

  const getClaimMessage = (claimId, loader = true) => {
    setModelLoading(loader);
    getClaimMessages(claimId)
      .then((res) => {
        setMessageList(res.result);
      })
      .catch((error) => {
        console.error("Error fetching claim messages:", error);
      })
      .finally(() => {
        scrollToBottom();
      });
  };

  useEffect(() => {
    if (claimList?.result?.repairParts?.length !== 0) {
      const mappedValues = claimList?.result?.repairParts?.map((part) => ({
        serviceType: part.serviceType || "",
        description: part.description || "",
        price: part.price || "",
      }));

      setInitialValues({
        repairParts: mappedValues,
        note: "",
      });
    }
  }, [claimList]);

  const downloadAttachments = async (res) => {
    const attachments = res || [];

    for (const [index, attachment] of attachments.entries()) {
      try {
        const binaryString = await downloadFile({ key: attachment.key });
        const fileExtension = attachment.key.split(".").pop();
        let mimeType = "";

        switch (fileExtension) {
          case "pdf":
            mimeType = "application/pdf";
            break;
          case "jpg":
          case "jpeg":
            mimeType = "image/jpeg";
            break;
          case "png":
            mimeType = "image/png";
            break;
          case "doc":
          case "docx":
            mimeType = "application/msword";
            break;
          case "xlsx":
            mimeType =
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            break;
          default:
            mimeType = "application/octet-stream";
        }
        const blob = new Blob([binaryString], { type: mimeType });
        const blobUrl = URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = blobUrl;
        anchor.download = attachment.key.split("/").pop();
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Error fetching the file:", error);
      }
    }
  };

  const initialValues2 = {
    content: "",
    orderId: "",
    type: "Admin",
    messageFile: {},
  };

  const formik2 = useFormik({
    initialValues: initialValues2,
    validationSchema: Yup.object({
      content: Yup.string().required(),
    }),

    onSubmit: (values) => {
      values.orderId = claimDetail?.contracts?.orders?._id;

      const userInfo = JSON.parse(localStorage.getItem("userDetails"));
      const temporaryMessage = {
        _id: "temp-id",
        content: values.content,
        type: values.type || "Admin",
        messageFile: {
          fileName: values.fileName || "",
          originalName: values.originalName || "",
          size: values.size || "",
          _id: "temp-file-id",
        },
        date: new Date().toISOString(),
        commentTo: {
          firstName: values.commentToFirstName || "",
          lastName: values.commentToLastName || "",
        },
        commentBy: {
          firstName: userInfo?.userInfo?.firstName || "",
          lastName: userInfo?.userInfo?.lastName || "",
          roles: {
            role: userInfo?.role || "",
          },
        },
      };
      // Update the state with the temporary message
      setMessageList((prevClaimMessages) => [
        ...prevClaimMessages,
        temporaryMessage,
      ]);

      addClaimMessages(claimDetail?._id, values).then((res) => {
        getClaimMessage(claimDetail._id, false);
      });

      formik2.resetForm();
      formik2.setFieldValue("content", "");
      setPreviewImage(null);
      const pathname = window.location.pathname;
      const type = pathname.includes("/dealer/claimList")
        ? "Dealer"
        : pathname.includes("/customer/claimList")
          ? "Customer"
          : pathname.includes("/reseller/claimList") ||
            pathname.includes("/reseller/customerDetails")
            ? "Reseller"
            : pathname.includes("/servicer/claimList")
              ? "Servicer"
              : null;

      if (type) {
        formik2.setFieldValue("type", type);
      }
    },
  });

  useEffect(() => {
    if (activeIndex != null) {
      const coverageType =
        claimList?.result[activeIndex]?.contracts?.orders?.coverageType;
      getClaimOptions(claimList?.result[activeIndex]?.claimType);
      const claims =
        coverageType === "Breakdown"
          ? [{ label: "Breakdown", value: "Breakdown" }]
          : coverageType === "Accidental"
            ? [{ label: "Accidental", value: "Accidental" }]
            : [
              { label: "Breakdown", value: "Breakdown" },
              { label: "Accidental", value: "Accidental" },
            ];
      setClaim(claims);
      const bdAdhValue = claimList.result[activeIndex]?.claimType;

      setClaimType(bdAdhValue);

      setError("");

      const getLastItem = (array) => array?.[array.length - 1];
      setSelfServicer(claimList.result[activeIndex]?.selfServicer);
      const customerValue = getLastItem(
        claimList.result[activeIndex]?.customerStatus
      );
      const claimStatus = getLastItem(
        claimList.result[activeIndex]?.claimStatus
      );
      const repairStatus = getLastItem(
        claimList.result[activeIndex]?.repairStatus
      );
      let arr = [];
      const filterServicer = claimList?.result[
        activeIndex
      ]?.contracts?.allServicer?.map((res) => ({
        label: res?.name,
        value: res?._id,
      }));

      setServicerList(filterServicer);
      if (
        filterServicer?.length !== 0 &&
        claimList?.result[activeIndex]?.servicerId !== null
      ) {
        setServicer(claimList?.result[activeIndex]?.servicerId);
      } else {
        setServicer("");
      }

      setCustomerStatus({
        status: customerValue?.status,
        date: customerValue?.date,
      });
      setClaimStatus({ status: claimStatus?.status, date: claimStatus?.date });
      setRepairStatus({ status: repairStatus?.status, date: repairStatus?.date });
    }
  }, [activeIndex]);

  // Use useFormik with the correct initialValues
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      repairParts: Yup.array().of(
        Yup.object().shape({
          serviceType: Yup.string().required("Service Type is required"),
          description: Yup.string().required("Description is required"),
          price: Yup.number()
            .required("Price is required")
            .min(0.1, "Price must be at least 0.1"),
        })
      ),
    }),
    onSubmit: (values) => {
      setClaimLoading(true);
      setError("");

      let totalPrice = 0;
      values.repairParts.forEach((part) => {
        totalPrice += Number(part.price);
      });
      values.totalAmount = totalPrice;
      addClaimsRepairParts(claimId, values).then((res) => {
        if (res.code == 401) {
          setError(res.message);
          setTimeout(() => {
            setClaimLoading(false);
          }, 1000);
        } else {
          openAttachments();
          setTimer(3);
          getAllClaims();
          // setActiveIndex();
          setIsEditOpen(false);
          setTimeout(() => {
            setClaimLoading(false);
          }, 1000);
        }
      });

    },
  });

  const Shipment = useFormik({
    initialValues: {
      trackingNumber: "",
      trackingType: "",
    },
    onSubmit: (values) => {
      setLoading1(true);
      setShowdata1(false);
      addClaimsRepairParts(claimList.result[activeIndex]._id, values).then(
        (res) => {
          let stemp = true
          getAllClaims(undefined, undefined, true, stemp);
          setActiveIndex(activeIndex);
          setTrackerView(false);
          Shipment.resetForm();
        }
      );
      console.log(activeIndex, '-----------activeIndex')
      setTimeout(() => {
        setLoading1(false);
        setShowdata1(true);
      }, 3000);
    },
  });

  const handleRemove = (index) => {
    setError("");
    const updatedErrors = { ...formik.errors };
    if (updatedErrors.repairParts) {
      const updatedRepairPartsErrors = updatedErrors.repairParts.slice();
      updatedRepairPartsErrors.splice(index, 1);
      updatedErrors.repairParts = updatedRepairPartsErrors;
    }
    formik.setErrors(updatedErrors);
    const updatedRepairParts = [...formik.values.repairParts];
    updatedRepairParts.splice(index, 1);
    formik.setFieldValue("repairParts", updatedRepairParts);
  };

  const handleAddMore = () => {
    // Update the formik values with the new item
    setError("");
    formik.setFieldValue("repairParts", [
      ...formik.values.repairParts,
      { serviceType: "", description: "", price: "" },
    ]);
  };

  useEffect(() => {
    // getAllClaims();
    getClaimOptions();
    getAllReportingPermission()

  }, []);

  useEffect(() => {
    if (props.activeTab == "Claims") {
      getAllClaims();
    }
  }, [props]);

  const getClaimOptions = async (value) => {
    console.log(claimType, value)
    try {
      const data = [
        "repair_status",
        "shipment_type",
        "customer_status",
        "claim_status",
      ];

      const result = await getOptions(data);
      console.log('result', result)
      const stateSetters = {
        repair_status,
        shipment_type,
        customer_status,
        claim_status,
      };

      const filterOptions = (key, options) => {
        if (value === "" || value == "New" || value === undefined) {
          if (key === "claim_status") {
            // add this line
            claim_statusOptions(options)
            return {
              ...options,
              value: options?.value?.filter(option => option.value != "completed"),
            };
          }
          if (key === "repair_status") {
            return {
              ...options,
              value: options?.value?.filter(
                option =>
                  option.value != "repair_complete" &&
                  option.value != "servicer_shipped"
              ),
            };
          }
          if (key === "customer_status") {
            return {
              ...options,
              value: options?.value?.filter(option => option.value != "product_received"),
            };
          }
        }
        console.log(options)
        return options;
      };


      data.forEach((key, index) => {
        const filteredOptions = filterOptions(key, result.result[index]);
        console.log(result.result[index], filteredOptions, '-------here-----')
        stateSetters[key]?.(filteredOptions);
      });
    } catch (error) {
      console.error("Error fetching claim options:", error);
    }
  };



  const claimPaid = [
    {
      value: "Unpaid",
      label: "Unpaid",
    },
    {
      value: "Paid",
      label: "Paid",
    },
  ];

  const filterBy = [
    {
      value: "damageDate",
      label: "Damage Date",
    },
    {
      value: "openDate",
      label: "Open Date",
    },
    {
      value: "closeDate",
      label: "Close Claim",
    },
  ];

  const validationSchema = Yup.object().shape({});
  const formik1 = useFormik({
    initialValues: initialValues21,
    validationSchema,

    onSubmit: (values) => {
      isFormSubmittedRef.current = true;
      setIsDisapprovedOpen(false);
      getAllClaims();
      setShowdata(false);
      setActiveIndex(null);
      setTimeout(function () {
        setShowdata(true);
      }, 1000);
      setShowdata(false);
    },
  });

  const getAllClaims = async (page = 1, rowsPerPage, loader, stemp, id = undefined) => {

    if (loader) {
      setLoaderType(false);
    } else setLoaderType(true);
    setDisable(true);
    setPageValue(page);
    let data = {
      page,
      pageLimit: rowsPerPage == undefined ? recordsPerPage : rowsPerPage,
      ...(isFormSubmittedRef.current ? formik1.values : initialValues21),
    };
    let getClaimListPromise;

    if (props.flag === "dealer") {
      getClaimListPromise = getClaimListForDealer(props.id, data);
    } else if (props.flag === "servicer") {
      getClaimListPromise = getClaimListForServicer(props.id, data);
    } else if (props.flag === "reseller") {
      getClaimListPromise = getClaimListForReseller(props.id, data);
    } else if (window.location.pathname.includes("/reseller/claimList")) {
      getClaimListPromise = getClaimListForResellerPortal(props.id, data);
    } else if (props.flag === "customer") {
      getClaimListPromise = getClaimListForCustomer(props.id, data);
    } else {
      console.log('currentClaimId', formik1.values.claimId)
      if (id == undefined) {
        getClaimListPromise = getClaimList(data);
      } else {
        let newData = {
          ...data,
          claimId: id,
        };
        getClaimListPromise = getClaimList(newData);
      }
    }

    getClaimListPromise
      .then((res) => {
        localStorage.removeItem("activeIndex");
        if (stemp) { }
        else { setActiveIndex(null); }
        if (res) {
          setClaimList(res);
          setTotalRecords(res?.totalCount);
        }
        setLoaderType(false);
        setTimeout(function () {
          setShowdata(true);
        }, 1000);
        setDisable(false);
      })
      .catch(() => {
        setLoaderType(false);
        setShowdata(false);
        setDisable(false);
      });
  };
  const handleChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  const handleChange2 = (name, value) => {
    formik2.setFieldValue(name, value);
  };
  const fileInputRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [fileType, setFileType] = useState(null);

  useEffect(() => {
    formik1.setFieldValue('claimId', claimIdValue)
    setCurrentClaimId(claimIdValue);
    console.log('new testing', claimIdValue)
    getAllClaims(undefined, undefined, false, true, claimIdValue);
  }, []);

  const handleFilterIconClick = () => {

    formik1.values = {};
    formik1.resetForm();

    isFormSubmittedRef.current = false;
  };

  const onhandle = async (id) => {
    setIsCustomerOpen(true);
    setViewLoader(true);
    const res = await getCustomerData(id);
    console.log(res, "------------------Login--------------->>>>");
    setCustomerDetail(res.result);
    setViewLoader(false);
  }

  return (
    <>
      {loading1 && (
        <div className=" fixed z-[999999] bg-[#f9f9f99c] backdrop-blur-xl top-0 h-screen w-full flex py-5">
          <div className="self-center ml-[40%]">
            <RotateLoader color="#333" />
          </div>
        </div>
      )}
      <div className="mb-8 ml-3">
        {props && Object.keys(props).length === 0 && (
          <>
            <Headbar />
            <div className="flex mt-2">
              <div className="pl-3">
                <p className="font-bold text-[36px] leading-9 mb-[3px]">
                  Claim
                </p>
                <ul className="flex self-center">
                  <li className="text-sm font-Regular">
                    <Link to={"/"}>Home </Link> /
                  </li>
                  <li className="text-sm font-semibold ml-1">
                    {" "}
                    Claim Listing
                  </li>
                </ul>
              </div>
            </div>

            <InActiveButton
              onClick={handleAddClaim}
              className=" flex self-center mb-3 rounded-xl ml-auto border-[1px] border-Light-Grey"
            >
              <div
                style={{
                  maskImage: `url(${AddItem})`,
                  WebkitMaskImage: `url(${AddItem})`,
                  maskRepeat: "no-repeat",
                  WebkitMaskRepeat: "no-repeat",
                  maskPosition: "center",
                  WebkitMaskPosition: "center",
                  maskSize: "contain",
                  WebkitMaskSize: "contain",
                }}
                className="self-center pr-1 py-1 h-4 w-4"
              />
              {/* <img src={AddItem} className="self-center" alt="AddItem" />{" "} */}
              <span className=" ml-2 text-[14px] font-Regular ">
                Add Claim
              </span>
            </InActiveButton>
          </>
        )}

        <Card className="my-4 pb-4 border-[1px] border-Light-Grey rounded-xl">
          <Grid className="!p-[26px] !gap-2 !pt-[14px] !pb-0">
            <div className="col-span-2 self-center">
              <p className="text-xl font-semibold">Claims List</p>
            </div>
            <div className="col-span-10">
              <div className="bg-grayf9 rounded-[30px] p-3 border-[1px] border-Light-Grey">
                <form onSubmit={formik1.handleSubmit}>
                  <Grid className="!gap-1">
                    <div className="col-span-8 self-center">
                      <Grid className="!gap-2">
                        <div className="col-span-4 self-center">
                          <Input
                            name="contractId"
                            type="text"
                            className="!text-[14px] !bg-White-Smoke"
                            className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                            label=""
                            placeholder="Contract ID"
                            {...formik1.getFieldProps("contractId")}
                          />
                        </div>
                        <div className="col-span-4 self-center">
                          <Input
                            name="claimId"
                            type="text"
                            className="!text-[14px] !bg-White-Smoke"
                            className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                            label=""
                            placeholder="Claim ID"
                            {...formik1.getFieldProps("claimId")}
                          />
                        </div>
                        <div className="col-span-4 self-center">
                          <SelectSearch
                            name="claimStatus"
                            label=""
                            //change here 
                            options={claimvaluesOptions?.value}
                            OptionName="Claim Status"
                            className="!text-[14px] !bg-White-Smoke"
                            className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                            onChange={handleSelectChange2}
                            value={formik1.values.claimStatus}
                          />
                        </div>
                      </Grid>
                    </div>
                    <div className="col-span-4 self-center flex justify-center">
                      <Button type="submit" disabled={disable} className={`${disable ? "!bg-[#817878]" : ""
                        } !p-2`}>
                        <img
                          src={Search}
                          className="cursor-pointer "
                          alt="Search"
                        />
                      </Button>
                      <InActiveButton
                        className=""

                        onClick={() => {
                          setCurrentClaimId(undefined)

                          handleFilterIconClick();
                          getAllClaims()
                        }}
                      >
                        <div
                          style={{
                            maskImage: `url(${clearFilter})`,
                            WebkitMaskImage: `url(${clearFilter})`,
                            maskRepeat: "no-repeat",
                            WebkitMaskRepeat: "no-repeat",
                            maskPosition: "center",
                            WebkitMaskPosition: "center",
                            maskSize: "contain",
                            WebkitMaskSize: "contain",
                          }}
                          className="self-center pr-1 py-1 h-4 w-4 cursor-pointer mx-auto"
                        />

                      </InActiveButton>
                      <Button
                        type="button"
                        disabled={disable}
                        className={`${disable ? "!bg-[#817878]" : ""
                          } ml-2 !text-[14px] !px-2`}
                        onClick={() => openDisapproved()}
                      >
                        Advance Search
                      </Button>
                    </div>
                  </Grid>
                </form>
              </div>
            </div>
          </Grid>
          <div className="px-3 mt-5">
            {loaderType == true ? (
              <div className=" h-[400px] w-full flex py-5">
                <div className="self-center mx-auto">
                  <RotateLoader color="#333" />
                </div>
              </div>
            ) : (
              <>
                {claimList?.result?.length !== 0 &&
                  <div className="text-right mb-3">
                    <Button className='!text-sm' onClick={() => { setType('Summary'); openReport(); }}>Generate Summary Report</Button>
                    <Button className='!text-sm !ml-3' onClick={() => { setType('Detail'); openDetailsReport(); }}>Generate Detail Report</Button>
                    <Button className='!text-sm !ml-3'> <Link to={window.location.pathname.includes("/customer/claimList") ? '/customer/Reporting/List' : '/Reporting/List'}> View Reports </Link> </Button>
                  </div>}

                {claimList?.result &&
                  claimList?.result?.length !== 0 &&
                  claimList?.result?.map((res, index) => {
                    return (
                      <div
                        index={index}
                        className="border-Gray28 border rounded-[22px] mb-3"
                      >
                        <Grid className="border-Gray28 border-b !gap-2 bg-white rounded-t-[22px]">
                          <div className="col-span-3 self-center border-Gray28 border-r rounded-ss-xl p-5">
                            <p className="font-semibold leading-5 text-black text-lg">
                              {" "}
                              {res.unique_key}{" "}
                            </p>
                            <p className="text-[#A3A3A3]">Claim ID</p>
                          </div>
                          <div className="col-span-3 self-center border-Gray28 border-r p-5">
                            <p className="font-semibold leading-5 text-black text-lg">
                              {" "}
                              {res?.contracts?.unique_key}{" "}
                            </p>
                            <p className="text-[#A3A3A3]">Contract ID</p>
                          </div>
                          <div className="col-span-3 self-center border-Gray28 border-r p-5">
                            <p className="font-semibold leading-5 text-black text-lg">
                              {" "}
                              {format(new Date(new Date(res.lossDate).setDate(new Date(res.lossDate).getDate())), "MM/dd/yyyy")}
                            </p>
                            <p className="text-[#A3A3A3]">Damage Date</p>
                          </div>
                          <div className="col-span-3 self-center justify-center pl-4 flex relative">
                            <img
                              src={chat}
                              className=" mr-2 h-[38px] w-[38px] cursor-pointer"
                              onClick={() => navigate(window.location.pathname.includes("/customer/claimList") ? `/customer/claimDetails/${res?._id}` : `/claimDetails/${res?._id}`)}
                              alt="chat"
                            />
                          </div>
                        </Grid>
                        <Grid
                          className={`!grid-cols-5 !gap-0 bg-grayf9 rounded-[22px] `}
                        >
                          {props != '' ?
                            <div className="col-span-1 flex ">
                              <img
                                src={delaerName}
                                className="self-center h-[50px] w-[50px] ml-3"
                                alt="productName"
                              />
                              <div className="py-4 px-3 self-center">
                                <p className="text-[#4a4a4a] text-[11px] font-Regular">
                                  Dealer SKU
                                </p>
                                <p className="text-light-black text-sm font-semibold">
                                  {res?.dealerSku}
                                </p>
                              </div>
                            </div>
                            :
                            <div className="col-span-1 flex ">
                              <img
                                src={delaerName}
                                className="self-center h-[50px] w-[50px] ml-3"
                                alt="productName"
                              />
                              <div className="py-4 px-3 self-center">
                                <p className="text-[#4a4a4a] text-[11px] font-Regular">
                                  Dealer Name
                                </p>
                                <p className="text-light-black text-sm font-semibold">
                                  {res?.dealerName}
                                </p>
                              </div>
                            </div>
                          }
                          <div className="col-span-1 flex">
                            <img
                              src={ServicerName}
                              className="self-center h-[50px] w-[50px] ml-3"
                              alt=""
                            />
                            <div className="py-4 px-3 self-center">
                              <p className="text-[#4a4a4a] text-[11px] font-Regular">
                                Servicer Name
                              </p>
                              <p className="text-light-black text-sm font-semibold">
                                {res?.servicerName}
                              </p>
                            </div>
                          </div>
                          {props.flag != 'customer' ?
                            <div className="col-span-1 flex">
                              <img
                                src={CustomerName}
                                className="self-center h-[50px] w-[50px] ml-3"
                                alt=""
                              />
                              <div className="py-4 px-3 self-center">
                                <p className="text-[#4a4a4a] text-[11px] font-Regular">
                                  Customer Name
                                </p>
                                <p className="text-light-black text-sm font-semibold">
                                  {res?.customerName}
                                </p>
                              </div>
                            </div>
                            :
                            <div className="col-span-1 flex">
                              <img
                                src={CustomerName}
                                className="self-center h-[50px] w-[50px] ml-3"
                                alt=""
                              />
                              <div className="py-4 px-3 self-center">
                                <p className="text-[#4a4a4a] text-[11px] font-Regular">
                                  Customer Status
                                </p>
                                <p className="text-light-black text-sm font-semibold">
                                  {getCustomerStatus(res?.customerStatus?.[0]?.status)}
                                </p>
                              </div>
                            </div>
                          }
                          <div className="col-span-1 flex">
                            <img
                              src={status}
                              className="self-center h-[50px] w-[50px] ml-3"
                              alt=""
                            />
                            <div className="py-4 px-3 self-center w-[67%]">
                              <p className="text-[#4a4a4a] text-[11px] font-Regular">
                                Claim Status
                              </p>
                              <p className="text-light-black text-sm font-semibold break-words w-full capitalize">
                                {getStatus(res?.claimStatus[0]?.status)}
                              </p>
                            </div>
                          </div>
                          <div className="col-span-1 flex">
                            <img
                              src={claimed}
                              className="self-center h-[50px] w-[50px] ml-3"
                              alt=""
                            />
                            <div className="py-4 px-3 self-center w-[67%]">
                              <p className="text-[#4a4a4a] text-[11px] font-Regular">
                                Claim Amount
                              </p>
                              <p className="text-light-black text-sm font-semibold break-words w-full">
                                $
                                {res?.totalAmount === undefined
                                  ? parseInt(0).toLocaleString(2)
                                  : formatOrderValue(
                                    res?.totalAmount ??
                                    parseInt(0)
                                  )}
                              </p>
                            </div>
                          </div>
                        </Grid>

                      </div>
                    );
                  })}
              </>
            )}
          </div>
          <div>
            {totalRecords === 0 && !loaderType ? (
              <>
                <Card className="text-center my-5">
                  <p>No records found.</p>
                </Card>
              </>
            ) : (
              <>
                <CustomPagination
                  totalRecords={totalRecords}
                  page={pageValue}
                  className={loaderType ? "opacity-0	" : "opacity-100"}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  onPageChange={handlePageChange}
                  setRecordsPerPage={setRecordsPerPage}
                />
              </>
            )}
          </div>
        </Card>

      </div>


      <Modal isOpen={isDisapprovedOpen} onClose={closeDisapproved}>
        <Button
          onClick={closeDisapproved}
          className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
        >
          <img
            src={Cross}
            className="w-full h-full text-black rounded-full p-0"
          />
        </Button>
        <div className="py-3">
          <p className="text-center text-3xl font-semibold ">Advance Search</p>
          <form onSubmit={formik1.handleSubmit}>
            <div className="overflow-y-auto max-h-[470px] min-h-[470px] pb-4">
              <Grid className="mt-5 px-6">
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="contractId"
                    className="!bg-white"
                    label="Contract ID"
                    placeholder=""
                    {...formik1.getFieldProps("contractId")}
                  />
                </div>
                <div className="col-span-6">
                  <Input
                    name="claimId"
                    type="text"
                    className="!bg-white"
                    label="Claim ID"
                    placeholder=""
                    {...formik1.getFieldProps("claimId")}
                  />
                </div>

                <div className="col-span-6">
                  <Input
                    type="text"
                    name="orderId"
                    className="!bg-white"
                    label="Order ID"
                    placeholder=""
                    {...formik1.getFieldProps("orderId")}
                  />
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="venderOrder"
                    className="!bg-white"
                    label="Dealer P.O. #."
                    placeholder=""
                    {...formik1.getFieldProps("venderOrder")}
                  />
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="serial"
                    className="!bg-white"
                    label="Serial # / Device ID"
                    placeholder=""
                    {...formik1.getFieldProps("serial")}
                  />
                </div>
                {
                  role == "Super Admin" && (
                    <div className="col-span-6">
                      <Input
                        type="text"
                        name="productName"
                        className="!bg-white"
                        label="Product SKU"
                        placeholder=""
                        {...formik1.getFieldProps("productName")}
                      />
                    </div>
                  )
                }

                <div className="col-span-6">
                  <Input
                    type="text"
                    name="dealerSku"
                    className="!bg-white"
                    label="Dealer SKU"
                    placeholder=""
                    {...formik1.getFieldProps("dealerSku")}
                  />
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="pName"
                    className="!bg-white"
                    label="Product Name"
                    placeholder=""
                    {...formik1.getFieldProps("pName")}
                  />
                </div>
                {/* {props?.flag === "" || props === '' && ( */}
                {role == "Super Admin" && !location.pathname.includes('/resellerDetails') && !location.pathname.includes('/customerDetails') && (
                  <>
                    {
                      !location.pathname.includes('/dealerDetails') &&
                      <div className="col-span-6">
                        <Input
                          type="text"
                          name="dealerName"
                          className="!bg-white"
                          label="Dealer Name"
                          placeholder=""
                          {...formik1.getFieldProps("dealerName")}
                        />
                      </div>
                    }

                    <div className="col-span-6">
                      <Input
                        type="text"
                        name="resellerName"
                        className="!bg-white"
                        label="Reseller Name"
                        placeholder=""
                        {...formik1.getFieldProps("resellerName")}
                      />
                    </div>
                  </>
                )}

                {window.location.pathname.includes("/customer/claimList") ||
                  props?.flag == "customer" ? (
                  ""
                ) : (
                  <div className="col-span-6">
                    <Input
                      type="text"
                      name="customerName"
                      className="!bg-white"
                      label="Customer Name"
                      placeholder=""
                      {...formik1.getFieldProps("customerName")}
                    />
                  </div>
                )}
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="servicerName"
                    className="!bg-white"
                    label="Servicer Name"
                    placeholder=""
                    {...formik1.getFieldProps("servicerName")}
                  />
                </div>

                <div className="col-span-6">
                  <Select
                    name="claimStatus"
                    label="Claim Status"
                    options={claimvaluesOptions?.value}
                    className="!bg-white"
                    onChange={handleSelectChange2}
                    value={formik1.values.claimStatus}
                  />
                </div>
                {formik1.values.claimStatus == "completed" ? (
                  <div className="col-span-6">
                    <Select
                      options={claimPaid}
                      name="claimPaidStatus"
                      label="Paid Status"
                      className="!bg-white"
                      onChange={handleSelectChange2}
                      value={formik1.values.claimPaidStatus}
                    />
                  </div>
                ) : (
                  <>
                    <div className="col-span-6">
                      <Select
                        options={customerValue?.value}
                        name="customerStatusValue"
                        label="Customer Status"
                        className="!bg-white"
                        onChange={handleSelectChange2}
                        value={formik1.values.customerStatusValue}
                      />
                    </div>
                    <div className="col-span-6">
                      <Select
                        options={repairValue?.value}
                        name="repairStatus"
                        label="Repair Status"
                        className="!bg-white"
                        onChange={handleSelectChange2}
                        value={formik1.values.repairStatus}
                      />
                    </div>
                  </>
                )}
                <div className="col-span-6">
                  <Select
                    name="dateFilter"
                    label=" Filter By (Date Range)"
                    options={filterBy}
                    disableFirstOption={true}
                    className="!bg-white"
                    onChange={handleSelectChange2}
                    value={formik1.values.dateFilter}
                  />
                </div>
                {formik1.values.dateFilter != '' && <>
                  <div className="col-span-6">
                    <Input
                      type="date"
                      name="startDate"
                      className="!bg-white z-10"
                      label="Start Date"
                      placeholder=""
                      min={new Date(new Date().setFullYear(new Date().getFullYear() - 1))
                        .toISOString()
                        .split("T")[0]}
                      maxDate={new Date().toISOString().split("T")[0]}
                      {...formik1.getFieldProps("startDate")}
                    />
                  </div>
                  <div className="col-span-6">
                    <Input
                      type="date"
                      name="endDate"
                      className="!bg-white z-10"
                      label="End Date"
                      maxDate={new Date().toISOString().split("T")[0]}
                      placeholder=""
                      {...formik1.getFieldProps("endDate")}
                    />
                  </div>
                </>}

              </Grid>
            </div>
            <Grid className=" px-6">
              <div className="col-span-12">
                <Button type="submit" className={"w-full"}>
                  Search
                </Button>
              </div>
            </Grid>
          </form>
        </div>
      </Modal>

      <Modal isOpen={isReportOpen} onClose={closeReport}>
        <Button
          onClick={closeReport}
          className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
        >
          <img
            src={Cross}
            className="w-full h-full text-black rounded-full p-0"
          />
        </Button>
        <div className="py-3">
          {viewLoader ? (
            <>
              <div className=" h-[400px] w-full flex py-5">
                <div className="self-center mx-auto">
                  <RotateLoader color="#333" />
                </div>
              </div>
            </>
          ) : (
            !reportSuccess ? (<div>
              <p className="text-center text-3xl font-semibold mb-2 mx-auto">
                Generate Report
              </p>
              <p className="text-center w-2/3 mx-auto mb-5">
                This report will include all claims within the specified date range
                along with key details for your review. Please provide the{" "}
                <b>Report Name</b> and any <b>Remarks</b> in the text fields below
                before proceeding.
              </p>
              <div className="px-8">
                <form onSubmit={fileGenrateForm.handleSubmit}>
                  {/* File Name Field */}
                  <div className="col-span-12">
                    <Input
                      type="text"
                      id="reportName"
                      name="reportName"
                      label='File Name'
                      required={true}
                      value={fileGenrateForm.values.reportName}
                      onChange={fileGenrateForm.handleChange}
                      onBlur={fileGenrateForm.handleBlur}
                      className="!bg-white"
                      placeholder=""
                    />
                    {fileGenrateForm.touched.reportName && fileGenrateForm.errors.reportName && (
                      <div className="text-red-500 text-sm">{fileGenrateForm.errors.reportName}</div>
                    )}
                  </div>

                  {/* Remarks Field */}
                  <div className="col-span-12 mt-4">
                    <div className="relative">
                      <label
                        htmlFor="Remark"
                        className="absolute text-base text-[#5D6E66] leading-6 duration-300 transform origin-[0] top-1 bg-white left-2 px-1 -translate-y-4 scale-75"
                      >
                        Remark
                      </label>
                      <textarea
                        id="remark"
                        name="remark"
                        rows="4"
                        maxLength={150}
                        value={fileGenrateForm.values.remark}
                        onChange={fileGenrateForm.handleChange}
                        onBlur={fileGenrateForm.handleBlur}
                        className="block px-2.5 pb-2.5 pt-4 w-full text-base font-semibold text-light-black bg-transparent rounded-lg border-[1px] border-gray-300 appearance-none peer resize-none	"
                        placeholder=""
                      />
                    </div>
                  </div>

                  {/* Submit Buttons */}
                  <div className="col-span-12 mt-6 flex justify-end">
                    <InActiveButton
                      type="button"
                      className="mr-3 "
                      onClick={closeReport1}
                    >
                      Back
                    </InActiveButton>
                    <Button
                      type="submit"
                      className=""
                      disabled={fileGenrateForm.isSubmitting}
                    >
                      {fileGenrateForm.isSubmitting ? 'Generating...' : 'Generate'}
                    </Button>
                  </div>
                </form>
              </div>
            </div>) : (
              <>
                <p className="text-center text-3xl font-semibold mb-2 mx-auto">
                  Generate Report
                </p>
                <p className="text-center w-2/3 mx-auto mb-5">
                  Thank you for generating the report. The report is currently being processed. Once ready, you can download it by visiting the reports page from below link.
                </p>
                <div className="text-center">
                  <Link to={window.location.pathname.includes('customer/claimList') ? "/customer/Reporting/List" : "/Reporting/List"} className="text-blue-500 hover:underline">
                    Go to Reports Page
                  </Link>
                </div>
              </>
            )

          )}
        </div>
      </Modal>

      <Modal isOpen={isDetailsReportOpen} onClose={closeDetailsReport}>
        <Button
          onClick={closeDetailsReport}
          className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
        >
          <img
            src={Cross}
            className="w-full h-full text-black rounded-full p-0"
          />
        </Button>
        <div className="py-3">
          <p className="text-center font-semibold text-2xl mb-3">Generate Detail Report</p>
          <div className="overflow-y-scroll max-h-[450px] pb-3">
            {option.map((option) => (
              <CollapsibleDiv
                key={option.index}
                ShowData={showdata}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                imageClass="w-10 h-10"
                index={option.index}
                title={
                  <SingleView className="border-Gray28 border px-4 py-2 rounded-t-[22px]">
                    <p className="text-lg font-bold">{option.title}</p>
                  </SingleView>
                }
              >
                <Grid className="!grid-cols-3 gap-4 p-4">
                  {option.sections.map((field) => (
                    <p key={field.action} className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        id={field.action}
                        value={field.action}
                        checked={projection[field.action] === 1}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor={field.action}>{field.label}</label>
                    </p>
                  ))}
                </Grid>
              </CollapsibleDiv>
            ))}
          </div>
          <div>
            <Button className='w-full' onClick={openReport}>Generate Report</Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ClaimList;
