import React, { useEffect, useRef, useState } from 'react'
import Grid from '../../../../common/grid'
import Card from '../../../../common/card'
import Select from '../../../../common/select';
import Input from '../../../../common/input';
import checkIcon from "../../../../assets/images/check-mark.png";
import Button from '../../../../common/button';
import Dropbox from "../../../../assets/images/icons/dropBox.svg";
import InActiveButton from '../../../../common/inActiveButton';
import Cross from "../../../../assets/images/Cross.png";
import Modal from '../../../../common/model';
import delete1 from "../../../../assets/images/delete.png";
import Carousel from "react-multi-carousel";
import DeleteImage from "../../../../assets/images/icons/Delete.svg";
import disapproved from "../../../../assets/images/Disapproved.png";
import pen from "../../../../assets/images/pencil.png";
import "react-multi-carousel/lib/styles.css";
import csvFile from "../../../../assets/images/icons/csvFile.svg";
import AddDealer from "../../../../assets/images/dealer-book.svg";
import Login_image from "../../../../assets/images/Login_Page.png";
import SelectBoxWithSearch from '../../../../common/selectBoxWIthSerach';
import { addClaimsRepairParts, checkCoverageTypeDate, deleteClaimImage, editClaimStatus, editClaimTypeValue, getClaimMessages, getClaimTrackRecord, getOptions } from '../../../../services/claimServices';
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import * as Yup from "yup";
import {
    faFileImage,
    faFilePdf,
    faFileWord,
    faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { getClaimValues } from '../../../../services/extraServices';
import { RotateLoader } from 'react-spinners';
import { useNavigate, useParams } from 'react-router-dom';
import { uploadDealerBookInBulk, uploadPrePostImages } from '../../../../services/priceBookService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
function ClaimDetails(props) {
    console.log(props, '---------Props---')

    const [previewImage, setPreviewImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState("");
    const messagesEndRef = useRef(null);
    const [repairValue, repair_status] = useState([]);
    const [loading1, setLoading1] = useState(false);
    const [customerValue, customer_status] = useState([]);
    const [claimvalues, claim_status] = useState([]);
    const [coveragevalues, coverage_type] = useState([]);
    const navigate = useNavigate();
    const [trackerView, setTrackerView] = useState(false)
    const [claimvaluesOptions, claim_statusOptions] = useState([]);
    const [shipment, shipment_type] = useState([]);
    const [claimType, setClaimType] = useState(props?.data?.claimType);
    const [claimList, setClaimList] = useState({});

    const [coverageTypeError, setCoverageTypeError] = useState({});
    const [errorForCoverageType, setErrorForCoverageType] = useState(false);
    const [isReceived, setIsReceived] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isShipped, setIsShipped] = useState(false);
    const [completeLoader, setCompleteLoader] = useState(false);
    const [trackLoader, setTrackLoader] = useState(false);
    const [initialValues, setInitialValues] = useState({
        repairParts: [{ serviceType: "", description: "", price: "" }],
        note: "",
        totalAmount: "",
    });
    const [serviceType, setServiceType] = useState([]);
    const [isRejectOpen, setIsRejectOpen] = useState(false);
    const [claimDetails, setClaimDetails] = useState({});
    const [isServicer, setIsSevicer] = useState();
    const [serviceType1, setServiceType1] = useState([
        { label: "Parts", value: "Parts" },
        { label: "Labor ", value: "Labour" },
        { label: "Shipping", value: "Shipping" },
    ]);
    const [error, setError] = useState("");
    const [allOptions, setAllOptions] = useState([]);
    const [allTrack, setAllTrack] = useState([]);
    const [IsImageOpen, setIsImageOpen] = useState(false)
    const optionStatus = [
        { label: "Claim Status", value: "claim_status" },
        { label: "Customer Status", value: "customer_status" },
        { label: "Repair Status", value: "repair_status" },
    ];
    const [showForm, setShowForm] = useState(false);
    const CoverageStartDate = [
        { label: "11/09/2026", value: true },
        { label: "12/09/2026", value: false },
    ];
    const { claimId } = useParams();
    const [claimStatus, setClaimStatus] = useState({ status: "" });
    const [repairStatus, setRepairStatus] = useState({ status: "" });
    const [editableRow, setEditableRow] = useState([]);
    const [customerStatus, setCustomerStatus] = useState({
        status: "",
    });
    const [imageType, setImageType] = useState();
    const openImage = (data) => {
        setImageType(data)
        setIsImageOpen(true);
    };
    const handleYesClick = () => {
        setShowForm(true);
    };
    const handleDelete = async (key, type) => {
        console.log('delete', key, type);
        let data = {
            "key": key,
            "flag": type
        }
        const result = await deleteClaimImage(claimId, data);
        setClaimDetails(result.result);
    }
    const closeReject = () => {
        setIsRejectOpen(false);
        setShowForm(false);
    };

    const closeImage = () => {
        setIsImageOpen(false);
    };

    const handleChange = (name, value) => {
        formik.setFieldValue(name, value);
    };

    const getClaimDetails = async () => {

        setLoading1(true);

        const result = await getClaimValues(claimId);

        setClaimDetails(result.result);
        setIsSevicer(result.result.resellerDetail.isServicer)
        console.log("hit api ------------------------------------------------------->>>>>>>>>>>>>")
        setLoading1(false);
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const formatOrderValue = (orderValue) => {
        if (Math.abs(orderValue) >= 1e6) {
            return (orderValue / 1e6).toFixed(2) + "M";
        } else {
            return orderValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
    };

    useEffect(() => {

        console.log(props?.data?.claimType, '--------------props')
        getClaimOptions(props?.data?.claimType)
        getClaimDetails();
        getClaimTrack();
    }, [props])

    const getClaimOptions = async (value) => {
        try {
            const data = [
                "coverage_type",
                "repair_status",
                "shipment_type",
                "customer_status",
                "claim_status",

            ];

            const result = await getOptions(data);
            console.log('result', result)
            setAllOptions(result.result)
            const stateSetters = {
                repair_status,
                shipment_type,
                customer_status,
                claim_status,
                coverage_type,
            };

            const filterOptions = (key, options) => {
                if (value === "" || value === "New" || value === undefined) {
                    if (key === "claim_status") {
                        claim_statusOptions(options)
                        return {
                            ...options,
                            value: options?.value?.filter(option => option.value != "completed"),
                        };
                    }
                    if (key === "repair_status") {
                        return {
                            ...options,
                            value: options?.value?.filter(
                                option =>
                                    option.value != "repair_complete" &&
                                    option.value != "servicer_shipped"
                            ),
                        };
                    }
                    if (key === "customer_status") {
                        return {
                            ...options,
                            value: options?.value?.filter(option => option.value != "product_received"),
                        };
                    }
                    if (key === "coverage_type") {
                        return {
                            ...options,

                        };

                    }
                    if (key === "shipment_type") {
                        return {
                            ...options,

                        };
                    }
                } else {

                    return options;
                }


            };


            data.forEach((key, index) => {
                const filteredOptions = filterOptions(key, result.result[index]);
                console.log(result.result[index], filteredOptions, '-------here-----')
                stateSetters[key]?.(filteredOptions);
            });
        } catch (error) {
            console.error("Error fetching claim options:", error);
        }
    };
    const toggleEditRow = (index) => {
        setEditableRow((prev) =>
            prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
        );
    };
    const handleSelectChange21 = (selectedValue, value) => {
        Shipment.setFieldValue(selectedValue, value);
    };

    const Shipment = useFormik({
        initialValues: {
            trackingNumber: "",
            trackingType: "",
        },
        onSubmit: (values) => {
            console.log("Submitting claim repair parts", values);
            addClaimsRepairParts(claimId, values).then(
                (res) => {
                    Shipment.resetForm();
                    setTrackerView(false);
                    getClaimTrack()
                    getClaimDetails();
                }
            );
        },
    });
    const getStatusLabels = (status, statusName) => {
        const matchedCategory = allOptions?.find(category => category.name === statusName);
        if (!matchedCategory) return "Unknown";

        const matchedStatus = matchedCategory.value?.find(s => s.value === status);
        return matchedStatus ? matchedStatus.label : "Unknown";
    };

    const [formData, setFormData] = useState({
        claimStatus: claimDetails?.claimStatus?.[0]?.status,
        repairStatus: claimDetails?.repairStatus?.[0]?.status,
        customerStatus: claimDetails?.customerStatus?.[0]?.status,
        claimType: claimDetails?.claimType
    });

    useEffect(() => {
        setClaimType(props.data.claimType)
        claimDataDetails()
    }, [claimDetails]);

    const claimDataDetails = () => {
        if (claimDetails?.repairParts?.length != 0) {
            const repairPartsValues = claimDetails?.repairParts?.map((part) => ({
                serviceType: part.serviceType || "",
                description: part.description || "",
                price: part.price || "",
                value: true,
            }));

            console.log("repairPartsValues", repairPartsValues);
            setInitialValues((prevValues) => ({
                ...prevValues,
                repairParts: repairPartsValues,
            }));
            formik.setValues({
                repairParts: repairPartsValues,
                note: claimDetails?.note || " ",
            });
        } else {
            formik.setValues({
                repairParts: [{ serviceType: "", description: "", price: "" }],
                note: claimDetails?.note || " ",
            });
        }
        if (claimDetails?.claimStatus?.[0]?.status) {
            setFormData(prev => ({
                ...prev,
                claimStatus: claimDetails?.claimStatus?.[0]?.status,
                repairStatus: claimDetails?.repairStatus?.[0]?.status,
                customerStatus: claimDetails?.customerStatus?.[0]?.status,
                claimType: claimDetails?.claimType
            }));
        }
    }
    console.log(formData, 'formData-----------------------------')

    const formatDateTime = (isoString) => {
        const date = new Date(isoString);
        return date.toLocaleString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true, // 12-hour format with AM/PM
        });
    };
    const handleSelectChange = async (selectedValue, value) => {
        setLoading1(true);
        if (selectedValue === "claimStatus") {
            if (value === "rejected") {
                setIsRejectOpen(true);
            } else if (value?.reason) {
                value.claimStatus = "rejected";
                editClaimRejectedValue(claimId, value);
            }
            else if (value === "completed") {
                setIsComplete(true)

            }
            else {
                const updateAndCallAPI = (setter) => {
                    // setCompleteLoader(true);

                    setter((prevRes) => ({ ...prevRes, status: value }));
                    editClaimValue(
                        claimId,
                        selectedValue,
                        value.type == "completed" ? value.type : value
                    );
                };

                switch (selectedValue) {
                    case "claimStatus":
                        updateAndCallAPI(setClaimStatus);
                        break;
                    default:
                        console.error("here");
                }
            }
        }
        else if (selectedValue === "claimType") {
            // setLoading1(true);
            let data = {
                claimId: claimId,
                coverageType: value
            }
            setFormData((prevFormData) => ({
                ...prevFormData,
                claimType: value
            }));
            checkCoverageTypeDate(data).then((res) => {
                if (res.code == 200) {
                    const updateAndCallAPI = (setter) => {
                        editClaimClaimType(
                            claimId,
                            selectedValue,
                            value
                        );
                    };
                    updateAndCallAPI(setClaimType);
                    getClaimOptions(value)

                }
                else {
                    setCoverageTypeError(res)
                    setErrorForCoverageType(true)
                    // setLoading1(false);
                }
            })
        } else {
            if (value === 'servicer_shipped') {
                setIsShipped(true)
            }
            else if (value === 'product_received') {
                setIsReceived(true)
            }
            else {
                console.log(value)
                // setLoading1(true);
                const updateAndCallAPI = (setter) => {
                    setter((prevRes) => ({ ...prevRes, status: value }));
                    editClaimValue(claimId, selectedValue, (value.type === 'servicer_shipped' || value.type === "product_received") ? value.type : value);
                };
                switch (selectedValue) {
                    case "customerStatus":
                        updateAndCallAPI(setCustomerStatus);
                        setIsReceived(false)
                        break;
                    case "claimStatus":
                        updateAndCallAPI(setClaimStatus);
                        break;
                    case "repairStatus":
                        updateAndCallAPI(setRepairStatus);
                        setIsShipped(false)
                        break;
                    default:
                        console.error("here");
                }
            }
        }


    };

    const editClaimRejectedValue = async (claimId, data) => {
        setLoading1(true);
        try {

            // Step 1: Call editClaimStatus and wait for the response
            const res = await editClaimStatus(claimId, data);
            console.log(res, '----res')
            // Step 2: Update state based on the response
            updateAndSetStatus(setClaimStatus, "claimStatus", res);
            updateAndSetStatus(setRepairStatus, "repairStatus", res);
            updateAndSetStatus(setCustomerStatus, "customerStatus", res);

            if (data.claimStatus === "rejected") {
                const updatedClaimListCopy = { ...claimDetails };
                updatedClaimListCopy.result[claimType] = "rejected"; // Fix assignment operator
            }

            // Step 3: Call getClaimDetails and wait for it to finish


        } catch (error) {
            console.error("Error updating claim status:", error);
        } finally {
            setLoading1(false);
            closeReject();
            closeCoveragType();
            getClaimTrack();
            getClaimDetails();
        }
    };

    const closeCoveragType = () => {
        setErrorForCoverageType(false);;
    };

    const editClaimClaimType = (claimId, statusType, statusValue) => {
        setLoading1(true);

        let data = {
            claimType: statusValue,
        };

        editClaimTypeValue(claimId, data).then(async (res) => {
            // setClaimType(res.result.claimType);
            const updatedClaimListCopy = { ...claimDetails };

            if (updatedClaimListCopy.result) {
                updatedClaimListCopy.result["claimType"] =
                    res.result.claimType;
                if (updatedClaimListCopy.result) {
                    updatedClaimListCopy.result["claimType"] =
                        res.result.claimType;
                    updatedClaimListCopy.result["customerClaimAmount"] =
                        res.result.customerClaimAmount;
                    updatedClaimListCopy.result["customerOverAmount"] =
                        res.result.customerOverAmount;
                    updatedClaimListCopy.result["getCoverClaimAmount"] =
                        res.result.getCoverClaimAmount;
                    updatedClaimListCopy.result["getcoverOverAmount"] =
                        res.result.getcoverOverAmount;
                    await getClaimOptions(res.result.claimType)
                }
            }
            setClaimList(updatedClaimListCopy);
            console.log(res?.result?.claimType, updatedClaimListCopy);
            setClaimType(res.result.claimType);
            getClaimTrack();
            getClaimDetails();
        });
    };

    const editClaimValue = (claimId, statusType, statusValue) => {
        // setCompleteLoader(true);
        let data = {
            [statusType]: statusValue,
        };

        editClaimStatus(claimId, data).then((res) => {
            setLoading1(true);
            setIsComplete(false)
            updateAndSetStatus(setClaimStatus, "claimStatus", res);
            updateAndSetStatus(setRepairStatus, "repairStatus", res);
            updateAndSetStatus(setCustomerStatus, "customerStatus", res);
            getClaimTrack()
            // setCompleteLoader(false);
            getClaimDetails();
        });

    };
    const updateAndSetStatus = (statusObject, name, res) => {
        if (res.code === 200) {
            const resultData = res.result || {};
            const updatedClaimListCopy = { ...claimDetails };
            if (updatedClaimListCopy.result) {
                updatedClaimListCopy.result[name] = resultData[`${name}`];
                updatedClaimListCopy.result[`reason`] = resultData.reason;
            }
            setClaimList(updatedClaimListCopy);
            statusObject({
                status: resultData[`${name}`][resultData[`${name}`].length - 1].status,
                date: resultData[`${name}`][resultData[`${name}`].length - 1].date,
            });
        }

    };
    console.log("Claim Values:", claimvalues?.value);
    console.log("FormData.claimStatus:", formData.claimStatus);
    console.log(
        "Matched Option:",
        (claimvalues?.value ?? []).find(opt => opt.value === formData.claimStatus)
    );

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            repairParts: Yup.array().of(
                Yup.object().shape({
                    serviceType: Yup.string().required("Service Type is required"),
                    description: Yup.string().required("Description is required"),
                    price: Yup.number()
                        .required("Price is required")
                        .min(0.1, "Price must be at least 0.1"),
                })
            ),
        }),
        onSubmit: (values) => {

            // setClaimLoading(true);
            setError("");
            let totalPrice = 0;
            values.repairParts.forEach((part) => {
                totalPrice += Number(part.price);
            });
            values.totalAmount = totalPrice;
            addClaimsRepairParts(claimDetails?._id, values).then((res) => {
                // console.log(res);
                if (res.code == 401) {
                    setError(res.message);
                    // setTimeout(() => {
                    //     setClaimLoading(false);
                    // }, 1000);
                } else {

                    console.log(res)
                    getClaimTrack()
                    setEditableRow([])
                    getClaimDetails()
                    // openAttachments();
                    // setTimer(3);
                    // setIsEditOpen(false);
                    // setTimeout(() => {
                    //     setClaimLoading(false);
                    // }, 1000);
                }
            });
        },
    });

    const handleRemove = (index) => {
        setError("");
        const updatedErrors = { ...formik.errors };
        if (updatedErrors.repairParts) {
            const updatedRepairPartsErrors = updatedErrors.repairParts.slice();
            updatedRepairPartsErrors.splice(index, 1);
            updatedErrors.repairParts = updatedRepairPartsErrors;
        }
        formik.setErrors(updatedErrors);
        const updatedRepairParts = [...formik.values.repairParts];
        updatedRepairParts.splice(index, 1);
        formik.setFieldValue("repairParts", updatedRepairParts);
        setInitialValues((prevValues) => ({
            ...prevValues,
            repairParts: updatedRepairParts,
        }));
    };
    const handleAddMore = () => {

        setError("");
        formik.setFieldValue("repairParts", [
            ...formik.values.repairParts,
            { serviceType: "", description: "", price: "" },
        ]);
        setEditableRow([...editableRow, formik.values.repairParts.length]);
    };

    const closeComplete = () => {
        setIsComplete(false);
    };

    const closeShipped = () => {
        setIsShipped(false);
    };

    const closeReceived = () => {
        setIsReceived(false);
    };

    const fileInputRef = useRef(null);
    const fileInputRef1 = useRef(null);
    const [fileType, setFileType] = useState(null);
    const handleFileSelect = async (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 5) {
            alert("You can upload a maximum of 5 images.");
            return;
        }

        const imagePreviews = [];
        const formData = new FormData();

        files.forEach((file, index) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                imagePreviews.push(event.target.result);
                if (imagePreviews.length === files.length) {
                    setPreviewImage(imagePreviews);
                }
            };
            reader.readAsDataURL(file);
            formData.append("file", file);
        });

        try {
            const result = await uploadPrePostImages(claimId, "preUpload", formData);
            console.log("Upload result:", result);
            getClaimDetails(result.result);
        } catch (error) {
            console.error("File upload failed:", error);
        }
    };

    const handleFileSelect1 = async (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 5) {
            alert("You can upload a maximum of 5 images.");
            return;
        }

        const imagePreviews = [];
        const formData = new FormData();

        files.forEach((file, index) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                imagePreviews.push(event.target.result);
                if (imagePreviews.length === files.length) {
                    setPreviewImage(imagePreviews);
                }
            };
            reader.readAsDataURL(file);
            formData.append("file", file);
        });

        try {
            const result = await uploadPrePostImages(claimId, "postUpload", formData);
            console.log("Upload result:", result);
            getClaimDetails(result.result);
        } catch (error) {
            console.error("File upload failed:", error);
        }
    };


    const handleDropdownClick = () => {
        if (fileInputRef) {
            fileInputRef.current.click();
            setSelectedFile(null);
            console.log("formik value becomes empty")
            file.setFieldValue("file", "");
            file.setFieldTouched("file", false);
            setError("");
        }
    };

    const handleDropdownClick1 = () => {
        if (fileInputRef1) {
            fileInputRef1.current.click();
            setSelectedFile(null);
            console.log("formik value becomes empty")
            file.setFieldValue("file", "");
            file.setFieldTouched("file", false);
            setError("");
        }
    };

    const file = useFormik({
        initialValues: {
            file: null,
        },
        validationSchema: Yup.object({
            file: Yup.mixed()
                .test("fileRequired", "File is required", (value) => {
                    return value && value instanceof File; // Ensure it's a valid file
                }),
        }),
        onSubmit: async (values) => {
            try {
                setLoading1(true);
                console.log("Submitting file:", values.file);

                const formData = new FormData();
                formData.append("file", values.file);

                const result = await uploadPrePostImages("preUpload", formData);
                console.log("Upload result:", result);

                if (result.status === 400) {
                    setError(result.message);
                }
            } catch (error) {
                console.error("Upload failed:", error);
            } finally {
                setLoading1(false);
            }
        },
    });
    const getFileType = (fileType) => {
        if (fileType.includes("image")) {
            return "image";
        } else if (fileType.includes("pdf")) {
            return "pdf";
        } else if (
            fileType.includes("spreadsheetml.sheet") ||
            fileType.includes("excel")
        ) {
            return "xlsx";
        } else if (fileType.includes("csv")) {
            return "csv";
        } else if (fileType.includes("ms-excel")) {
            return "xls";
        } else {
            return "file";
        }
    };

    const [selectedStatus, setSelectedStatus] = useState("");

    const handleStatusChange = (selectedValue, value) => {
        console.log(selectedValue, value, '----yes')
        setSelectedStatus(value); // Update the state
        getClaimTrack(value); // Call API with new status
    };


    const getClaimTrack = (status) => {
        setTrackLoader(true);
        let data = {
            status: status || "", // Send selected status
        };
        getClaimTrackRecord(claimId, data)
            .then((res) => {
                setAllTrack(res.result);
            })
            .catch((error) => {
                console.error("Error fetching claim messages:", error);
            })
            .finally(() => {
                setTrackLoader(false);
            });
    };

    const handleDownload = (url) => {

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "image.pdf"); // Suggests filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            {loading1 ? (
                <div className="h-[400px] w-full flex py-5">
                    <div className="self-center mx-auto">
                        <RotateLoader color="#333" />
                    </div>
                </div>
            ) :
                <div>
                    <Grid className='mb-4'>
                        <div className='col-span-8'>
                            <Card className="mt-6 border-[1px] border-Light-Grey rounded-xl !h-auto">
                                <div className=" mb-3 ">
                                    <Grid className=" !pt-0 !pb-3">
                                        <div className="col-span-5 px-2 self-center">
                                            <p className="text-xl font-semibold">Claim Details</p>
                                        </div>
                                        <div className="col-span-7 text-right">

                                        </div>
                                    </Grid>
                                    <div>
                                        {
                                            claimDetails?.claimStatus?.[0]?.status ===
                                            "rejected" &&
                                            <div>
                                                <p className="pl-2 text-base mb-3 font-Regular"><b>Reason of rejection : </b> {claimDetails?.reason}</p>
                                            </div>}
                                        <Grid >
                                            <div className="col-span-4 pl-2">
                                                <p className="text-base  font-Regular">
                                                    GetCover Cost :{" "}
                                                    <span className="font-semibold text-base mb-3">
                                                        {" "}
                                                        $
                                                        {claimDetails?.getCoverClaimAmount === undefined
                                                            ? parseInt(0).toLocaleString(2)
                                                            : formatOrderValue(
                                                                (parseFloat(claimDetails?.getCoverClaimAmount) || 0) +
                                                                (parseFloat(claimDetails?.getcoverOverAmount) || 0)
                                                            )}
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="col-span-4">
                                                <p className="text-base font-Regular">
                                                    Customer Cost :{" "}
                                                    <span className="font-semibold text-base mb-3">
                                                        $
                                                        {claimDetails?.customerClaimAmount === undefined
                                                            ? parseInt(0).toLocaleString(2)
                                                            : formatOrderValue(
                                                                (parseFloat(claimDetails?.customerClaimAmount) || 0) +
                                                                (parseFloat(claimDetails?.customerOverAmount) || 0)
                                                            )}
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="col-span-4">
                                                <p className=" text-base mb-3 font-Regular">
                                                    Total Cost :{" "}
                                                    <span className="font-semibold ml-3">
                                                        {" "}
                                                        $
                                                        {claimDetails?.totalAmount === undefined
                                                            ? parseInt(0).toLocaleString(2)
                                                            : formatOrderValue(claimDetails?.totalAmount ?? parseInt(0))}
                                                    </span>
                                                </p>
                                            </div>
                                        </Grid>
                                        <Grid >

                                            <div className="col-span-4 pl-2">

                                                <Select
                                                    options={claimvalues?.value ?? []}
                                                    name="claimStatus"
                                                    label="Claim Status"
                                                    disableFirstOption={true}
                                                    value={claimvalues?.value?.find(opt => opt.value === formData.claimStatus)?.value || null}
                                                    disabled={true}
                                                    onChange={handleSelectChange}
                                                    className="!bg-white"
                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <Select
                                                    options={repairValue?.value ?? []}
                                                    name="repairStatus"
                                                    label="Repair Status"
                                                    value={repairValue?.value?.find(opt => opt.value === formData.repairStatus)?.value || null}
                                                    disableFirstOption={true}
                                                    disabled={
                                                        isServicer ?
                                                            claimDetails?.claimStatus?.[0]?.status ==
                                                            "rejected" ||
                                                            claimDetails?.claimStatus?.[0]?.status ==
                                                            "completed" || claimDetails?.repairStatus?.[0]?.value == "servicer_shipped" :
                                                            true
                                                    }
                                                    onChange={handleSelectChange}
                                                    className="!bg-white"
                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <Select
                                                    options={customerValue?.value ?? []}
                                                    name="customerStatus"
                                                    label="Customer Status"
                                                    value={customerValue?.value?.find(opt => opt.value === formData.customerStatus)?.value || null}
                                                    disableFirstOption={true}
                                                    disabled={
                                                        claimDetails?.claimStatus?.[0]?.status == "rejected" ||
                                                        claimDetails?.claimStatus?.[0]?.status == "completed"
                                                    }
                                                    onChange={handleSelectChange}
                                                    className="!bg-white"
                                                />
                                            </div>
                                        </Grid>
                                        <Grid className="grid-cols-3 mt-4">
                                            <div className="pl-2 col-span-1">
                                                <Select
                                                    options={claimDetails?.contractDetail?.coverageType ?? []}
                                                    name="claimType"
                                                    label="Coverage Type"
                                                    disabled={
                                                        isServicer ?
                                                            claimDetails?.claimStatus?.[0]?.status == "rejected" ||
                                                            claimDetails?.claimStatus?.[0]?.status == "completed" || repairStatus.status == "repair_complete" || repairStatus.status == "servicer_shipped"
                                                            : true
                                                    }
                                                    value={claimDetails?.contractDetail?.coverageType?.find(opt => opt.value === formData.claimType)?.value || null}
                                                    onChange={handleSelectChange}
                                                    className="!bg-white"
                                                />
                                            </div>
                                            <div className=' col-span-2 self-center'>

                                                <>
                                                    {trackerView || (
                                                        claimDetails?.claimStatus?.[0]?.status === "rejected" ||
                                                        claimDetails?.claimStatus?.[0]?.status === "completed" ||
                                                        claimDetails?.trackingType === ""
                                                    ) ? (
                                                        <>
                                                            {isServicer && <form onSubmit={Shipment.handleSubmit}>
                                                                <div className="relative flex w-full">
                                                                    <Select
                                                                        name="trackingType"
                                                                        label="Shipment"
                                                                        options={shipment?.value}
                                                                        value={
                                                                            Shipment.values
                                                                                .trackingType
                                                                        }
                                                                        onChange={
                                                                            handleSelectChange21
                                                                        }
                                                                        classBox="w-[35%] "
                                                                        className1='!border-r-0 !rounded-r-[0px]'
                                                                        className="!bg-white"
                                                                    />
                                                                    <Input
                                                                        name="trackingNumber"
                                                                        label=""
                                                                        placeholder="Tracking #"
                                                                        white
                                                                        className1="!rounded-l-[0px]"
                                                                        required={true}
                                                                        classBox="w-[50%]"
                                                                        value={Shipment.values.trackingNumber}
                                                                        {...Shipment.getFieldProps("trackingNumber")}
                                                                    />
                                                                    <Button className="ml-2" type="submit">
                                                                        <img src={checkIcon} className="w-[21px]" />
                                                                    </Button>
                                                                </div>
                                                            </form>
                                                            }
                                                        </>

                                                    ) : (
                                                        <div className="flex self-center justify-center">
                                                            <p className="mr-3 font-semibold">Shipment Link :</p>

                                                            {claimDetails?.trackingType === "ups" && (
                                                                <a
                                                                    className="text-base border-2 self-center rounded-3xl px-4"
                                                                    href={`https://www.ups.com/track?track=yes&trackNums=${claimDetails?.trackingNumber}&loc=en_US&requester=ST/`}
                                                                    target="_blank"
                                                                >
                                                                    UPS Tracker
                                                                </a>
                                                            )}

                                                            {claimDetails?.trackingType === "usps" && (
                                                                <a
                                                                    className="text-base border-2 self-center rounded-3xl px-4"
                                                                    href={`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${claimDetails?.trackingNumber}`}
                                                                    target="_blank"
                                                                >
                                                                    USPS Tracker
                                                                </a>
                                                            )}

                                                            {claimDetails?.trackingType === "fedx" && (
                                                                <a
                                                                    className="text-base border-2 self-center rounded-3xl px-4"
                                                                    href={`https://www.fedex.com/fedextrack/system-error?trknbr=${claimDetails?.trackingNumber}`}
                                                                    target="_blank"
                                                                >
                                                                    FedX Tracker
                                                                </a>
                                                            )}
                                                            {isServicer &&
                                                                <>
                                                                    {claimDetails?.claimStatus?.[0]?.status !== "rejected" &&
                                                                        claimDetails?.claimStatus?.[0]?.status !== "completed" && (
                                                                            <div
                                                                                style={{
                                                                                    maskImage: `url(${pen})`,
                                                                                    WebkitMaskImage: `url(${pen})`,
                                                                                    maskRepeat: "no-repeat",
                                                                                    WebkitMaskRepeat: "no-repeat",
                                                                                    maskPosition: "center",
                                                                                    WebkitMaskPosition: "center",
                                                                                    maskSize: "contain",
                                                                                    WebkitMaskSize: "contain",
                                                                                    backgroundColor: "#0000 !important",
                                                                                    color: "#0000 !important",
                                                                                    height: "20px",
                                                                                    width: "20px",
                                                                                }}
                                                                                onClick={() => {
                                                                                    console.log("Edit button clicked");
                                                                                    setTrackerView(true);
                                                                                }}
                                                                                className="cursor-pointer !bg-black object-contain ml-4"
                                                                            />
                                                                        )}
                                                                </>}


                                                        </div>
                                                    )}

                                                </>
                                                {/* } */}
                                            </div>

                                        </Grid>
                                    </div>
                                    <div className=' m-2 p-2'>
                                        <Grid className=''>
                                            <div className='col-span-4'>
                                                <p className='font-semibold'>Submitted By (Name) : </p>
                                                <p className='break-words'>{claimDetails?.userInfo}</p>
                                            </div>
                                            <div className='col-span-4'>
                                                <p className='font-semibold'>Submitted By (Email) : </p>
                                                <p className='break-words'>{claimDetails?.submittedBy}</p>
                                            </div>
                                            <div className='col-span-4'>
                                                <p className='font-semibold'>Shipping To (Address) : </p>
                                                <p className='break-words'>{claimDetails?.shippingTo}</p>
                                            </div>
                                        </Grid>
                                    </div>
                                    <div className="mb-3">
                                        <div className='m-2 p-2'>
                                            <p className='font-semibold'>Diagnosis</p>
                                            <p className=''>{claimDetails?.diagnosis}</p>
                                        </div>
                                    </div>
                                </div>
                            </Card >
                            <Card className="mt-6 border-[1px] border-Light-Grey rounded-xl !h-auto">
                                <Grid className=" !pt-0 !pb-3">
                                    <div className="col-span-5 px-2 self-center">
                                        <p className="text-xl font-semibold">Product Details</p>
                                    </div>
                                    <div className="col-span-7 text-right">

                                    </div>
                                </Grid>
                                <div className=" mb-3 px-2">
                                    <Grid >
                                        <div className='col-span-6'>
                                            <p className='font-semibold'>Pre-Repair</p>
                                            {claimDetails?.claimStatus?.[0]?.status !== "rejected" &&
                                                claimDetails?.claimStatus?.[0]?.status !== "completed" &&
                                                <>
                                                    {isServicer &&
                                                        <>
                                                            <p className='leading-[12px] mb-3 text-sm'><b>Note :</b> You can upload up to 5 images, with a maximum size of 10MB per image. </p>
                                                            <button
                                                                type="button"
                                                                onClick={handleDropdownClick}
                                                                className='bg-[#F2F2F2] border-[1px] border-[#D1D9E2] py-10 w-full rounded-md focus:outline-none focus:border-blue-500 !bg-transparent  text-center border-dashed'>
                                                                <img
                                                                    src={Dropbox}
                                                                    className="mx-auto"
                                                                    alt="Dropbox"
                                                                />
                                                            </button>
                                                            <input
                                                                type="file"
                                                                ref={fileInputRef}
                                                                multiple
                                                                accept=".jpg, .jpeg, .png, .csv, .pdf, .xls, .xlsx"
                                                                style={{ display: "none" }}
                                                                onChange={handleFileSelect}
                                                            />
                                                            {error && (
                                                                <p style={{ color: "red" }}>{error}</p>
                                                            )}
                                                        </>}

                                                </>}

                                            <div className='mt-5 pl-[16px]'>
                                                {claimDetails?.preRepairImage != '' &&
                                                    <div className='flex flex-wrap'>
                                                        {claimDetails?.preRepairImage?.map((imagess, index) => (
                                                            <>
                                                                <div className=' cursor-pointer relative' key={index} >
                                                                    {/* {imagess.originalname} */}
                                                                    {getFileType(imagess.mimetype) === "image" && (
                                                                        <img
                                                                            onClick={() => openImage('preRepairImage')}
                                                                            src={imagess.location}
                                                                            alt="Preview"
                                                                            className="w-[75px] h-[55px] pr-[10px] mb-3"
                                                                        />
                                                                    )}
                                                                    {getFileType(imagess.mimetype) === "pdf" && (
                                                                        <FontAwesomeIcon icon={faFilePdf} size="4x" onClick={() => handleDownload(imagess.location)} className='w-[75px] h-[55px] mb-3' />
                                                                    )}
                                                                    {(getFileType(imagess.mimetype) === "csv" ||
                                                                        getFileType(imagess.mimetype) === "xlsx" ||
                                                                        getFileType(imagess.mimetype) === "xls") && (
                                                                            <FontAwesomeIcon icon={faFileImage} onClick={() => handleDownload(imagess.location)} size="4x" className='w-[75px] h-[55px] mb-3' />
                                                                        )}
                                                                    {getFileType(imagess.mimetype) === "word" && (
                                                                        <FontAwesomeIcon onClick={() => handleDownload(imagess.location)} icon={faFileWord} size="4x" className='w-[75px] h-[55px] mb-3' />
                                                                    )}
                                                                    {getFileType(imagess.mimetype) === "excel" && (
                                                                        <FontAwesomeIcon onClick={() => handleDownload(imagess.location)} icon={faFileExcel} size="4x" className='w-[75px] h-[55px] mb-3' />
                                                                    )}
                                                                    {claimDetails?.claimStatus?.[0]?.status !== "rejected" &&
                                                                        claimDetails?.claimStatus?.[0]?.status !== "completed" &&
                                                                        <>
                                                                            <img
                                                                                src={Cross}
                                                                                alt="Preview"
                                                                                className="cursor-pointer absolute top-[-13px] right-[3px]"
                                                                                onClick={() => handleDelete(imagess.key, "preUploadImage")}
                                                                                style={{
                                                                                    width: "20px",
                                                                                    height: "20px",
                                                                                    marginTop: "5px",
                                                                                }}
                                                                            /> </>
                                                                    }
                                                                </div>
                                                            </>
                                                        ))}
                                                    </div>}

                                            </div>
                                        </div>
                                        <div className='col-span-6'>
                                            <p className='font-semibold'>Post-Repair</p>
                                            {claimDetails?.claimStatus?.[0]?.status !== "rejected" &&
                                                claimDetails?.claimStatus?.[0]?.status !== "completed" &&
                                                <>
                                                    {isServicer &&
                                                        <>
                                                            <p className='leading-[12px] mb-3 text-sm'><b>Note :</b> You can upload up to 5 images, with a maximum size of 10MB per image. </p>
                                                            <button
                                                                type="button"
                                                                onClick={handleDropdownClick1}
                                                                className='bg-[#F2F2F2] border-[1px] border-[#D1D9E2] py-10 w-full rounded-md focus:outline-none focus:border-blue-500 !bg-transparent  text-center border-dashed'>

                                                                <img
                                                                    src={Dropbox}
                                                                    className="mx-auto"
                                                                    alt="Dropbox"
                                                                />
                                                            </button>
                                                            <input
                                                                type="file"
                                                                ref={fileInputRef1}
                                                                multiple
                                                                accept=".jpg, .jpeg, .png, .csv, .pdf, .xls, .xlsx"
                                                                style={{ display: "none" }}
                                                                onChange={handleFileSelect1}
                                                            />
                                                            {file.errors.file && file.touched.file && (
                                                                <p style={{ color: "red" }}>{file.errors.file}</p>
                                                            )}
                                                        </>}
                                                </>
                                            }

                                            <div className='mt-5 pl-[16px]'>
                                                {claimDetails?.postRepairImage != '' &&
                                                    <div className='flex flex-wrap'>
                                                        {claimDetails?.postRepairImage?.map((imagess, index) => (
                                                            <>
                                                                <div className=' cursor-pointer relative' key={index} >
                                                                    {/* {imagess.originalname} */}
                                                                    {getFileType(imagess.mimetype) === "image" && (
                                                                        <img
                                                                            src={imagess.location}
                                                                            alt="Preview"
                                                                            onClick={() => openImage('postRepairImage')}
                                                                            className="w-[75px] h-[55px] pr-[10px] mb-3"
                                                                        />
                                                                    )}
                                                                    {getFileType(imagess.mimetype) === "pdf" && (
                                                                        <FontAwesomeIcon icon={faFilePdf} onClick={() => handleDownload(imagess.location)} size="4x" className='w-[75px] h-[55px] mb-3' />
                                                                    )}
                                                                    {(getFileType(imagess.mimetype) === "csv" ||
                                                                        getFileType(imagess.mimetype) === "xlsx" ||
                                                                        getFileType(imagess.mimetype) === "xls") && (
                                                                            <FontAwesomeIcon icon={faFileImage} onClick={() => handleDownload(imagess.location)} size="4x" className='w-[75px] h-[55px] mb-3' />
                                                                        )}
                                                                    {getFileType(imagess.mimetype) === "word" && (
                                                                        <FontAwesomeIcon icon={faFileWord} onClick={() => handleDownload(imagess.location)} size="4x" className='w-[75px] h-[55px] mb-3' />
                                                                    )}
                                                                    {getFileType(imagess.mimetype) === "excel" && (
                                                                        <FontAwesomeIcon icon={faFileExcel} onClick={() => handleDownload(imagess.location)} size="4x" className='w-[75px] h-[55px] mb-3' />
                                                                    )}
                                                                    {claimDetails?.claimStatus?.[0]?.status !== "rejected" &&
                                                                        claimDetails?.claimStatus?.[0]?.status !== "completed" &&
                                                                        <>
                                                                            <img
                                                                                src={Cross}
                                                                                alt="Preview"
                                                                                className="cursor-pointer absolute top-[-13px] right-[3px]"
                                                                                onClick={() => handleDelete(imagess.key, "postUploadImage")}
                                                                                style={{
                                                                                    width: "20px",
                                                                                    height: "20px",
                                                                                    marginTop: "5px",
                                                                                }}
                                                                            />
                                                                        </>
                                                                    }
                                                                </div>
                                                            </>
                                                        ))
                                                        }

                                                    </div>}
                                            </div>
                                        </div>


                                    </Grid>
                                </div>
                            </Card>
                        </div >
                        <div className='col-span-4'>
                            <Card
                                className={`${claimDetails?.preRepairImage?.length > 0 || claimDetails?.postRepairImage?.length > 0
                                    ? claimDetails?.preRepairImage?.length > 3 || claimDetails?.postRepairImage?.length > 3
                                        ? 'max-h-[833px] '
                                        : 'max-h-[766px] '
                                    : 'max-h-[698px]'} mt-6 border-[1px] border-Light-Grey rounded-xl shadow overflow-y-scroll`}
                            >
                                <div className="  ">
                                    <Grid className="!px-[16px] !pb-0 p-2 sticky top-[-8px] pt-3 bg-white z-[9] ">
                                        <div className='col-span-6'>
                                            <p className="text-xl  font-semibold">Track Details</p>
                                        </div>
                                        <div className="col-span-6">
                                            <Select
                                                name="type"
                                                options={optionStatus}
                                                placeholder=""
                                                OptionName='All'
                                                classBox="w-full self-center"

                                                onChange={handleStatusChange}
                                                className="!text-[14px] !bg-White-Smoke"
                                                className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                                            />
                                        </div>
                                    </Grid>

                                    <div className="w-full max-w-md mx-auto p-4 pb-0 ">
                                        <div className='px-2'>
                                            {trackLoader ? <>
                                                <div className="h-[400px] w-full flex py-5">
                                                    <div className="self-center mx-auto">
                                                        <RotateLoader color="#333" />
                                                    </div>
                                                </div>
                                            </> :
                                                <>
                                                    {allTrack?.length > 0 ? (
                                                        allTrack.map((claim, index) => (
                                                            <div
                                                                key={index}
                                                                className={`py-4 px-2 flex ${claim.selected ? "bg-blue-100" : ""} mb-2 relative 
                                                ${index === 0 ? "border-l-2 before:absolute before:w-[2px] before:h-1/2 before:bg-[#fff] before:left-[-2px] before:top-[17px] before:-translate-y-1/2" : "border-l-2"}
                                                ${index === allTrack.length - 1 ? "after:absolute after:w-[2px] after:h-1/2 after:bg-[#fff] after:left-[-2px] after:bottom-[-27px] after:-translate-y-1/2 pb-6 " : ""} 
                                                border-dashed border-blue-500`}

                                                            >
                                                                <div className='self-center absolute left-[-9px]'>
                                                                    <div className='bg-blue-500 h-4 w-4 rounded-full'></div>
                                                                </div>
                                                                <div className='pl-2'>
                                                                    <h3 className="text-sm font-semibold">{claim?.userName ? claim.userName : "System Update"}</h3>
                                                                    <p className="text-xs text-gray-600">{formatDateTime(claim?.date)}</p>
                                                                    <p className="text-xs text-gray-500">
                                                                        <span className="font-semibold">
                                                                            {optionStatus?.find(status => status.value === claim?.statusName)?.label || "Unknown"}
                                                                        </span>
                                                                        {" "}
                                                                        {getStatusLabels(claim?.status, claim?.statusName) || "N/A"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>No track status available</p> // Show a fallback message if no data exists
                                                    )}
                                                </>}
                                        </div>
                                    </div>

                                </div>
                            </Card>
                        </div>
                    </Grid >

                    <Grid>
                        <div className='col-span-12'>
                            <Card className='mt-6 border-[1px] px-2 border-Light-Grey rounded-xl shadow h-auto'>
                                <form onSubmit={formik.handleSubmit} className="mt-2 mr-4 !px-[16px]">
                                    <div className="pb-4  mb-5 ">
                                        <p className='pb-4 text-lg font-semibold'>Repair Parts</p>
                                        <div className="mb-5 grid grid-cols-12 gap-4">
                                            {claimDetails?.claimStatus?.[0]?.status !== "rejected" &&
                                                claimDetails?.claimStatus?.[0]?.status !== "completed" &&
                                                <>
                                                    {isServicer &&
                                                        <div className="col-span-2">
                                                            <p className='text-base font-semibold'>Action</p>
                                                        </div>
                                                    }
                                                </>
                                            }
                                            <div className="col-span-2">
                                                <p className='text-base font-semibold'>Service Type</p>
                                            </div>
                                            <div className="col-span-2">
                                                <p className='text-base font-semibold'>Price ($)</p>
                                            </div>
                                            <div className="col-span-6">
                                                <p className='text-base font-semibold'>Description</p>
                                            </div>
                                        </div>
                                        {formik?.values?.repairParts?.map((part, index) => {
                                            // console.log('initialValues[index]',initialValues.repairParts[index])
                                            return (
                                                <div className="mb-5 grid grid-cols-12 gap-4" key={index}>
                                                    {claimDetails?.claimStatus?.[0]?.status !== "rejected" &&
                                                        claimDetails?.claimStatus?.[0]?.status !== "completed" &&
                                                        <>
                                                            {index == 0 || isServicer && (
                                                                <div className="col-span-1 self-center rounded-[4px] flex justify-center">
                                                                    <Button
                                                                        type="button"
                                                                        className="!text-sm"
                                                                        onClick={handleAddMore}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            )}

                                                            {index > 0 || isServicer && (
                                                                <div className="col-span-1 self-center rounded-[4px] flex justify-center">
                                                                    <div
                                                                        className="flex h-full justify-center cursor-pointer"
                                                                        onClick={() => handleRemove(index)}
                                                                    >
                                                                        <img src={delete1} className='w-8 h-8' />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {initialValues?.repairParts?.[index]?.price || isServicer &&
                                                                <div className="col-span-1 self-center flex justify-center">
                                                                    <div
                                                                        onClick={() => toggleEditRow(index)}
                                                                        className="cursor-pointer"
                                                                        style={{
                                                                            maskImage: `url(${pen})`,
                                                                            WebkitMaskImage: `url(${pen})`,
                                                                            maskRepeat: "no-repeat",
                                                                            WebkitMaskRepeat: "no-repeat",
                                                                            maskPosition: "center",
                                                                            WebkitMaskPosition: "center",
                                                                            maskSize: "contain",
                                                                            WebkitMaskSize: "contain",
                                                                            backgroundColor: "#000",
                                                                            height: '20px',
                                                                            width: '20px',
                                                                        }}
                                                                    />
                                                                </div>}</>
                                                    }

                                                    <div className="col-span-2">
                                                        {formik?.values?.repairParts[index]?.value ? (
                                                            <>
                                                                {/* <p className='text-base font-semibold'>Service Type</p> */}
                                                                <p className='text-sm'>
                                                                    {formik.values.repairParts[index].serviceType}
                                                                </p>
                                                            </>
                                                        ) : (
                                                            <Select
                                                                name={`repairParts[${index}].serviceType`}
                                                                label="Service Type"
                                                                options={
                                                                    index === 0
                                                                        ? serviceType1.filter(
                                                                            (option) => option.label !== "Shipping"
                                                                        )
                                                                        : serviceType1
                                                                }
                                                                required={true}
                                                                className="!bg-white"
                                                                disableFirstOption={true}
                                                                disabled={
                                                                    formik?.values?.repairParts[index]?.value || !isServicer
                                                                }
                                                                placeholder=""
                                                                maxLength={"30"}
                                                                className1=""
                                                                value={
                                                                    formik.values.repairParts[index].serviceType ||
                                                                    ""
                                                                }
                                                                onChange={handleChange}
                                                                onBlur={formik.handleBlur}
                                                                error={
                                                                    formik.touched.repairParts &&
                                                                    formik.touched.repairParts[index] &&
                                                                    formik.errors.repairParts &&
                                                                    formik.errors.repairParts[index] &&
                                                                    formik.errors.repairParts[index].serviceType
                                                                }
                                                            />
                                                        )}
                                                        {formik.touched.repairParts &&
                                                            formik.touched.repairParts[index] &&
                                                            formik.errors.repairParts &&
                                                            formik.errors.repairParts[index]?.serviceType && (
                                                                <div className="text-red-500 text-[13px]">
                                                                    {formik.errors.repairParts[index].serviceType}
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="col-span-2">
                                                        {editableRow?.includes(index) || !initialValues?.repairParts?.[index]?.price ? (
                                                            <>
                                                                <Input
                                                                    type="number"
                                                                    id={`price-${index}`}
                                                                    label="Price ($)"
                                                                    name={`repairParts[${index}].price`}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    disabled={!isServicer}
                                                                    value={formik.values.repairParts[index].price}
                                                                    className="!bg-white"
                                                                    className1="w-full rounded-md"
                                                                />
                                                                {formik.touched.repairParts &&
                                                                    formik.touched.repairParts[index] &&
                                                                    formik.errors.repairParts &&
                                                                    formik.errors.repairParts[index]?.price && (
                                                                        <div className="text-red-500 text-[13px]">
                                                                            {formik.errors.repairParts[index].price}
                                                                        </div>
                                                                    )}
                                                            </>
                                                        ) : (
                                                            <p className="text-sm">
                                                                ${formik.values.repairParts[index].price || "0.00"}
                                                            </p>
                                                        )}
                                                    </div>

                                                    {/* Description Column */}
                                                    <div className="col-span-6">
                                                        {editableRow?.includes(index) || !initialValues?.repairParts?.[index]?.description ? (
                                                            <>
                                                                <Input
                                                                    type="text"
                                                                    label="Description"
                                                                    id={`description-${index}`}
                                                                    name={`repairParts[${index}].description`}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.repairParts[index].description}
                                                                    disabled={!isServicer}
                                                                    className="!bg-white"
                                                                    className1="w-full"
                                                                />
                                                                {formik.touched.repairParts &&
                                                                    formik.touched.repairParts[index] &&
                                                                    formik.errors.repairParts &&
                                                                    formik.errors.repairParts[index]?.description && (
                                                                        <div className="text-red-500 text-[13px]">
                                                                            {formik.errors.repairParts[index].description}
                                                                        </div>
                                                                    )}
                                                            </>
                                                        ) : (
                                                            <p className="text-sm">
                                                                {formik.values.repairParts[index].description}
                                                            </p>
                                                        )}
                                                    </div>


                                                </div>
                                            );
                                        })}
                                        <div ref={messagesEndRef} />
                                    </div>

                                    <hr />
                                    <div className="relative mt-4 ">
                                        <label
                                            htmlFor="description"
                                            className="absolute text-base text-[#5D6E66] leading-6 duration-300 transform origin-[0] top-1 bg-white left-6 px-1 -translate-y-4 scale-75"
                                        >
                                            Note <span className="text-red-500">*</span>
                                        </label>
                                        <textarea
                                            id="note"
                                            rows="4"
                                            name="Note"
                                            disabled={isServicer ? claimDetails?.claimStatus?.[0]?.status === "rejected" ||
                                                claimDetails?.claimStatus?.[0]?.status === "completed" : true
                                            }
                                            // maxLength={150}
                                            className="block px-2.5 pb-2.5 pt-4 w-full text-base font-semibold text-light-black bg-transparent rounded-lg border-[1px] border-gray-300 appearance-none peer resize-none	"
                                        ></textarea>
                                    </div>
                                    {isServicer &&
                                        <div className='mt-4 text-right !px-[16px]'>
                                            <Button type='submit'>Update</Button>
                                        </div>}

                                </form>
                            </Card>
                        </div>
                    </Grid >

                </div >}

            <Modal isOpen={IsImageOpen} onClose={closeImage}>
                <Button
                    onClick={closeImage}
                    className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
                >
                    <img
                        src={Cross}
                        className="w-full h-full text-black rounded-full p-0"
                    />
                </Button>
                <p className='pb-4 pt-1 text-xl text-center font-semibold'>{imageType === "postRepairImage" ? 'Post-Repair' : 'Pre-Repair'} Images</p>
                <div className='mx-5 relative'>
                    {imageType === "postRepairImage" ? <>
                        <Carousel responsive={responsive}>
                            {claimDetails?.postRepairImage
                                ?.filter((imagess) => getFileType(imagess.mimetype) === "image") // Filter only images
                                .map((imagess, index) => (
                                    <div key={index}>
                                        <img src={imagess.location} alt={`Repair Image ${index}`} className="h-[350px] mx-auto" />
                                    </div>
                                ))}
                        </Carousel>
                    </>
                        :
                        <>
                            <Carousel responsive={responsive}>
                                {claimDetails?.preRepairImage
                                    ?.filter((imagess) => getFileType(imagess.mimetype) === "image") // Filter only images
                                    .map((imagess, index) => (
                                        <div key={index}>
                                            <img src={imagess.location} alt={`Repair Image ${index}`} className="h-[350px] mx-auto" />
                                        </div>
                                    ))}
                            </Carousel>
                        </>
                    }
                </div>
            </Modal>

            <Modal isOpen={isComplete} onClose={closeComplete}>
                {completeLoader ? <div className=" h-[350px] w-full flex py-5">
                    <div className="self-center mx-auto">
                        <RotateLoader color="#333" />
                    </div>
                </div> :
                    <div className="py-1 text-center">
                        <img src={AddDealer} alt="email Image" className="mx-auto" />
                        <p className="text-3xl mb-0 mt-4 font-semibold">
                            Are you
                            <span className=""> sure ? </span>
                        </p>
                        <p className="text-xl font-medium mt-2">
                            You want to complete this Claim ?
                        </p>
                        <div className="mt-3">
                            <Button type="submit" className='!px-8' onClick={() => { handleSelectChange("claimStatus", { type: 'completed' }); }}>Yes</Button>
                            <InActiveButton className="ml-8 border !px-8 !border-[#333]" onClick={closeComplete}>
                                No
                            </InActiveButton>
                        </div>
                    </div>
                }

            </Modal>

            <Modal isOpen={isShipped} onClose={closeShipped}>
                {completeLoader ? <div className=" h-[350px] w-full flex py-5">
                    <div className="self-center mx-auto">
                        <RotateLoader color="#333" />
                    </div>
                </div> :
                    <div className="py-1 text-center">
                        <img src={AddDealer} alt="email Image" className="mx-auto" />
                        <p className="text-3xl mb-0 mt-4 font-semibold">
                            Are you
                            <span className=""> sure ? </span>
                        </p>
                        <p className="text-xl font-medium mt-2">
                            You want to change repair status ({Array.isArray(repairValue?.value)
                                ? repairValue?.value.find(item => item?.value === "servicer_shipped")?.label || "Unknown"
                                : "Unknown123"}) for this Claim ?

                        </p>
                        <div className="mt-3">
                            <Button type="submit" className='!px-8' onClick={() => { handleSelectChange("repairStatus", { type: 'servicer_shipped' }); }}>Yes</Button>
                            <InActiveButton className="ml-8  border !px-8 !border-[#333]" onClick={closeShipped}>
                                No
                            </InActiveButton>
                        </div>
                    </div>
                }

            </Modal>

            <Modal isOpen={isReceived} onClose={closeReceived}>
                {completeLoader ? <div className=" h-[350px] w-full flex py-5">
                    <div className="self-center mx-auto">
                        <RotateLoader color="#333" />
                    </div>
                </div> :
                    <div className="py-1 text-center">
                        <img src={AddDealer} alt="email Image" className="mx-auto" />
                        <p className="text-3xl mb-0 mt-4 font-semibold">
                            Are you
                            <span className=""> sure ? </span>
                        </p>
                        <p className="text-xl font-medium mt-2">
                            You want to change customer status (Product Received) for this Claim ?
                        </p>
                        <div className="mt-3">
                            <Button type="submit" className='!px-8' onClick={() => { handleSelectChange("customerStatus", { type: 'product_received' }); }}>Yes</Button>
                            <InActiveButton className="ml-8 border !px-8 !border-[#333]" onClick={closeReceived}>
                                No
                            </InActiveButton>
                        </div>
                    </div>
                }

            </Modal>
            <Modal isOpen={isRejectOpen} onClose={closeReject}>
                <Button
                    onClick={closeReject}
                    className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
                >
                    <img
                        src={Cross}
                        className="w-full h-full text-black rounded-full p-0"
                    />
                </Button>
                <div className="text-center py-3">
                    <img src={disapproved} alt="email Image" className="mx-auto" />

                    {!showForm ? (
                        <Grid>
                            <div className="col-span-12">
                                <p className="text-3xl mb-0 mt-4 font-semibold">
                                    {" "}
                                    <span className=""> Reject </span>
                                </p>
                                <p className="text-base font-medium mt-2 ">
                                    Do you really want to Reject the Claim ?
                                </p>
                            </div>
                            <div className="col-span-3"></div>
                            <div className="col-span-3">
                                <Button onClick={handleYesClick} className="w-full">
                                    Yes
                                </Button>
                            </div>
                            <div className="col-span-3">
                                <InActiveButton
                                    type="button"
                                    className="w-full !border-[1px]"
                                    onClick={closeReject}
                                >
                                    No
                                </InActiveButton>
                            </div>
                            <div className="col-span-3"></div>
                        </Grid>
                    ) : (
                        <div className="col-span-12">
                            <div className="relative my-4">
                                <label
                                    htmlFor="reason"
                                    className="absolute text-base text-[#5D6E66] leading-6 duration-300 transform origin-[0] top-1 bg-white left-2 px-1 -translate-y-4 scale-75"
                                >
                                    Enter Your Reason <span className="text-red-500">*</span>
                                </label>
                                <Formik
                                    initialValues={{ reason: "" }}
                                    validate={(values) => {
                                        const errors = {};
                                        if (!values.reason) {
                                            errors.reason = "Required";
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        handleSelectChange("claimStatus", values);
                                        // Submit logic here
                                        // console.log(values);
                                        setSubmitting(false);
                                        getClaimDetails();
                                    }}
                                >
                                    {({ isSubmitting, errors, touched }) => (
                                        <Form>
                                            <Field
                                                as="textarea"
                                                id="reason"
                                                rows="4"
                                                name="reason"
                                                maxLength={150}
                                                className={`block px-2.5 pb-2.5 pt-1.5 w-full text-sm font-semibold text-light-black bg-transparent rounded-lg border-[1px] border-gray-300 appearance-none peer resize-none focus:text-sm ${errors.reason && touched.reason && "border-red-500"
                                                    }`}
                                            />
                                            <ErrorMessage
                                                name="reason"
                                                component="div"
                                                className="text-red-500"
                                            />
                                            <div className="mt-4">
                                                <Button type="submit" disabled={isSubmitting}>
                                                    Submit
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
            <Modal isOpen={errorForCoverageType} onClose={closeCoveragType}>
                <Button
                    onClick={closeCoveragType}
                    className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
                >
                    <img
                        src={Cross}
                        className="w-full h-full text-black rounded-full p-0"
                    />
                </Button>
                <div className="text-center py-3">
                    <img src={disapproved} alt="email Image" className="mx-auto" />
                    <Grid>
                        <div className="col-span-12">
                            <p className="text-3xl mb-0 mt-4 font-semibold">
                                {" "}
                                <span className=""> {coverageTypeError.tittle} </span>
                            </p>
                            <p className="text-base font-medium mt-2 ">
                                {coverageTypeError.message}
                            </p>
                        </div>
                        <div className="col-span-3"></div>
                        <div className="col-span-3">
                            <Button onClick={() => {
                                handleSelectChange("claimStatus", {
                                    value: "rejected",
                                    reason: coverageTypeError.message1,
                                });
                            }}
                                className="w-full">
                                Yes
                            </Button>
                        </div>
                        <div className="col-span-3">
                            <InActiveButton
                                type="button"
                                className="w-full !border-light-black !border-[1px]"
                                onClick={closeCoveragType}
                            >
                                No
                            </InActiveButton>
                        </div>
                        <div className="col-span-3"></div>
                    </Grid>
                </div>
            </Modal>
        </>
    )
}

export default ClaimDetails
