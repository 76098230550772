import React, { useEffect, useState } from "react";
import Headbar from "../../common/headBar";
import BackImage from "../../assets/images/icons/backArrow.svg";
import date from "../../assets/images/icons/date.svg";
import Loader from "../../assets/images/Loader.gif";
import unRead from "../../assets/images/Msg-Black.svg";
import Read from "../../assets/images/Msg-white.png";
import unReadDot from "../../assets/images/UnReadDot.svg";
import ReadDot from "../../assets/images/ReadDot.svg";
import time from "../../assets/images/icons/time.svg";
import { Link, useNavigate } from "react-router-dom";

import {
  getNotificationMarks,
  getNotifications,
  updateNotifications,
} from "../../services/extraServices";
import { RotateLoader } from "react-spinners";
import Grid from "../../common/grid";
import Button from "../../common/button";
import InActiveButton from "../../common/inActiveButton";
import SingleView from "../../common/singleView";

function Notification() {
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const navigate = useNavigate();

  useEffect(() => {
    fetchNotifications();
  }, [activeTab]);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await getNotifications({
        readFlag: activeTab === "unread" ? "false" : "",
      });
      setNotificationList(response.result);
      console.log(response.result);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateNotification = async (data) => {
    const urlsToReplace = [
      "https://demo.codewarranty.com",
      "http://54.176.118.28",
      "https://demo.getcover.com",
      "https://app.getcover.com",
    ];
    
    const path = urlsToReplace.reduce(
      (updatedPath, url) => updatedPath.replace(url, ""),
      data.endPoint
    );
    
    console.log(path);
    try {
      const res = await updateNotifications(data._id);
      if (res) {
        switch (data.type) {
          case "Super Admin":
            navigate("/manageAccount");
            break;
          // case "reseller":
          //   navigate("/resellerList");
          //   break;
          default:
            // return false
            if (data.tabAction != "") {
              if (data.tabAction == 'dealerUser')
                localStorage.setItem('menu', 'Users')
              if (data.tabAction == 'servicerUser') {
                localStorage.setItem('servicer', 'Users')
              }
              if (data.tabAction == 'resellerUser') {
                localStorage.setItem('Resellermenu', 'Users')
              }
              if (data.tabAction == 'customerUser') {
                localStorage.setItem('customer', 'Users')
              }
            }
            break;
        }
        navigate(path);

      }
    } catch (error) {
      console.error("Error updating notification:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    setLoading(true);
    try {
      const response = await getNotificationMarks();
      console.log(response.result?.notification);
      await fetchNotifications();
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleClick = () => {
    navigate("/destination?myProp=Hello, World!");
  };
  return (
    <div className="relative overflow-x-hidden min-h-screen">
      <Headbar />
      <div className="mt-[8%] p-3 rounded-[20px] pr-2 mx-auto">
        <div className="flex justify-between mb-3">
          <div>
            <p className="font-semibold text-[25px] leading-9 mb-[3px]">
              Notifications
            </p>
            <div className="flex">
              {activeTab === "all" ?
                <>
                  <Button
                    className={`tab-button mr-3 font-semibold py-1 px-4 text-sm rounded`}
                    onClick={() => handleTabClick("all")}
                  >
                    All
                  </Button>
                  <InActiveButton
                    className={`tab-button font-semibold py-1 px-4 text-sm rounded`}
                    onClick={() => handleTabClick("unread")}
                  >
                    Unread
                  </InActiveButton>
                </>
                :
                <>
                  <InActiveButton
                    className={`tab-button mr-3  font-semibold py-1 px-4 text-sm rounded
                      `}
                    onClick={() => handleTabClick("all")}
                  >
                    All
                  </InActiveButton>
                  <Button
                    className={`tab-button font-semibold py-1 px-4 text-sm rounded`}
                    onClick={() => handleTabClick("unread")}
                  >
                    Unread
                  </Button>
                </>
              }

            </div>
          </div>
          <Button
            className=" self-center"
            onClick={handleMarkAllAsRead}
          >
            Mark all as read
          </Button>
        </div>
        {loading ? (
          <div className="h-[500px] bg-[#fff] left-0 w-full flex py-5">
            <div className="self-center mx-auto">
              <RotateLoader color="#333" />
            </div>
          </div>
        ) : (
          <>
            <div className="tab-content overflow-y-scroll min-h-[70vh] h-[70vh] bg-white">
              {activeTab === "all" && (
                <div>
                  {notificationList?.length !== 0 ? (
                    notificationList?.map((data, key) => (
                      <div
                        key={key}
                        className=""
                        onClick={() =>
                          handleUpdateNotification(data)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {data?.isRead !== true ? (
                          <SingleView className="border-[1px] p-2 border-[#D1D1D2] relative">
                            <Grid>
                              <div className="col-span-3 self-center flex w-full">
                                <div
                                  style={{
                                    maskImage: `url(${unReadDot})`,
                                    WebkitMaskImage: `url(${unReadDot})`,
                                    maskRepeat: "no-repeat",
                                    WebkitMaskRepeat: "no-repeat",
                                    maskPosition: "center",
                                    WebkitMaskPosition: "center",
                                    maskSize: "contain",
                                    WebkitMaskSize: "contain",
                                  }}
                                  className="self-center singleViews mr-2 w-[10px] h-[10px] my-auto"
                                />
                                {/* <img
                                  src={unReadDot}
                                  className="mr-2 w-[10px] h-[10px] my-auto self-center"
                                  alt="Unread dot"
                                /> */}
                                <div
                                  style={{
                                    maskImage: `url(${unRead})`,
                                    WebkitMaskImage: `url(${unRead})`,
                                    maskRepeat: "no-repeat",
                                    WebkitMaskRepeat: "no-repeat",
                                    maskPosition: "center",
                                    WebkitMaskPosition: "center",
                                    maskSize: "contain",
                                    WebkitMaskSize: "contain",
                                  }}
                                  className="mr-2 w-[18px] singleViews h-[24px] self-center"
                                />
                                {/* <img
                                  src={unRead}
                                  className="mr-2 w-[18px] h-[24px] self-center"
                                  alt="Unread icon"
                                /> */}
                                <p className=" text-[15px] font-semibold">
                                  {data?.title}
                                </p>
                              </div>
                              <div className="col-span-6 self-center flex w-full">

                                <p className="text-sm font-Regular self-center pl-4 pt-1">
                                  {data?.description}
                                </p>
                              </div>
                              <div className="col-span-3">
                                <div className="flex justify-end h-full">
                                  <p className="flex text-sm mr-3 w-1/2  self-center">
                                    <div
                                      style={{
                                        maskImage: `url(${time})`,
                                        WebkitMaskImage: `url(${time})`,
                                        maskRepeat: "no-repeat",
                                        WebkitMaskRepeat: "no-repeat",
                                        maskPosition: "center",
                                        WebkitMaskPosition: "center",
                                        maskSize: "contain",
                                        WebkitMaskSize: "contain",
                                      }}
                                      className="self-center singleViews mr-2 h-4 w-4 cursor-pointer"
                                    />
                                    {/* <img src={time} className="mr-2" alt="Time" />{" "} */}
                                    {new Date(
                                      data.createdAt
                                    ).toLocaleTimeString()}{" "}
                                  </p>
                                  <p className="mr-3 flex text-sm w-1/2  self-center">
                                    <div
                                      style={{
                                        maskImage: `url(${date})`,
                                        WebkitMaskImage: `url(${date})`,
                                        maskRepeat: "no-repeat",
                                        WebkitMaskRepeat: "no-repeat",
                                        maskPosition: "center",
                                        WebkitMaskPosition: "center",
                                        maskSize: "contain",
                                        WebkitMaskSize: "contain",
                                      }}
                                      className="self-center singleViews mr-2 h-4 w-4 cursor-pointer "
                                    />
                                    {/* <img src={date} className="mr-2" alt="Date" />{" "} */}
                                    {new Date(
                                      data?.createdAt
                                    ).toLocaleDateString()}{" "}
                                  </p>
                                </div>
                              </div>
                            </Grid>
                          </SingleView>
                        ) : (
                          <Grid className="border-[1px] p-2 border-[#D1D1D2] relative text-black">
                            <div className="col-span-3 self-center flex w-full">
                              {/* <div
                                style={{
                                  maskImage: `url(${ReadDot})`,
                                  WebkitMaskImage: `url(${ReadDot})`,
                                  maskRepeat: "no-repeat",
                                  WebkitMaskRepeat: "no-repeat",
                                  maskPosition: "center",
                                  WebkitMaskPosition: "center",
                                  maskSize: "contain",
                                  WebkitMaskSize: "contain",
                                }}
                                className="mr-2 w-[10px] singleViews h-[10px] my-auto self-center"
                              /> */}
                              <img
                                src={ReadDot}
                                className="mr-2 w-[10px] h-[10px] my-auto self-center"
                                alt="Read dot"
                              />
                              {/* <div
                                style={{
                                  maskImage: `url(${Read})`,
                                  WebkitMaskImage: `url(${Read})`,
                                  maskRepeat: "no-repeat",
                                  WebkitMaskRepeat: "no-repeat",
                                  maskPosition: "center",
                                  WebkitMaskPosition: "center",
                                  maskSize: "contain",
                                  WebkitMaskSize: "contain",
                                }}
                                className="mr-2 w-[18px] singleViews h-[18px] mt-[3px] self-center"
                              /> */}
                              <img
                                src={Read}
                                className="mr-2 w-[18px] h-[18px] mt-[3px] self-center"
                                alt="Read icon"
                              />
                              <p className=" text-base font-semibold ">
                                {data?.title}
                              </p>
                            </div>
                            <div className="col-span-6 self-center flex w-full">

                              <p className="text-sm font-Regular self-center pl-4 pt-1">
                                {data?.description}
                              </p>
                            </div>
                            <div className="col-span-3">
                              <div className="flex justify-end h-full">
                                <p className="flex text-sm mr-3 w-1/2  self-center">
                                  {/* <div
                                    style={{
                                      maskImage: `url(${time})`,
                                      WebkitMaskImage: `url(${time})`,
                                      maskRepeat: "no-repeat",
                                      WebkitMaskRepeat: "no-repeat",
                                      maskPosition: "center",
                                      WebkitMaskPosition: "center",
                                      maskSize: "contain",
                                      WebkitMaskSize: "contain",
                                    }}
                                    className="self-center singleViews mr-2 h-4 w-4 cursor-pointer"
                                  /> */}
                                  <img src={time} className="mr-2" alt="Time" />{" "}
                                  {new Date(
                                    data.createdAt
                                  ).toLocaleTimeString()}{" "}
                                </p>
                                <p className="mr-3 flex text-sm w-1/2 text-light-black self-center">
                                  {/* <div
                                    style={{
                                      maskImage: `url(${date})`,
                                      WebkitMaskImage: `url(${date})`,
                                      maskRepeat: "no-repeat",
                                      WebkitMaskRepeat: "no-repeat",
                                      maskPosition: "center",
                                      WebkitMaskPosition: "center",
                                      maskSize: "contain",
                                      WebkitMaskSize: "contain",
                                    }}
                                    className="self-center singleViews mr-2 h-4 w-4 cursor-pointer"
                                  /> */}
                                  <img src={date} className="mr-2" alt="Date" />{" "}
                                  {new Date(
                                    data?.createdAt
                                  ).toLocaleDateString()}{" "}
                                </p>
                              </div>
                            </div>
                          </Grid>
                        )}
                      </div>
                    ))
                  ) : (
                    <p className="py-8 text-xl text-center font-semibold">
                      No Notification Yet
                    </p>
                  )}
                </div>
              )}
              {activeTab === "unread" && (
                <div>
                  {notificationList?.length !== 0 ? (
                    notificationList?.map((data, key) => (
                      <div
                        key={key}
                        className=""
                        onClick={() =>
                          handleUpdateNotification(data?._id, data.flag)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <SingleView>
                          <Grid className="border-[1px] p-2 border-[#D1D1D2] relative">
                            <div className="col-span-3 self-center flex w-full">
                              <div
                                style={{
                                  maskImage: `url(${unReadDot})`,
                                  WebkitMaskImage: `url(${unReadDot})`,
                                  maskRepeat: "no-repeat",
                                  WebkitMaskRepeat: "no-repeat",
                                  maskPosition: "center",
                                  WebkitMaskPosition: "center",
                                  maskSize: "contain",
                                  WebkitMaskSize: "contain",
                                }}
                                className="mr-2 w-[10px] singleViews h-[10px] my-auto self-center"
                              />
                              {/* <img
                              src={unReadDot}
                              className="mr-2 w-[10px] h-[10px] my-auto self-center"
                              alt="Unread dot"
                            /> */}
                              <div
                                style={{
                                  maskImage: `url(${unRead})`,
                                  WebkitMaskImage: `url(${unRead})`,
                                  maskRepeat: "no-repeat",
                                  WebkitMaskRepeat: "no-repeat",
                                  maskPosition: "center",
                                  WebkitMaskPosition: "center",
                                  maskSize: "contain",
                                  WebkitMaskSize: "contain",
                                }}
                                className="mr-2 w-[18px] singleViews h-[24px] self-center"
                              />
                              {/* <img
                              src={unRead}
                              className="mr-2 w-[18px] h-[24px] self-center"
                              alt="Unread icon"
                            /> */}
                              <p className=" text-base font-semibold">
                                {data?.title}
                              </p>
                            </div>
                            <div className="col-span-6 self-center flex w-full">
                              <p className="text-sm  font-Regular self-center pl-4 pt-1">
                                {data?.description}
                              </p>
                            </div>
                            <div className="col-span-3">
                              <div className="flex justify-end h-full">
                                <p className="flex text-sm mr-3 w-1/2 self-center">
                                  <div
                                    style={{
                                      maskImage: `url(${time})`,
                                      WebkitMaskImage: `url(${time})`,
                                      maskRepeat: "no-repeat",
                                      WebkitMaskRepeat: "no-repeat",
                                      maskPosition: "center",
                                      WebkitMaskPosition: "center",
                                      maskSize: "contain",
                                      WebkitMaskSize: "contain",
                                    }}
                                    className="self-center singleViews mr-2 h-4 w-4 cursor-pointer"
                                  />
                                  {/* <img src={time} className="mr-2" alt="Time" />{" "} */}
                                  {new Date(data.createdAt).toLocaleTimeString()}{" "}
                                </p>
                                <p className="mr-3 flex text-sm w-1/2  self-center">
                                  <div
                                    style={{
                                      maskImage: `url(${date})`,
                                      WebkitMaskImage: `url(${date})`,
                                      maskRepeat: "no-repeat",
                                      WebkitMaskRepeat: "no-repeat",
                                      maskPosition: "center",
                                      WebkitMaskPosition: "center",
                                      maskSize: "contain",
                                      WebkitMaskSize: "contain",
                                    }}
                                    className="self-center singleViews mr-2 h-4 w-4 cursor-pointer"
                                  />
                                  {/* <img src={date} className="mr-2" alt="Date" />{" "} */}
                                  {new Date(data?.createdAt).toLocaleDateString()}{" "}
                                </p>
                              </div>
                            </div>
                          </Grid>
                        </SingleView>
                      </div>
                    ))
                  ) : (
                    <p className="py-8 text-xl text-center font-semibold">
                      No Notification Yet
                    </p>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Notification;
