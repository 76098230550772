import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import ActiveIcon from "../../../assets/images/icons/iconAction.svg";
import Headbar from "../../../common/headBar";
import shorting from "../../../assets/images/icons/shorting.svg";
import Grid from "../../../common/grid";
import DataTable from "react-data-table-component";
import remove from "../../../assets/images/delete.png";
// import view from "../../../assets/images/eye.png";
import Search from "../../../assets/images/icons/SerachWhite.svg";
import unassign from "../../../assets/images/Unassign.png";
import download from "../../../assets/images/download.png";
import view from "../../../assets/images/whiteView.png";

import clearFilter from "../../../assets/images/icons/Clear-Filter-Icon-White.svg";
import Primary from "../../../assets/images/SetPrimary.png";
import { RotateLoader } from "react-spinners";
import Card from "../../../common/card";
import SingleView from "../../../common/singleView";
import { deleteDownloadReport, DownloadReport, getdeleteReports, getEmails } from "../../../services/claimServices";
import Modal from "../../../common/model";
import InActiveButton from "../../../common/inActiveButton";
import Button from "../../../common/button";
import { downloadFile, dowreportingTimeUpdate } from "../../../services/userServices";
import { saveAs } from "file-saver";
import Select from "../../../common/select";
import Cross from "../../../assets/images/Cross.png";
import Input from "../../../common/input";
import { useFormik } from "formik";
import * as Yup from "yup";
import SelectSearch from "../../../common/selectSearch";
import CustomPagination from "../../pagination";
const url = process.env.REACT_APP_API_KEY_LOCAL;

function MailLogs() {
    const [selectedAction, setSelectedAction] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [deleteReport, setDeleteReport] = useState();
    const [timer, setTimer] = useState(3);
    const [reportId, setReportId] = useState();
    const [report, setReport] = useState();
    const [emailTeplate, setEmailTeplate] = useState([]);
    const [values, setValues] = useState([]);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [primaryMessage, setPrimaryMessage] = useState("");
    const [secondaryMessage, setSecondaryMessage] = useState("");
    const [isArchiveOpen, setIsArchiveOpen] = useState(false);
    const [isDisapprovedOpen, setIsDisapprovedOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [value, setValue] = useState(null);
    const [value1, setValue1] = useState(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setSelectedAction(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const CustomNoDataComponent = () => (
        <Card className="text-center my-5">
            <p>No records found.</p>
        </Card>
    );

    const paginationOptions = {
        rowsPerPageText: "Rows per page:",
        rangeSeparatorText: "of",
    };

    // Handle page change

    const formatDateToYYYYDDMM = (date) => {
        const newDate = new Date(date);
        const year = newDate.getUTCFullYear();
        const day = String(newDate.getUTCDate()).padStart(2, '0');
        const month = String(newDate.getUTCMonth() + 1).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };
    const today = new Date();
    const startDate = formatDateToYYYYDDMM(new Date(today.setMonth(today.getMonth() - 3, today.getDate() + 1)));
    const endDate = formatDateToYYYYDDMM(new Date());
    const [pageValue, setPageValue] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const isFormSubmittedRef = useRef(false);
    const [initialValues, setInitialValues] = useState({
        role: "",
        email: "",
        status: "",
        startDate: startDate,
        endDate: endDate,
        accountName: "",
    });

    const handlePageChange = async (page, rowsPerPage) => {

        setRecordsPerPage(rowsPerPage);

        // setLoading(true);
        try {
            // setPage(newPage); // Update page state
            getReportListData(page, rowsPerPage, formik.values);

            // setLoading(false);


        } finally {
            // setLoading(false);

        }
    };

    useEffect(() => {
        getReportListData();
        window.scrollTo(0, 0);
    }, []);
    const getReportListData = async (page = 1, rowsPerPage) => {
        setLoading(true);
        try {
            setPageValue(page);
            let requestData = {
                page: page,
                pageLimit: rowsPerPage == undefined ? recordsPerPage : rowsPerPage,
                ...(isFormSubmittedRef.current ? formik.values : initialValues),
            };


            console.log("📤 API Request:", requestData); // Debugging: Log request payload

            const res = await getEmails(requestData);

            // Assuming res.result is the list of data and res.totalCount is the total number of rows
            if (res && res.result) {
                console.log("📥 API Response:", res); // Debugging: Log API response

                setDeleteReport(res.result); // Update the report data
                setTotalRecords(res.totalCount || 0); // Ensure total count is updated
            } else {
                console.error("❌ Unexpected API Response:", res);
            }
        } catch (error) {
            console.error("❌ API Error:", error);
            alert("Something went wrong while fetching the data.");
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        let intervalId;
        if (isModalOpen1 && timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }
        if (timer === 0) {
            closeArchive();
            getReportListData();
            closeModal1();
        }
        return () => clearInterval(intervalId);
    }, [isModalOpen1, timer]);

    const closeModal1 = () => {
        setIsModalOpen1(false);
    };

    const openArchive = (data) => {
        setEmailTeplate(data.content)
        setValues(data?.keyValues)
        setIsArchiveOpen(true);
    };
    const closeArchive = () => {
        setIsArchiveOpen(false);
    };

    const filterBy = [
        {
            value: "Super Admin",
            label: "Super Admin",
        },
        {
            value: "Dealer",
            label: "Dealer",
        },
        {
            value: "Reseller",
            label: "Reseller",
        },
        {
            value: "Customer",
            label: "Customer",
        },
        {
            value: "Servicer",
            label: "Servicer",
        },
    ];

    const createMarkup = (htmlString) => {
        return { __html: htmlString };
    };

    function populateTemplate(template, data) {

        if (typeof template !== "string") {
            console.error("Template must be a string. Received:", typeof template, template);
            return "";
        }

        // Remove {{#if ...}} ... {{/if}} blocks
        template = template.replace(/{{#if\s+[\w.]+}}[\s\S]*?{{\/if}}/g, "");

        // Replace {{key}} placeholders with data values
        return template.replace(/{{\s*([\w.]+)\s*}}/g, (match, key) => {
            const keys = key.split('.');
            let value = data;
            for (const k of keys) {
                value = value[k];
                if (value === undefined) return match; // Leave placeholder if value is missing
            }
            return value;
        });
    }

    // Populate the template with dynamic data
    const populatedHTML = populateTemplate(emailTeplate, values);

    const validationSchema = Yup.object().shape({
        startDate: Yup.string().required("Start date is required"),
        endDate: Yup.string()
            .required("End date is required")
            .test("is-after-start", "End date must be after start date", function (value) {
                const { startDate } = this.parent;
                return !startDate || new Date(value) >= new Date(startDate);
            }),
    });


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            isFormSubmittedRef.current = true;
            getReportListData();
            setIsDisapprovedOpen(false);
        },
    });

    const Status = [
        { label: "Sent", value: 'Sent' },
        { label: "Delivered", value: 'Delivered' },
        { label: "Open", value: 'Open' },
        { label: "Failed", value: 'Failed' },
        { label: "Bounced", value: 'Bounced' },
    ];
    const handleSelectChange2 = (label, value) => {
        formik.setFieldValue("status", value);
        setValue(value);
    };
    const handleSelectChange = (label, value) => {
        formik.setFieldValue("role", value);
        setValue1(value);
    };

    const openDisapproved = () => {
        setIsDisapprovedOpen(true);
    };

    const closeDisapproved = () => {
        setIsDisapprovedOpen(false);
    };

    const handleFilterIconClick = () => {
        formik.resetForm();
        setValue("");
        setValue1("");
        getReportListData();
        isFormSubmittedRef.current = false;
    };



    return (
        <>
            <div className="mb-8 ml-3">
                <Headbar />
                <div className="flex mt-2">
                    <div className="pl-3">
                        <p className="font-bold text-[36px] leading-9	mb-[3px]">
                            Mail Logs
                        </p>
                        <ul className="flex self-center">
                            <li className="text-sm font-Regular">
                                <Link to={"/"}>Home </Link> /{" "}
                            </li>
                            <li className="text-sm font-semibold ml-1 pt-[1px]">
                                {" "}
                                Mails List{" "}
                            </li>
                        </ul>
                    </div>
                </div>

                <Card className="mt-5  border-[1px] border-Light-Grey rounded-xl">
                    <Grid className="!px-[26px] !pb-0">
                        <div className="col-span-3 self-center">
                            <p className="text-xl font-semibold py-4">Mail Logs</p>
                        </div>
                        <div className="col-span-9">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="bg-grayf9 rounded-[30px] p-3 border-[1px] border-Light-Grey">
                                    <Grid className={`!grid-cols-9`}>

                                        <div className="col-span-2 self-center">

                                            <SelectSearch
                                                label=""
                                                options={filterBy}
                                                color="text-Black-Russian opacity-50"
                                                value={value1}
                                                OptionName="All User Roles"
                                                className1="!pt-1 !pb-1 !text-[13px] !bg-[white]"
                                                className="!text-[14px] !bg-white"
                                                selectedValue={value1}
                                                onChange={handleSelectChange}
                                            />
                                        </div>
                                        <div className="col-span-2 self-center">
                                            <Input
                                                name="email"
                                                type="text"
                                                className="!text-[14px] !bg-White-Smoke"
                                                className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                                                label=""
                                                placeholder="Email"
                                                {...formik.getFieldProps("email")}
                                            />
                                        </div>
                                        <div className="col-span-2 self-center">
                                            <SelectSearch
                                                label=""
                                                options={Status}
                                                color="text-Black-Russian opacity-50"
                                                value={value}
                                                OptionName="All Status"
                                                className1="!pt-1 !pb-1 !text-[13px] !bg-[white]"
                                                className="!text-[14px] !bg-white"
                                                selectedValue={value}
                                                onChange={handleSelectChange2}
                                            />
                                        </div >

                                        <div className="col-span-1 self-center flex justify-center">
                                            <Button
                                                type="submit"
                                                // disabled={disable}
                                                className={` !p-2`}
                                            >
                                                <img
                                                    src={Search}
                                                    className="cursor-pointer "
                                                    alt="Search"
                                                />
                                            </Button>
                                            <InActiveButton
                                                type="submit"
                                                // disabled={disable}
                                                onClick={() => {
                                                    handleFilterIconClick();
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        maskImage: `url(${clearFilter})`,
                                                        WebkitMaskImage: `url(${clearFilter})`,
                                                        maskRepeat: "no-repeat",
                                                        WebkitMaskRepeat: "no-repeat",
                                                        maskPosition: "center",
                                                        WebkitMaskPosition: "center",
                                                        maskSize: "contain",
                                                        WebkitMaskSize: "contain",
                                                    }}
                                                    className="self-center pr-1 py-1 h-4 w-4 cursor-pointer mx-auto"
                                                />
                                            </InActiveButton>
                                        </div>
                                        <div
                                            className={`text-center
                                                 col-span-2 self-center`}
                                        >
                                            <Button
                                                className={`!text-[13px]`}
                                                // disabled={disable}
                                                onClick={() => openDisapproved()}
                                            >
                                                Advance Search
                                            </Button>
                                        </div>
                                    </Grid >
                                </div >
                            </form >
                        </div >
                    </Grid >

                    <div className="col-span-12 relative ">
                        {loading ? (
                            <div className="h-[400px] w-full flex py-5">
                                <div className="self-center mx-auto">
                                    <RotateLoader color="#333" />
                                </div>
                            </div>
                        ) : (
                            <>
                                <table className="w-full border text-left table-auto mt-4">
                                    <thead className="staticTable">
                                        <tr className=" border-b-[1px]">
                                            <th className="font-semibold  w-[10%] text-center">Serial #</th>
                                            <th className="font-semibold  w-[10%]">
                                                User Role
                                            </th>
                                            <th className="font-semibold  w-[10%]">Account <br /> Name</th>
                                            <th className="font-semibold  w-[20%]">Email</th>
                                            <th className="font-semibold  w-[20%]">Subject</th>
                                            <th className="font-semibold  w-[10%]">Sent On</th>
                                            <th className="font-semibold  w-[10%]">Status</th>
                                            <th className="font-semibold  w-[10%]">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {deleteReport?.length !== 0 ? (
                                            deleteReport?.map((res, index) => (
                                                <tr
                                                    key={index}
                                                    className="text-[13px] font-[400] border-b-[1px]"
                                                >
                                                    <td className="py-3 text-center px-2">
                                                        {(pageValue - 1) * recordsPerPage + index + 1}
                                                    </td>
                                                    <td className="py-3 px-2">{res?.role}</td>
                                                    <td className="py-3 px-2">{res?.accountName}</td>
                                                    <td className="py-3 px-2">{res?.email}</td>
                                                    <td className="py-3 px-2">{res?.keyValues?.subject}</td>
                                                    <td className="py-3 px-2">
                                                        {(() => {
                                                            const date = new Date(res?.sentOn);
                                                            const formattedDate = date.toLocaleDateString("en-US", {
                                                                month: "long",
                                                                day: "numeric",
                                                                year: "numeric",
                                                            });
                                                            const formattedTime = date.toLocaleTimeString("en-US", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            });
                                                            return `${formattedDate} ${formattedTime}`;
                                                        })()}
                                                    </td>
                                                    <td className="py-3 px-2"> {res?.event === "dropped" ? "failed" : res?.event}</td>
                                                    <td className="py-3 mx-auto px-2">
                                                        <div className="relative">

                                                            <div
                                                                onClick={() =>
                                                                    setSelectedAction(selectedAction === index ? null : index)
                                                                }
                                                            >
                                                                <img
                                                                    src={ActiveIcon}
                                                                    className="cursor-pointer w-[35px]"
                                                                    alt="Active Icon"
                                                                />
                                                            </div>
                                                            {selectedAction === index && (
                                                                <>
                                                                    <SingleView
                                                                        ref={dropdownRef}
                                                                        className={`absolute z-[2] w-[80px] drop-shadow-5xl -bottom-1 left-1 mt-2 py-1 border rounded-lg shadow-md`}
                                                                    >

                                                                        <div
                                                                            className="text-left py-1 px-2 flex cursor-pointer"
                                                                            onClick={() => openArchive(res)}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    maskImage: `url(${view})`,
                                                                                    WebkitMaskImage: `url(${view})`,
                                                                                    maskRepeat: "no-repeat",
                                                                                    WebkitMaskRepeat: "no-repeat",
                                                                                    maskPosition: "center",
                                                                                    WebkitMaskPosition: "center",
                                                                                    maskSize: "contain",
                                                                                    WebkitMaskSize: "contain",
                                                                                }}
                                                                                className="self-center singleViews mr-2 h-4 w-4 "
                                                                            /> View
                                                                        </div>
                                                                    </SingleView>
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colspan="8" className="py-3 text-center">
                                                    <p>No Record Found</p>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </div>
                    <div className="col-span-12">
                        {deleteReport?.length !== 0 ? (
                            <>
                                <div className="mt-2">
                                    <CustomPagination
                                        totalRecords={totalRecords}
                                        page={pageValue}
                                        className={loading ? "opacity-0	" : "opacity-100"}
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        onPageChange={handlePageChange}
                                        setRecordsPerPage={setRecordsPerPage}
                                    />
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>

                </Card >
            </div >

            <Modal isOpen={isModalOpen1} onClose={closeModal1}>
                <div className="text-center py-3">
                    <img src={Primary} alt="email Image" className="mx-auto my-4" />
                    <p className="text-3xl mb-0 mt-2 font-[800]">
                        {primaryMessage}
                    </p>
                    <p className="text-base font-medium mt-2">
                        {secondaryMessage}
                    </p>
                    <p className="text-base font-medium mt-2">
                        Redirecting you on Report List Page {timer} seconds.
                    </p>
                </div>
            </Modal>


            <Modal isOpen={isArchiveOpen} onClose={closeArchive}>
                <Button
                    onClick={closeArchive}
                    className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
                >
                    <img
                        src={Cross}
                        className="w-full h-full text-black rounded-full p-0"
                    />
                </Button>
                <div className="max-h-[70vh] overflow-y-scroll">

                    <div dangerouslySetInnerHTML={createMarkup(populatedHTML)} className="custom-content" />
                </div>
            </Modal>

            <Modal isOpen={isDisapprovedOpen} onClose={closeDisapproved}>
                <Button
                    onClick={closeDisapproved}
                    className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
                >
                    <img
                        src={Cross}
                        className="w-full h-full text-black rounded-full p-0"
                    />
                </Button>
                <form onSubmit={formik.handleSubmit}>
                    <div className="py-3">
                        <p className="text-center text-3xl font-semibold ">
                            Advance Search
                        </p>
                        <Grid className="my-5 px-6">
                            <div className="col-span-6">
                                <SelectSearch
                                    label="User Roles"
                                    options={filterBy}
                                    color="text-Black-Russian opacity-50"
                                    value={value1}
                                    className="!bg-white"
                                    OptionName="All "
                                    selectedValue={value1}
                                    onChange={handleSelectChange}
                                />
                                {/* <Input
                                    type="text"
                                    name="role"
                                    className="!bg-white"
                                    label="User Role"
                                    placeholder=""
                                    {...formik.getFieldProps("role")}
                                /> */}
                            </div>
                            <div className="col-span-6">
                                <Input
                                    type="text"
                                    name="email"
                                    className="!bg-white"
                                    label="Email Address"
                                    placeholder=""
                                    {...formik.getFieldProps("email")}
                                />
                            </div>
                            <div className="col-span-6">
                                <Input
                                    type="text"
                                    name="accountName"
                                    className="!bg-white"
                                    label="Account Name"
                                    placeholder=""
                                    {...formik.getFieldProps("accountName")}
                                />
                            </div>

                            <div className="col-span-6">
                                <SelectSearch
                                    label="Status"
                                    options={Status}
                                    className="!bg-white"
                                    color="text-Black-Russian opacity-50"
                                    value={value}
                                    OptionName="All"
                                    selectedValue={value}
                                    onChange={handleSelectChange2}
                                />
                            </div>
                            <div className="col-span-6">
                                <Input
                                    type="date"
                                    name="startDate"
                                    className="!bg-white z-10"
                                    label="Email Sent Start Date"
                                    placeholder=""
                                    minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))
                                        .toISOString()
                                        .split("T")[0]} // Min: 1 year ago
                                    maxDate={new Date().toISOString().split("T")[0]}
                                    {...formik.getFieldProps("startDate")}
                                />
                                {formik.touched.startDate && formik.errors.startDate && (
                                    <p className="text-red-500">{formik.errors.startDate}</p>
                                )}
                            </div>

                            <div className="col-span-6">
                                <Input
                                    type="date"
                                    name="endDate"
                                    className="!bg-white z-10"
                                    label="Email Sent End Date"
                                    placeholder=""
                                    minDate={formik.values.startDate}
                                    maxDate={new Date().toISOString().split("T")[0]}
                                    {...formik.getFieldProps("endDate")}
                                />
                                {formik.touched.endDate && formik.errors.endDate && (
                                    <p className="text-red-500">{formik.errors.endDate}</p>
                                )}
                            </div>
                        </Grid>

                        <div className="px-6">
                            <Button type="submit" className={"w-full"}>
                                Search
                            </Button>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    );
}


export default MailLogs