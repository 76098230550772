export const contractReportDownloadOptions = [
  {
    "index": "12",
    "title": "Contract",
    "apiFieldName": "contractNotification",
    "sections": [
      {
        "label": "Contract Id",
        "action": "unique_key"
      },
      {
        "label": "Dealer P.O.#",
        "action": "venderOrder"
      },
      {
        "label": "Order Id",
        "action": "orderId"
      },

      {
        "label": "Manufacturer",
        "action": "manufacture"
      },
      {
        "label": "Model",
        "action": "model"
      },
      {
        "label": "Serial Number",
        "action": "serial"
      },
      {
        "label": "Condition",
        "action": "condition"
      },
      {
        "label": "Retail Price",
        "action": "retailPrice"
      },
      {
        "label": "Product Category",
        "action": "category"
      },
      {
        "label": "Product SKU",
        "action": "pName"
      },
      {
        "label": "Dealer SKU",
        "action": "dealerSku"
      },

      {
        "label": "Product Name",
        "action": "productName"
      },
      {
        "label": "Product Description",
        "action": "description"
      },
      {
        "label": "Price Type",
        "action": "priceType"
      },
      {
        "label": "Coverage Start Date",
        "action": "coverageStartDate"
      },
      {
        "label": "Coverage End Date",
        "action": "coverageEndDate"
      },
      {
        "label": "Contract Status",
        "action": "status"
      },
      {
        "label": "Eligibilty",
        "action": "eligibilty"
      },
      {
        "label": "Total Claim value",
        "action": "claimAmount"
      },
      {
        "label": "Total Number of claims",
        "action": "totalNoOfClaim"
      },

      {
        "label": "Manufacturer Labour Warranty End Date",
        "action": "labourWarrantyDate"
      },
      {
        "label": "Manufacturer Parts Warranty End Date",
        "action": "partsWarrantyDate"
      },
      {
        "label": "Purchase Date",
        "action": "purchaseDate"
      },
      {
        "label": "No of Claim Over the Certain Period",
        "action": "noOfClaimPerPeriod"
      },
      {
        "label": "Number of Claims in Coverage Period",
        "action": "noOfClaim"
      },
      {
        "label": "Is Manufacturer Warranty?",
        "action": "isManufacturerWarranty"
      },
      {
        "label": "Is Max Claim Amount?",
        "action": "isMaxClaimAmount"
      },


    ]
  },
  {
    "index": "13",
    "title": "User",
    "apiFieldName": "userNotification",
    "sections": [
      {
        "label": "Dealer Name",
        "action": "dealerName"
      },
      {
        "label": "Reseller Name",
        "action": "resellerName"
      },
      {
        "label": "Servicer Name",
        "action": "servicerName"
      },
      {
        "label": "Customer Name",
        "action": "customerName"
      }
    ]
  }
];
