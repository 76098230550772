import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import orderSummary from "../../../assets/images/order/orderSummary.svg";
import contract from "../../../assets/images/order/Contracts.svg";
import Cross from "../../../assets/images/Cross.png";
import DealerList from "../../../assets/images/icons/dealerList.svg";
import DealerPO from "../../../assets/images/contract/DealerPO.svg";
import status from "../../../assets/images/icons/Status.svg";
import contractActive from "../../../assets/images/order/ContractsActive.svg";
import orderActive from "../../../assets/images/order/orderSummaryActive.svg";
import category1 from "../../../assets/images/contract/OrderID.svg";
import Name from "../../../assets/images/order/Name.svg";
import BackImage from "../../../assets/images/icons/backArrow.svg";
import money from "../../../assets/images/icons/usdCircle.svg";
import { RotateLoader } from 'react-spinners';
import Headbar from '../../../common/headBar';
import * as Yup from "yup";
import Grid from '../../../common/grid';
import SingleView from '../../../common/singleView';
import Edit from "../../../assets/images/Dealer/EditIcon.svg";
import { getClaimValues, getContractValues, getServicerList } from '../../../services/extraServices';
import Button from '../../../common/button';
import InActiveButton from '../../../common/inActiveButton';
import ClaimDetails from './claim details/claimDetails';
import Comment from './claim details/comment';
import Modal from '../../../common/model';
import { useFormik } from 'formik';
import SelectBoxWithSearch from '../../../common/selectBoxWIthSerach';
import ContractDetails from './claim details/contractDetails';
import { editClaimServicerValue } from '../../../services/claimServices';
function ServicerClaimSingleView() {
    const [loading1, setLoading1] = useState(false);
    const [listLoading, setListLoading] = useState(false);
    const [claimDetails, setClaimDetails] = useState({});
    const [servicerList, setServicerList] = useState([]);
    const [servicer, setServicer] = useState("");
    const { claimId } = useParams();
    const [activeTab, setActiveTab] = useState("Summary");
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("userDetails"));
    const [isServicerModal, setIsServicerModal] = useState(false);

    const openServicer = (id) => {
        getServicers(id)
        setIsServicerModal(true);
    };
    const closeServicer = () => {
        setIsServicerModal(false);
    };
    const formatOrderValue = (orderValue) => {
        if (Math.abs(orderValue) >= 1e6) {
            return (orderValue / 1e6).toFixed(2) + "M";
        } else {
            return orderValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
    };
    useEffect(() => {
        // setLoading1(true);
        getClaimDetails(claimId);
        localStorage.setItem("claimMenu", activeTab);
        // setLoading1(false);
    }, [activeTab]);



    const getClaimDetails = async (id) => {

        setLoading1(true);

        const result = await getClaimValues(id);
        if (result.code == 200) {
            setClaimDetails(result.result);
            console.log(result);
            if (result.result.servicerData && result.result.servicerData._id) {
                setServicer(result.result.servicerData._id);
            }
        }
        else {
            navigate(`/`);
        }
        setLoading1(false);
    };



    const getServicers = async (id) => {
        setListLoading(true);
        const result = await getServicerList(id);
        let arr = [];
        const filteredServicer = result?.data?.filter(
            (data) => data.servicerData.status === true
        );
        filteredServicer?.map((res) => {
            arr.push({
                label: res.servicerData.name,
                value: res.servicerData._id,
            });
        });

        console.log(arr, '------------------------------');
        setServicerList(arr)
        setListLoading(false);
        console.log(servicer)

    };

    const tabs = [
        {
            id: "Summary",
            label: "Summary",
            icons: orderSummary,
            Activeicons: orderActive,
            content: activeTab === "Summary" && (
                <ClaimDetails
                    data={claimDetails}
                />
            ),
        },
        {
            id: "Comments",
            label: `Comments `,
            icons: contract,
            Activeicons: contractActive,
            content: activeTab === "Comments" && (
                <Comment flag="Servicer" data={claimDetails} />
            ),
        },
        {
            id: "Contract Details",
            label: `Contract Details `,
            icons: contract,
            Activeicons: contractActive,
            content: activeTab === "Contract Details" && (
                <ContractDetails data={claimDetails?.contractDetail?._id} />
            ),
        },
    ];

    const handleGOBack = () => {
        const path = localStorage.getItem('contractPathname')
        console.log(`'${path}'`)
        localStorage.removeItem("contractMenu");
        // window.location.href = path;
        navigate('/servicer/claimList')
    };

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const InactiveTabButton = ({ tab, onClick }) => (
        <InActiveButton
            className="flex self-center mr-2 w-[95%] !px-2 !py-1 rounded-xl border-[1px] border-Light-Grey "
            onClick={onClick}
        >
            <div
                style={{
                    maskImage: `url(${tab.icons})`,
                    WebkitMaskImage: `url(${tab.icons})`,
                    maskRepeat: "no-repeat",
                    WebkitMaskRepeat: "no-repeat",
                    maskPosition: "center",
                    WebkitMaskPosition: "center",
                    maskSize: "contain",
                    WebkitMaskSize: "contain",
                }}
                className="self-center pr-1 py-1 h-4 w-4"
            />
            <span
                style={{
                    borderLeftWidth: "1px",
                    paddingLeft: "7px",
                }}
                className="ml-1 py-1 text-sm font-Regular"
            >
                {tab.label}
            </span>
        </InActiveButton>
    );

    // ActiveTabButton Component
    const ActiveTabButton = ({ tab, onClick }) => (
        <Button
            className="flex self-center mr-2 w-[95%] !px-2 !py-1 rounded-xl border-[1px] border-Light-Grey"
            onClick={onClick}
        >
            <div
                style={{
                    maskImage: `url(${tab.Activeicons})`,
                    WebkitMaskImage: `url(${tab.Activeicons})`,
                    maskRepeat: "no-repeat",
                    WebkitMaskRepeat: "no-repeat",
                    maskPosition: "center",
                    WebkitMaskPosition: "center",
                    maskSize: "contain",
                    WebkitMaskSize: "contain",
                }}
                className="self-center pr-1 py-1 h-4 w-4"
            />
            <span
                style={{
                    borderLeftWidth: "1px",
                    paddingLeft: "7px",
                }}
                className="ml-1 py-1 text-sm font-Regular"
            >
                {tab.label}
            </span>
        </Button>
    );

    const validationSchema = Yup.object({
        servicerId: Yup.string().required("Servicer Name is required"),
    });

    const formik = useFormik({
        initialValues: {
            servicerId: "",
        },
        validationSchema,
        onSubmit: (values) => {
            // setLoading(true);
            const res = editClaimServicerValue(claimDetails._id, values).then((res) => {
                console.log(res);
                // setLoading(false);
                // openModel();
                setServicer(values.servicerId)
                closeServicer();
                getClaimDetails(claimId);
                // setTimer(3);
            })


            // setLoading(false);
        },
    });

    const handleSelectChange = async (name, selectedOption) => {
        console.log("Selected Option (full object) in Parent:", selectedOption); // This should show the full object

        if (selectedOption) {
            // If you only need the value 
            console.log("Selected Servicer ID:", selectedOption); // You can extract the value like this
            setServicer(selectedOption);
            formik.setFieldValue(name, selectedOption);
        }
    };

    return (
        <>
            {loading1 && (
                <div className=" fixed z-[999999] bg-[#333333c7] backdrop-blur-xl  h-screen w-full flex py-5">
                    <div className="self-center mx-auto">
                        <RotateLoader color="#fff" />
                    </div>
                </div>
            )}
            <div className="py-8 pl-3 relative ">
                <Headbar />
                <div className="flex">
                    <div
                        onClick={handleGOBack}
                        className="h-[60px] w-[60px] flex border-[1px] bg-white border-Light-Grey rounded-[25px]"
                    >
                        <img
                            src={BackImage}
                            className="m-auto my-auto self-center bg-white"
                            alt="BackImage"
                        />
                    </div>
                    <div className="pl-3">
                        <p className="font-bold text-[36px] leading-9 mb-[3px]">
                            Claim Details
                        </p>
                        <ul className="flex self-center">
                            <li className="text-sm  font-Regular">
                                <Link to={"/"}>Home / </Link>
                            </li>
                            <li className="text-sm  font-Regular pl-2">
                                <Link to={"/sevicer/claimList"}>Claim List / </Link>
                            </li>
                            <li className="text-sm  font-semibold ml-1 pt-[1px]">
                                {activeTab}
                            </li>
                        </ul>
                    </div>
                </div>

                <Grid className="!grid-cols-4 mt-5">
                    <div className="col-span-1 max-h-[85vh] overflow-y-scroll">
                        <SingleView className="p-5 rounded-[20px]">
                            <Grid>
                                <div className="col-span-12">
                                    <p className="text-sm font-Regular">
                                        Claim ID
                                    </p>
                                    <p className="text-xl font-semibold">
                                        {" "}
                                        {claimDetails.unique_key}{" "}
                                    </p>
                                </div>

                            </Grid>
                            <div className="flex my-4">
                                <div className="relative">
                                    <img
                                        src={category1}
                                        className="mr-3 bg-Onyx rounded-[14px]"
                                        alt="Name"
                                    />
                                </div>
                                <div className="w-[75%]">
                                    <p className="text-sm font-Regular">
                                        Contract ID
                                    </p>
                                    <p className="text-base font-semibold ">
                                        {claimDetails?.contractDetail?.contractId}
                                    </p>
                                </div>
                            </div>
                            <div className="flex my-4">
                                <div className="relative">
                                    <img
                                        src={category1}
                                        className="mr-3 bg-Onyx rounded-[14px]"
                                        alt="Name"
                                    />
                                </div>
                                <div className="w-[75%]">
                                    <p className="text-sm font-Regular">
                                        Order ID
                                    </p>
                                    <p className="text-base font-semibold ">
                                        {claimDetails?.orderId}
                                    </p>
                                </div>
                            </div>
                            <div className="flex my-4">
                                <img
                                    src={DealerPO}
                                    className="mr-3 bg-Onyx rounded-[14px] my-auto"
                                    alt="DealerPO"
                                />
                                <div>
                                    <p className="text-sm font-Regular mt-2">
                                        Damage Date
                                    </p>
                                    <p className="text-base font-semibold leading-5">

                                        {new Date(
                                            claimDetails.lossDate
                                        ).toLocaleDateString("en-US", {
                                            month: "2-digit",
                                            day: "2-digit",
                                            year: "numeric",
                                        })}
                                    </p>
                                </div>
                            </div>
                            <Grid>
                                <div className="col-span-12">
                                    <div className="flex ">
                                        <img
                                            src={status}
                                            className="mr-3 bg-Onyx rounded-[14px] my-auto"
                                            alt="status"
                                        />
                                        <div className='w-full self-center'>
                                            <p className="text-sm font-Regular">
                                                Claim Status
                                            </p>
                                            <p className="text-base font-semibold leading-5 break-words capitalize w-[92%]">
                                                {claimDetails?.claimStatus?.[0].status}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-12">
                                    <div className="flex mb-4 ">
                                        <img
                                            src={money}
                                            className="mr-3 bg-Onyx rounded-[14px] my-auto"
                                            alt="status"
                                        />
                                        <div className='w-full self-center'>
                                            <p className="text-sm font-Regular">
                                                Claim Amount
                                            </p>
                                            <p className="text-base font-semibold leading-5 break-words w-[92%]">
                                                ${ }
                                                {claimDetails?.totalAmount === undefined
                                                    ? parseInt(0).toLocaleString(2)
                                                    : formatOrderValue(
                                                        claimDetails?.totalAmount ??
                                                        parseInt(0)
                                                    )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            {claimDetails?.resellerId == null ? (
                                <></>
                            ) : (
                                <div className="flex mb-4">
                                    <div className="relative">
                                        <img
                                            src={Name}
                                            className="mr-3 bg-Onyx rounded-[14px]"
                                            alt="Name"
                                        />
                                    </div>
                                    <div className="w-[75%]">
                                        <p className="text-sm font-Regular">
                                            Reseller Name
                                        </p>
                                        <p className="text-base font-semibold ">
                                            {claimDetails?.resellerDetail?.name}
                                        </p>
                                    </div>
                                </div>
                            )}

                            <div className="flex mb-4">
                                <div className="relative">
                                    <img
                                        src={Name}
                                        className="mr-3 bg-Onyx rounded-[14px]"
                                        alt="Name"
                                    />
                                </div>
                                <div className="w-[75%]">
                                    <p className="text-sm font-Regular">
                                        Customer Name
                                    </p>
                                    <p className="text-base font-semibold ">
                                        {claimDetails?.customerDetail?.name}
                                    </p>
                                </div>
                            </div>

                        </SingleView>
                    </div>
                    <div className="col-span-3 max-h-[85vh] pr-3 overflow-y-scroll">
                        <Grid className="">
                            <div className="col-span-8">
                                <div className="!rounded-[30px] px-2 py-3  border-[1px] border-Light-Grey">
                                    <Grid className="!grid-cols-3 !gap-1">
                                        {tabs.map((tab) =>
                                            activeTab === tab.id ? (
                                                <ActiveTabButton key={tab.id} tab={tab} onClick={() => handleTabClick(tab.id)} />
                                            ) : (
                                                <InactiveTabButton key={tab.id} tab={tab} onClick={() => handleTabClick(tab.id)} />
                                            )
                                        )}
                                    </Grid>
                                </div>
                            </div>
                            <div className=" col-span-4">

                            </div>
                        </Grid>

                        {tabs.map((tab) => (
                            <div
                                key={tab.id}
                                className={`${activeTab !== tab.id ? "hidden" : "pb-20"}`}
                            >
                                {tab.content}
                            </div>
                        ))}
                    </div>
                </Grid >
            </div >
        </>
    )
}

export default ServicerClaimSingleView
