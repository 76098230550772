export const claimReportDownloadOptions = [
  {
    "index": "10",
    "title": "General",
    "sections": [
      {
        "label": "Claim Id",
        "action": "unique_key"
      },
      {
        "label": "Order Id",
        "action": "orderId"
      },
      {
        "label": "Contract Id",
        "action": "contractId"
      },
      {
        "label": "Customer Status",
        "action": "customerStatus"
      },

      {
        "label": "Repair Status",
        "action": "repairStatus"
      },
      {
        "label": "Claim Status",
        "action": "claimStatus"
      },
      {
        "label": "Claim Amount",
        "action": "totalAmount"
      }
    ]
  },
  {
    "index": "11",
    "title": "Claim",
    "apiFieldName": "claimNotification",
    "sections": [
      {
        "label": "Diagnosis",
        "action": "diagnosis"
      },
      {
        "label": "Coverage Type",
        "action": "claimType"
      },

      {
        "label": "Damage Date",
        "action": "lossDate"
      },
      {
        "label": "Rejection Reason",
        "action": "reason"
      },
      {
        "label": "Note",
        "action": "note"
      },


      {
        "label": "Shipment Type",
        "action": "trackingType"
      },
      // {
      //   "label": "Coverage Type",
      //   "action": "claimType"
      // },
      // {
      //   "label": "Repair Parts",
      //   "action": "repairParts"
      // },

      {
        "label": "Customer Status Date",
        "action": "customerStatusDate"
      },
      {
        "label": "Claim Status Date",
        "action": "claimStatusDate"
      },
      {
        "label": "Repair Status Date",
        "action": "repairStatusDate"
      },
      {
        "label": "Shipment Number",
        "action": "trackingNumber"
      },
      {
        "label": "Submited By",
        "action": "submittedBy"
      },
      {
        "label": "Customer Shipping Address",
        "action": "shippingTo"
      },
      {
        "label": "Getcover Cost",
        "action": "getCoverClaimAmount"
      },
      {
        "label": "Customer Cost",
        "action": "customerClaimAmount"
      },

      {
        "label": "Payment Status",
        "action": "paymentStatus"
      },

      {
        "label": "Claim creation date",
        "action": "createdAt"
      },
      {
        "label": "Claim Approved date",
        "action": "approveDate"
      },
    ]
  },
  {
    "index": "12",
    "title": "Contract",
    "apiFieldName": "contractNotification",
    "sections": [
      {
        "label": "Dealer P.O.#",
        "action": "venderOrder"
      },

      {
        "label": "Manufacturer",
        "action": "manufacture"
      },
      {
        "label": "Model",
        "action": "model"
      },
      {
        "label": "Serial Number",
        "action": "serial"
      },
      {
        "label": "Condition",
        "action": "condition"
      },
      {
        "label": "Retail Price",
        "action": "retailPrice"
      },
      {
        "label": "Product Category",
        "action": "category"
      },
      {
        "label": "Product SKU",
        "action": "pName"
      },
      {
        "label": "Dealer SKU",
        "action": "dealerSku"
      },

      {
        "label": "Product Name",
        "action": "productName"
      },
      {
        "label": "Product Description",
        "action": "description"
      },
      {
        "label": "Price Type",
        "action": "priceType"
      },
      {
        "label": "Coverage Start Date",
        "action": "coverageStartDate"
      },
      {
        "label": "Coverage End Date",
        "action": "coverageEndDate"
      },
      {
        "label": "Claim Payment Status",
        "action": "claimPaymentStatus"
      },
      {
        "label": "Manufacturer Labour Warranty End Date",
        "action": "labourWarrantyDate"
      },
      {
        "label": "Manufacturer Parts Warranty End Date",
        "action": "partsWarrantyDate"
      },
      {
        "label": "Purchase Date",
        "action": "purchaseDate"
      },
      {
        "label": "No of Claim Over the Certain Period",
        "action": "noOfClaimPerPeriod"
      },
      {
        "label": "Number of Claims in Coverage Period",
        "action": "noOfClaim"
      },
      {
        "label": "Contract Status",
        "action": "eligibilty"
      },
      {
        "label": "Is Manufacturer Warranty?",
        "action": "isManufacturerWarranty"
      },
      {
        "label": "Is Max Claim Amount?",
        "action": "isMaxClaimAmount"
      },


    ]
  },
  {
    "index": "13",
    "title": "User",
    "apiFieldName": "userNotification",
    "sections": [
      {
        "label": "Dealer Name",
        "action": "dealerName"
      },
      {
        "label": "Reseller Name",
        "action": "resellerName"
      },
      {
        "label": "Servicer Name",
        "action": "servicerName"
      },
      {
        "label": "Customer Name",
        "action": "customerUsername"
      }
    ]
  }
]

