import React, { useEffect, useState } from "react";
import Grid from "../../../../common/grid";
import Administration from "../../../../assets/images/Administration.png";
import Fronting from "../../../../assets/images/Fronting.png";
import insurance from "../../../../assets/images/Re-insurance.png";
import Reserves from "../../../../assets/images/Reserves.png";
import Broker from "../../../../assets/images/Broker.png";
import Button from "../../../../common/button";
import SelectedDateRangeComponent from "../../../../common/dateFilter";
import Modal from "../../../../common/model";
import Cross from "../../../../assets/images/Cross.png";
import LineChart from "../../../../common/lineChart";
import { getAllSales } from "../../../../services/reportingServices";
import { RotateLoader } from "react-spinners";
import { useMyContext } from "../../../../context/context";
import Card from "../../../../common/card";
import InActiveButton from "../../../../common/inActiveButton";
import MultiColorView from "../../../../common/multiColorView";

function All({ activeTab, activeButton }) {
  const formatDateToYYYYDDMM = (date) => {
    const newDate = new Date(date);
    const year = newDate.getUTCFullYear();
    const day = String(newDate.getUTCDate()).padStart(2, '0');
    const month = String(newDate.getUTCMonth() + 1).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState("daily");
  const [selectedRange, setSelectedRange] = useState({
    startDate: formatDateToYYYYDDMM(new Date(new Date().setDate(new Date().getDate() - 14))),
    endDate: formatDateToYYYYDDMM(new Date()),
  });
  const [graphDataCount, setGraphDataCount] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [totalFees, setTotalFees] = useState({});
  const { filters, flag1, toggleFilterFlag, filtersCategoryTab1 } =
    useMyContext();
  console.log(toggleFilterFlag);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const formatOrderValue = (orderValue) => {
    if (Math.abs(orderValue) >= 1e6) {
      return (orderValue / 1e6).toFixed(2) + "M";
    } else {
      return orderValue?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  };

  const handleApply = () => {
    const { startDate, endDate } = selectedRange;
    console.log(startDate, endDate)
    const startDateStr = startDate;
    const endDateStr = endDate;
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let flag = "daily";
    if (diffDays < 30) {
      flag = "daily";
    } else {
      flag = "weekly";
    }
    setFlag(flag);
    let data = {
      startDate: startDateStr,
      endDate: endDateStr,
      dealerId: activeButton == "dealer" ? filters.dealerId : "",
      priceBookId:
        activeButton == "dealer"
          ? filters.priceBookId
          : filtersCategoryTab1.priceBookId,
      categoryId:
        activeButton == "dealer"
          ? filters.categoryId
          : filtersCategoryTab1.categoryId,
      flag: flag,
    };

    getDatasetAtEvent(data);
    setIsModalOpen(false);
  };



  useEffect(() => {
    setLoading(true);
    getDatasetAtEvent({
      startDate: formatDateToYYYYDDMM(selectedRange.startDate),
      endDate: formatDateToYYYYDDMM(selectedRange.endDate),
      dealerId: activeButton == "dealer" ? filters.dealerId : "",
      priceBookId:
        activeButton == "dealer"
          ? filters.priceBookId
          : filtersCategoryTab1.priceBookId,
      categoryId:
        activeButton == "dealer"
          ? filters.categoryId
          : filtersCategoryTab1.categoryId,
      flag: flag,
    });

  }, []);

  useEffect(() => {
    if (flag1) {
      setLoading(true);
      getDatasetAtEvent({
        startDate: formatDateToYYYYDDMM(selectedRange.startDate),
        endDate: formatDateToYYYYDDMM(selectedRange.endDate),
        dealerId: activeButton == "dealer" ? filters.dealerId : "",
        priceBookId:
          activeButton == "dealer"
            ? filters.priceBookId
            : filtersCategoryTab1.priceBookId,
        categoryId:
          activeButton == "dealer"
            ? filters.categoryId
            : filtersCategoryTab1.categoryId,
        flag: flag,
      });
    }
  }, [flag1]);

  const getDatasetAtEvent = async (data) => {
    setLoading(true);
    try {
      const res = await getAllSales(data);

      const amountData = res.result.graphData.map((item) => {
        const { total_orders, total_contracts, ...rest } = item;
        console.log(data);
        return rest;
      });

      const countData = res.result.graphData.map((item) => {
        return {
          weekStart: item.weekStart,
          total_orders: item.total_orders,
          total_contracts: item.total_contracts,
        };
      });
      // if (activeTab === "Amount") {
      //   filteredGraphData = res.result.graphData.map((item) => {
      //     const { total_orders, total_contracts, ...rest } = item;
      //     return rest;
      //   });
      // } else {
      //   console.log("herrrrrrrrrrrrrrrrrr");
      //   filteredGraphData = res.result.graphData.map((item) => {
      //     return {
      //       weekStart: item.weekStart,
      //       total_orders: item.total_orders,
      //       total_contracts: item.total_contracts,
      //     };
      //   });
      // }
      setGraphData(amountData);
      setGraphDataCount(countData);
      setTotalFees(res.result.totalFees);
      toggleFilterFlag();
    } catch (error) {
      console.error("Error fetching sales data:", error);
    }
    setLoading(false);
  };

  const isValidDateRange = (startDate, endDate) => {
    const oneYear = 365 * 24 * 60 * 60 * 1000;
    return endDate - startDate <= oneYear;
  };

  const handleRangeChange = (ranges) => {
    let { startDate, endDate } = ranges.selection;

    // Convert startDate and endDate to Date objects (if needed)
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    // Ensure no time offset is affecting the date selection
    const adjustDateToUTC = (date) => {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    };

    const today = adjustDateToUTC(new Date());

    if (isValidDateRange(startDate, endDate)) {
      setSelectedRange({
        startDate: formatDateToYYYYDDMM(startDate > today ? today : adjustDateToUTC(startDate)),
        endDate: formatDateToYYYYDDMM(endDate > today ? today : adjustDateToUTC(endDate)),
      });
    } else {
      alert("Date range cannot exceed one year.");
    }
  };



  return (
    <>
      {loading ? (
        <div className=" h-[400px] w-full flex py-5">
          <div className="self-center mx-auto">
            <RotateLoader color="#333" />
          </div>
        </div>
      ) : (
        <>
          <Grid>
            <div className="col-span-12">
              <MultiColorView className="rounded-[20px] p-3 my-4 border-[1px] border-Light-Grey">
                <Grid className="!gap-1">
                  <div className="col-span-6 self-center">
                    <p className="text-xl font-bold">Total sales</p>
                  </div>
                  <div className="col-span-6 self-center flex ml-auto">
                    <p className="text-sm self-center mr-5">
                      {`Selected Range: ${selectedRange?.startDate} To ${selectedRange?.endDate}`}
                    </p>
                    <InActiveButton onClick={openModal}>
                      {/* <img src={Broker} className="pr-1 py-1" alt="Filter" /> */}
                      <span className="py-1">Date Filter</span>
                    </InActiveButton>
                  </div>

                  <div className="col-span-12">
                    <LineChart
                      graphData={
                        activeTab == "Amount" ? graphData : graphDataCount
                      }
                    />
                  </div>
                </Grid>
              </MultiColorView>
            </div>
          </Grid>

          <Card className=" rounded-[20px] p-3 my-4 border-[1px] border-Light-Grey !h-auto">
            <Grid className="!grid-cols-5">
              <div className="col-span-1 border-r bg-gradient-to-t from-[#FFFFFF00] via-[#AAAAAA] to-[#FFFFFF00] pr-[1px]">
                <Card className="h-full px-2">
                  <div className="flex w-full justify-between mb-4">
                    <div>
                      <p className="text-2xl font-bold">
                        $
                        {totalFees?.total_admin_fee === undefined
                          ? parseInt(0).toLocaleString(2)
                          : formatOrderValue(
                            totalFees?.total_admin_fee ?? parseInt(0)
                          )}
                      </p>
                      <p className="text-sm font-bold self-center">
                        Administration <br /> Fees
                      </p>
                    </div>
                    <div>
                      <img
                        src={Administration}
                        className="w-[55px]"
                        alt="Administration"
                      />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-span-1 border-r bg-gradient-to-t from-[#FFFFFF00] via-[#AAAAAA] to-[#FFFFFF00] pr-[1px]">
                <Card className="h-full  px-2">
                  <div className="flex w-full justify-between mb-4">
                    <div>
                      <p className="text-2xl font-bold">
                        $
                        {totalFees?.total_fronting_fee === undefined
                          ? parseInt(0).toLocaleString(2)
                          : formatOrderValue(
                            totalFees?.total_fronting_fee ?? parseInt(0)
                          )}
                      </p>
                      <p className="text-sm font-bold self-center">
                        Fronting <br /> Fees
                      </p>
                    </div>
                    <div>
                      <img
                        src={Fronting}
                        className="w-[55px]"
                        alt="Administration"
                      />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-span-1 border-r bg-gradient-to-t from-[#FFFFFF00] via-[#AAAAAA] to-[#FFFFFF00] pr-[1px]">
                <Card className="h-full  px-2">
                  <div className="flex w-full justify-between mb-4">
                    <div>
                      <p className="text-2xl font-bold">
                        $
                        {totalFees?.total_reinsurance_fee === undefined
                          ? parseInt(0).toLocaleString(2)
                          : formatOrderValue(
                            totalFees?.total_reinsurance_fee ?? parseInt(0)
                          )}
                      </p>
                      <p className="text-sm font-bold self-center">
                        Re-insurance <br /> Fees
                      </p>
                    </div>
                    <div>
                      <img
                        src={insurance}
                        className="w-[55px]"
                        alt="Administration"
                      />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-span-1 border-r bg-gradient-to-t from-[#FFFFFF00] via-[#AAAAAA] to-[#FFFFFF00] pr-[1px]">
                <Card className="h-full px-2">
                  <div className="flex w-full justify-between mb-4">
                    <div>
                      <p className="text-2xl font-bold">
                        $
                        {totalFees?.total_reserve_future_fee === undefined
                          ? parseInt(0).toLocaleString(2)
                          : formatOrderValue(
                            totalFees?.total_reserve_future_fee ?? parseInt(0)
                          )}
                      </p>
                      <p className="text-sm font-bold self-center">
                        Reserves Future <br /> Claims
                      </p>
                    </div>
                    <div>
                      <img
                        src={Reserves}
                        className="w-[55px]"
                        alt="Administration"
                      />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-span-1 px-2">
                <Card className="flex w-full justify-between mb-4">
                  <div>
                    <p className="text-2xl font-bold">
                      $
                      {totalFees?.total_broker_fee === undefined
                        ? parseInt(0).toLocaleString(2)
                        : formatOrderValue(
                          totalFees?.total_broker_fee ?? parseInt(0)
                        )}
                    </p>
                    <p className="text-sm font-bold self-center">
                      Broker <br /> Fees
                    </p>
                  </div>
                  <div>
                    <img src={Broker} className="w-[55px]" alt="Administration" />
                  </div>
                </Card>
              </div>
            </Grid>
          </Card>
        </>
      )}


      <Modal isOpen={isModalOpen} className="w-[72vw]" onClose={closeModal}>
        <Button
          onClick={closeModal}
          className="absolute right-[-13px] z-10 top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
        >
          <img
            src={Cross}
            className="w-full h-full text-black rounded-full p-0"
          />
        </Button>
        <SelectedDateRangeComponent
          selectedRange={selectedRange}
          onRangeChange={handleRangeChange}
          onApply={handleApply}
        />
        <div className="flex justify-end mb-4">
          <InActiveButton onClick={closeModal} className="mr-3">
            Cancel
          </InActiveButton>
          <Button onClick={handleApply}>Apply</Button>
        </div>
      </Modal>
    </>
  );
}

export default All;
