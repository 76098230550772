import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../common/button";

import ActiveIcon from "../../../../assets/images/icons/iconAction.svg";
import star from "../../../../assets/images/icons/star.svg";
import Primary from "../../../../assets/images/SetPrimary.png";
import NotificationImage from "../../../../assets/images/icons/Notification-icon.svg";
import deleteUser10 from "../../../../assets/images/deleteUser.svg";
import assign from "../../../../assets/images/Unassign.png";
import Search from "../../../../assets/images/icons/SearchIcon.svg";
import clearFilter from "../../../../assets/images/icons/Clear-Filter-Icon-White.svg";
import shorting from "../../../../assets/images/icons/shorting.svg";
import Grid from "../../../../common/grid";
import Input from "../../../../common/input";
import DataTable from "react-data-table-component";
import { RotateLoader } from "react-spinners";
import Modal from "../../../../common/model";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  changePrimaryByUserId,
  deleteUserByUserId,
  getUserListByDealerId,
  userDetailsById,
  updateUserDetailsById,
  getCustomerAddress,
} from "../../../../services/userServices";
import Select from "../../../../common/select";
import { getCustomerUsersById } from "../../../../services/customerServices";
import { useMyContext } from "../../../../context/context";
import { getServicerUsersById } from "../../../../services/servicerServices";
import { getResellerUsersById } from "../../../../services/reSellerServices";
import Card from "../../../../common/card";
import InActiveButton from "../../../../common/inActiveButton";
import make from "../../../../assets/images/star.png";
import edit from "../../../../assets/images/edit-text.png";
import delete1 from "../../../../assets/images/delete.png";
import SingleView from "../../../../common/singleView";
import SwitchButton from "../../../../common/switch";
import { getUserNotificationData, updateNotificationData } from "../../../../services/extraServices";
import { Notifications } from "../../../../notificationjson";
import CollapsibleDiv from "../../../../common/collapsibleDiv";
import Cross from "../../../../assets/images/Cross.png";
import SelectBoxWithSearch from "../../../../common/selectBoxWIthSerach";

function UserList(props) {
  const { toggleFlag } = useMyContext();
  const [selectedAction, setSelectedAction] = useState(null);
  const [userList, setUserList] = useState([]);
  const [activeIndex1, setActiveIndex1] = useState(null);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isModalOpen, SetIsModalOpen] = useState(false);
  const [showdata, setShowdata] = useState(true);
  const [title, setTitle] = useState('');
  const [isprimary, SetIsprimary] = useState(false);
  const [notificationList, setNotificationList] = useState(Notifications)
  const [mainStatus, setMainStatus] = useState(true);
  const [servicerStatus, setServiceStatus] = useState(true);
  const [deleteId, setDeleteId] = useState("");
  const [notificationSettings, setNotificationSettings] = useState([]);
  const [dealerStatus, setDealerStatus] = useState(true);
  const [notification, setNotification] = useState({})
  const [primaryText, SetPrimaryText] = useState("");
  const [secondaryText, SetSecondaryText] = useState("");
  const [timer, setTimer] = useState(3);
  const dropdownRef = useRef(null);
  const [addressList, setAddressList] = useState([]);
  const [isModalOpen12, setIsModalOpen12] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({
    lastName: "",
    firstName: "",
    phoneNumber: "",
    position: "",
    addressId: "",
    status: true,
    id: "",
  });
  // console.log("toggleFlag", toggleFlag);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const getUserList = async () => {
    setLoading(true);
    if (props.flag == "customer") {
      const result = await getCustomerUsersById(props.id, {});
      console.log(result.result);
      setDealerStatus(result.isAccountCreate);
      setUserList(result.result);
    } else if (props.flag == "servicer") {
      const result = await getServicerUsersById(props.id, {});
      console.log(result);
      setServiceStatus(result.servicerStatus);
      setDealerStatus(result.isAccountCreate);
      setUserList(result.result);
    } else if (props.flag == "reseller") {
      const result = await getResellerUsersById(props.id, {});
      console.log(result);
      setDealerStatus(result.isAccountCreate);
      // setServiceStatus(result.servicerStatus);
      setUserList(result.data);
    } else {
      const result = await getUserListByDealerId(props.id, {});
      console.log(result.result);
      setDealerStatus(result.isAccountCreate);
      setServiceStatus(result.dealerStatus);
      setUserList(result.result);
    }
    setLoading(false);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Close the dropdown if the click is outside of it
      setSelectedAction(null);
    }
  };
  useEffect(() => {
    if (props.activeTab === "Users") {
      getUserList();
      getAddress();
    }
  }, [props]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (props?.data?.length !== 0) {
      setLoading(true);
      getUserList();
      setLoading(false);
    }
  }, [props?.data]);
  useEffect(() => {
    setLoading(true);
    let intervalId;

    if ((isModalOpen || (isModalOpen12 && timer > 0)) && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      closeModal1();
      setSelectedAction(null);
      closeModal();
      closeModal12();
      getUserList();
    }

    if (!isModalOpen && !isModalOpen12) {
      clearInterval(intervalId);
      setTimer(3);
    }

    setLoading(false);

    return () => {
      clearInterval(intervalId);
    };
  }, [isModalOpen, isModalOpen12, timer]);

  const handleChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  const getAddress = async () => {
    try {
      const res = await getCustomerAddress(props.id);
      console.log(res.result.addresses, "------------");

      const addresses = res.result.addresses.map((address) => ({
        label: `${address.address}, ${address.city}, ${address.state}, ${address.zip}`,
        value: `${address._id}`,
      }));
      setAddressList(addresses);


    } catch (error) {
      console.error("Error fetching contract values or processing data:", error);
    }
  };

  const closeModal = () => {
    SetIsModalOpen(false);
  };

  const openModal = () => {
    SetIsModalOpen(true);
    getUserList();
  };

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const handleSelectChange = async (name, value) => {
    formik.setFieldValue(name, value);
  };

  const closeModal1 = () => {
    setIsModalOpen1(false);
  };

  const openModal1 = (id) => {
    setDeleteId(id);
    setIsModalOpen1(true);
  };

  const status = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const closeModal2 = () => {
    setIsModalOpen2(false);
    formik.resetForm();
  };

  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal12 = () => {
    setIsModalOpen12(false);
  };

  const openModal12 = () => {
    setIsModalOpen12(true);
  };

  const handleStatusChange = async (row, newStatus) => {
    console.log(row);
    try {
      setUserList((userData) => {
        return userData.map((user) => {
          if (user._id === row._id) {
            return {
              ...user,
              status: newStatus === "active" ? true : false,
            };
          }
          return user;
        });
      });

      const result = await updateUserDetailsById({
        id: row._id,
        status: newStatus === "active" ? true : false,
      });

      console.log(result);
    } catch (error) {
      console.error("Error in handleStatusChange:", error);
    }
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Required")
        .max(30, "Must be exactly 30 characters"),
      lastName: Yup.string()
        .required("Required")
        .max(30, "Must be exactly 30 characters"),
      phoneNumber: Yup.string()
        .required("Required")
        .min(10, "Must be at least 10 characters")
        .max(10, "Must be exactly 10 characters")
        .matches(/^[0-9]+$/, "Must contain only digits"),
      status: Yup.boolean().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log("Form values:", values);
      setLoading1(true);
      const result = await updateUserDetailsById(values);
      console.log(result);
      if (result.code == 200) {
        setLoading1(false);
        SetPrimaryText("User Updated Successfully ");
        SetSecondaryText("user updated successfully ");
        openModal();
        toggleFlag();
        // setIsModalOpen3(true);

        // setError(result.message);
        setTimer(3);
        getUserList();
      } else {
        setLoading1(false);
        // setError(false);
        // setIsModalOpen(true);
        // setTimer(3);
      }
      closeModal2();
    },
  });

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
  };

  const deleteUser = async () => {
    const result = await deleteUserByUserId(deleteId);
    console.log(result);
    if (result.code === 200) {
      getUserList();
      setIsModalOpen12(true);
      // closeModal1();
    }
  };

  const editUser = async (id) => {
    console.log(id);
    const result = await userDetailsById(id);
    console.log(result.result.status);
    SetIsprimary(result.result.isPrimary);
    setMainStatus(result.mainStatus);
    const add = await addressList.find((addr) => addr.value === result?.result?.addressId)
    formik.setValues({
      id: id,
      lastName: result?.result?.lastName,
      firstName: result?.result?.firstName,
      phoneNumber: result?.result?.phoneNumber,
      position: result?.result?.position,
      status: result?.result?.status,
      addressId: add,
    });

    openModal2();
  };

  const makeUserPrimary = async (row) => {
    console.log(row._id);
    const result = await changePrimaryByUserId(row._id);
    console.log(result);
    if (result.code === 200) {
      SetPrimaryText("It's set to Primary");
      SetSecondaryText("We have successfully made this user primary");
      toggleFlag();
      openModal();
    }
  };

  const filterUserDetails = async (data) => {
    try {
      setLoading(true);
      const res = await getUserListByDealerId(props.id, data);
      setUserList(res.result);
    } catch (error) {
      console.error("Error fetching category list:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, ""); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match groups of 3 digits

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber; // Return original phone number if it couldn't be formatted
  };

  const handleFilterIconClick = () => {
    formikUSerFilter.resetForm();
    console.log(formikUSerFilter.values);
    getUserList();
  };

  const formikUSerFilter = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string(),
      email: Yup.string(),
      phone: Yup.number(),
    }),
    onSubmit: async (values) => {
      filterUserDetails(values);
    },
  });

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <div className="flex relative">
          {row.isPrimary && (
            <img src={star} alt="" className="absolute -left-3 top-0" />
          )}
          <span className="self-center pt-2 ml-3">
            {row.firstName} {row.lastName}
          </span>
        </div>
      ),
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => "+1 " + formatPhoneNumber(row.phoneNumber),
      sortable: true,
    },
    {
      name: "Position",
      selector: (row) => row.position,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div className="relative">
          <div
            className={` ${row.status === true ? "bg-[#6BD133]" : "bg-[#FF4747]"
              } absolute h-3 w-3 rounded-full top-[33%] ml-[8px]`}
          ></div>
          <select
            disabled={row.isPrimary || !servicerStatus || !dealerStatus}
            value={row.status === true ? "active" : "inactive"}
            onChange={(e) => handleStatusChange(row, e.target.value)}
            className="text-[12px] border border-gray-300 text-[#727378] pl-[20px] py-2 pr-1 font-semibold rounded-xl"
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      ),
    },
    {
      name: "Action",
      minWidth: "auto", // Set a custom minimum width
      maxWidth: "90px", // Set a custom maximum width
      cell: (row, index) => {
        // console.log(index, index % 10 == 9)
        return (
          <div className="relative">
            <div
              onClick={() =>
                setSelectedAction(
                  selectedAction === row.email ? null : row.email
                )
              }
            >
              <img
                src={ActiveIcon}
                className="cursor-pointer	w-[35px]"
                alt="Active Icon"
              />
            </div>
            {selectedAction === row.email && (
              <SingleView
                ref={dropdownRef}
                className={`absolute z-[9999] ${!row.isPrimary ? "w-[140px]" : "w-[140px]"
                  }  drop-shadow-5xl -right-3 mt-2 py-1 border rounded-lg shadow-md bottom-[1rem] newImagecolor`}
              >
                {!row.isPrimary && row.status && (
                  <div
                    className=" text-left cursor-pointer flex border-b py-1 px-2"
                    onClick={() => makeUserPrimary(row)}
                  >
                    <div
                      style={{
                        maskImage: `url(${make})`,
                        WebkitMaskImage: `url(${make})`,
                        maskRepeat: "no-repeat",
                        WebkitMaskRepeat: "no-repeat",
                        maskPosition: "center",
                        WebkitMaskPosition: "center",
                        maskSize: "contain",
                        WebkitMaskSize: "contain",
                      }}
                      className="self-center singleViews mr-2 h-4 w-4 "
                    />
                    <span class="self-center"> Make Primary </span>

                  </div>
                )}

                <div
                  className="text-left cursor-pointer flex border-b py-1 px-2"
                  onClick={() => editUser(row._id)}
                >
                  <div
                    style={{
                      maskImage: `url(${edit})`,
                      WebkitMaskImage: `url(${edit})`,
                      maskRepeat: "no-repeat",
                      WebkitMaskRepeat: "no-repeat",
                      maskPosition: "center",
                      WebkitMaskPosition: "center",
                      maskSize: "contain",
                      WebkitMaskSize: "contain",
                    }}
                    className="self-center singleViews mr-2 h-4 w-4 "
                  />
                  <span class="self-center">
                    Edit
                  </span>
                </div>
                <div
                  onClick={() => openNotification(row._id)}
                  className={`text-left cursor-pointer flex border-b py-1 px-2`}
                >
                  <div
                    style={{
                      maskImage: `url(${NotificationImage})`,
                      WebkitMaskImage: `url(${NotificationImage})`,
                      maskRepeat: "no-repeat",
                      WebkitMaskRepeat: "no-repeat",
                      maskPosition: "center",
                      WebkitMaskPosition: "center",
                      maskSize: "contain",
                      WebkitMaskSize: "contain",
                    }}
                    className="self-center singleViews mr-2 h-4 w-4 "
                  />
                  {/* {/* <img src={edit} className="w-4 h-4 mr-2" />{" "}  */}
                  <span className="self-center">Notification </span>
                </div>
                {!row.isPrimary && (
                  <div
                    className="text-left cursor-pointer flex border-b py-1 px-2"
                    onClick={() => openModal1(row._id)}
                  >
                    <div
                      style={{
                        maskImage: `url(${delete1})`,
                        WebkitMaskImage: `url(${delete1})`,
                        maskRepeat: "no-repeat",
                        WebkitMaskRepeat: "no-repeat",
                        maskPosition: "center",
                        WebkitMaskPosition: "center",
                        maskSize: "contain",
                        WebkitMaskSize: "contain",
                      }}
                      className="self-center singleViews mr-2 h-4 w-4 "
                    />
                    <span class="self-center">
                      Delete
                    </span>
                  </div>
                )}
              </SingleView>
            )}
          </div>
        );
      },
    },
  ];

  const CustomNoDataComponent = () => (
    <Card className="text-center my-5">
      <p>No records found.</p>
    </Card>
  );

  const checkAllStatusTrue = (notificationSettings, index) => {
    console.log(notificationSettings)
    const section = notificationSettings.find((n) => n.index === index);
    if (!section) return false;
    return section.sections.every((s) => s.status === true);
  };



  const handleToggleAll = (allStatusTrue, setNotificationSettings, i) => {
    setNotificationSettings((prevSettings) => {
      const newSettings = prevSettings.map((notification, index) => {
        console.log(notificationSettings, i,);

        // Check if the current index matches the target index
        if (notification.index == i && notification.sections) {
          return {
            ...notification,
            sections: notification.sections.map((section) => ({
              ...section,
              status: !allStatusTrue, // Toggle status based on allStatusTrue
            })),
          };
        }
        return notification; // Leave other notifications unchanged
      });
      const updatedNotifications = transformDataForAPI(newSettings);
      updateNotification(updatedNotifications);

      return newSettings;
    });
  };


  const handleAddOrUpdate1 = (value, allStatusTrue, setNotificationSettings, i) => {
    setNotificationSettings((prevSettings) =>
      prevSettings.map((group, index) => {
        console.log(group.index == i)
        if (group.index == i) {
          return {
            ...group,
            sections: group.sections.map((section) =>
              section.action === value
                ? { ...section, status: !section.status }
                : section
            ),
          };
        }
        return group;
      })
    );

    // Pass the updated settings to the API
    setNotificationSettings((prevSettings) => {
      const updatedNotifications = transformDataForAPI(prevSettings);
      updateNotification(updatedNotifications);
      return prevSettings;
    });
  };

  // Transform function to structure data as per API requirements
  const transformDataForAPI = (notificationSettings) => {
    const transformedData = notificationSettings.reduce((acc, group) => {
      const apiFieldName = group.apiFieldName;
      const sections = group.sections.reduce((innerAcc, section) => {
        innerAcc[section.action] = section.status;
        return innerAcc;
      }, {});

      acc[apiFieldName] = sections;
      return acc;
    }, {});

    return {
      ...transformedData,
    };
  };

  // Update notification function
  const updateNotification = (updatedNotifications) => {
    console.log(notification)
    updateNotificationData(notification._id, updatedNotifications).then((res) => {
      console.log(res);
    });
  };


  const openNotification = async (id) => {
    const capitalizedFlag = props.flag.charAt(0).toUpperCase() + props.flag.slice(1);
    const data = await getUserNotificationData(id, capitalizedFlag);
    const notifications = data.result.notifications;
    const filteredNotifications = Notifications.filter(notification => {
      const apiFieldName = notification.apiFieldName;
      return data.result.notifications[apiFieldName] != undefined;
    });
    setNotificationList(filteredNotifications)
    console.log(filteredNotifications);

    // Map settings for toggles
    const mappedSettings = {};
    Object.entries(notifications).forEach(([categoryKey, categoryValue]) => {
      if (typeof categoryValue === "object" && categoryValue != null) {
        console.log(categoryValue)
        Object.entries(categoryValue).forEach(([key, value]) => {
          if (typeof value === "boolean") {
            mappedSettings[key] = value;
          }
        });
      }
    });

    // Update notificationList to reflect the new settings

    const unifiedNotifications = Notifications.map(notification => {
      const apiSection = notifications[notification.apiFieldName];


      return {
        index: notification.index,
        title: notification.title,
        apiFieldName: notification.apiFieldName,
        sections: notification.sections.map(section => {
          if (apiSection && apiSection[section.action] != undefined) {
            return {
              label: section.label,
              action: section.action,
              status: apiSection[section.action]
            };
          }
          return null;
        }).filter(item => item !== null)
      };
    });

    setNotificationSettings(unifiedNotifications);
    setNotification(data.result);
    setIsNotificationOpen(true);
  };


  const toggleNotification = (prevNotifications, actionKey) => {
    const updatedNotifications = { ...prevNotifications };
    for (const category in updatedNotifications.notifications) {
      if (updatedNotifications.notifications[category] && typeof updatedNotifications.notifications[category] === "object") {
        if (actionKey in updatedNotifications.notifications[category]) {
          updatedNotifications.notifications[category][actionKey] = !updatedNotifications.notifications[category][actionKey];
          break;
        }
      }
    }
    updateNotificationData(updatedNotifications._id, updatedNotifications.notifications).then((res) => {
      console.log(res)
    })
    return updatedNotifications;
  };

  const closeNotification = () => {
    setIsNotificationOpen(false);
  }

  return (
    <>
      <div className="my-8">
        <Card className="bg-white mt-6 border-[1px] border-Light-Grey rounded-xl">
          <Grid className="!p-[26px] !pt-[14px] !pb-0">
            <div className="col-span-5 self-center">
              <p className="text-xl font-semibold">Users List</p>
            </div>
            <div className="col-span-7">
              <div className="bg-grayf9 rounded-[30px] p-3 border-[1px] border-Light-Grey">
                <form className="" onSubmit={formikUSerFilter.handleSubmit}>
                  <Grid className="!grid-cols-11">
                    <div className="col-span-3 self-center">
                      <Input
                        name="firstName"
                        type="text"
                        className="!text-[14px] !bg-White-Smoke"
                        className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                        label=""
                        placeholder="First Name"
                        value={formikUSerFilter.values.firstName}
                        onBlur={formikUSerFilter.handleBlur}
                        onChange={formikUSerFilter.handleChange}
                      />
                    </div>
                    <div className="col-span-3 self-center">
                      <Input
                        name="email"
                        type="text"
                        className="!text-[14px] !bg-White-Smoke"
                        className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                        label=""
                        placeholder="Email"
                        value={formikUSerFilter.values.email}
                        onBlur={formikUSerFilter.handleBlur}
                        onChange={formikUSerFilter.handleChange}
                      />
                    </div>
                    <div className="col-span-3 self-center">
                      <Input
                        name="phone"
                        type="tel"
                        className="!text-[14px] !bg-White-Smoke"
                        className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                        label=""
                        placeholder="Phone"
                        value={formikUSerFilter.values.phone}
                        onBlur={formikUSerFilter.handleBlur}
                        onChange={(e) => {
                          const sanitizedValue = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          console.log(sanitizedValue);
                          formikUSerFilter.handleChange({
                            target: {
                              name: "phone",
                              value: sanitizedValue,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-span-2 self-center flex justify-center">
                      <Button type="submit" className="!p-2">
                        <img
                          src={Search}
                          className="cursor-pointer "
                          alt="Search"
                        />
                      </Button>
                      <InActiveButton
                        type="submit"
                        onClick={() => {
                          handleFilterIconClick();
                        }}
                      >
                        <div
                          style={{
                            maskImage: `url(${clearFilter})`,
                            WebkitMaskImage: `url(${clearFilter})`,
                            maskRepeat: "no-repeat",
                            WebkitMaskRepeat: "no-repeat",
                            maskPosition: "center",
                            WebkitMaskPosition: "center",
                            maskSize: "contain",
                            WebkitMaskSize: "contain",
                          }}
                          className="self-center pr-1 py-1 h-4 w-4 cursor-pointer mx-auto"
                        />
                      </InActiveButton>
                    </div>
                  </Grid>
                </form>
              </div>
            </div>
          </Grid>
          <div className="mb-5 relative dealer-detail">
            {loading ? (
              <div className=" h-[400px] w-full flex py-5">
                <div className="self-center mx-auto">
                  <RotateLoader color="#333" />
                </div>
              </div>
            ) : (
              <DataTable
                className="data-table-container" draggableColumns={false}
                columns={columns}
                data={userList}
                highlightOnHover
                sortIcon={
                  <>
                    {" "}
                    <div
                      style={{
                        maskImage: `url(${shorting})`,
                        WebkitMaskImage: `url(${shorting})`,
                        maskRepeat: "no-repeat",
                        WebkitMaskRepeat: "no-repeat",
                        maskPosition: "center",
                        WebkitMaskPosition: "center",
                        maskSize: "contain",
                        WebkitMaskSize: "contain",
                      }}
                      className="ml-2 tabless"
                    />
                  </>
                }
                noDataComponent={<CustomNoDataComponent />}
                pagination
                paginationPerPage={10}
                paginationComponentOptions={paginationOptions}
                paginationRowsPerPageOptions={[10, 20, 50, 100]}
              />
            )}
          </div>
        </Card>
      </div>

      {/* Modal Primary Popop */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="text-center py-3">
          <img src={Primary} alt="email Image" className="mx-auto" />
          <p className="text-3xl mb-0 mt-2 font-bold">
            {primaryText}
          </p>
          <p className="text-base font-medium mt-4">
            {secondaryText} <br />
            Redirecting Back to User List in {timer} Seconds
          </p>
        </div>
      </Modal>

      {/* Modal Delete Popop */}
      <Modal isOpen={isModalOpen1} onClose={closeModal1}>
        <div className="text-center py-3">
          <img src={assign} alt="email Image" className="mx-auto" />
          <p className="text-3xl mb-0 mt-2 font-semibold">
            Would you like to delete it?
          </p>
          <Grid className="!grid-cols-4 my-5 ">
            <div className="col-span-1"></div>
            <Button
              onClick={() => {
                deleteUser();
              }}
            >
              Yes
            </Button>
            <Button
              className="border w-full !border-Bright-Grey !bg-[white] !text-light-black !text-sm !font-Regular"
              onClick={() => closeModal1()}
            >
              No
            </Button>
            <div className="col-span-1"></div>
          </Grid>
        </div>
      </Modal>

      {/* Modal Delete Msg Popop */}
      <Modal isOpen={isModalOpen12} onClose={closeModal12}>
        <div className="text-center py-3">
          <img src={deleteUser10} alt="email Image" className="mx-auto" />
          <p className="text-3xl mb-0 mt-2 font-bold">
            Deleted Successfully
          </p>
          <p className="text-base font-medium mt-2">
            You have successfully deleted this user.
          </p>
          <p className="text-base font-medium mt-2">
            Redirecting Back to User List in {timer} seconds
          </p>
        </div>
      </Modal>

      {/* Modal Edit Popop */}
      <Modal isOpen={isModalOpen2} onClose={closeModal2}>
        {loading1 ? (
          <div className=" h-[400px] w-full flex py-5">
            <div className="self-center mx-auto">
              <RotateLoader color="#333" />
            </div>
          </div>
        ) : (
          <div className=" py-3">
            <p className="text-3xl text-center mb-5 mt-2 font-semibold">
              Edit User
            </p>
            <form className="mt-8" onSubmit={formik.handleSubmit}>
              <Grid className="px-8">
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="firstName"
                    label="First Name"
                    required={true}
                    className="!bg-white"
                    placeholder=""
                    maxLength={"30"}
                    value={formik.values.firstName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && formik.errors.firstName}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {formik.errors.firstName}
                    </div>
                  )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="lastName"
                    label="Last Name"
                    required={true}
                    placeholder=""
                    className="!bg-white"
                    maxLength={"30"}
                    value={formik.values.lastName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && formik.errors.lastName}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {formik.errors.lastName}
                    </div>
                  )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="position"
                    label="Position"
                    className="!bg-white"
                    // required={true}
                    placeholder=""
                    maxLength={"30"}
                    value={formik.values.position}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.position && formik.errors.position}
                  />
                  {/* {formik.touched.position && formik.errors.position && (
                <div className="text-red-500 text-sm pl-2 pt-2">
                  {formik.errors.position}
                </div>
              )} */}
                </div>
                <div className="col-span-6">
                  <Input
                    type="tel"
                    name="phoneNumber"
                    label="Phone Number"
                    required={true}
                    nonumber={true}
                    className="!bg-white"
                    placeholder=""
                    value={formik.values.phoneNumber}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      console.log(sanitizedValue);
                      formik.handleChange({
                        target: {
                          name: "phoneNumber",
                          value: sanitizedValue,
                        },
                      });
                    }}
                    onBlur={formik.handleBlur}
                    onWheelCapture={(e) => {
                      e.preventDefault();
                    }}
                    minLength={"10"}
                    maxLength={"10"}
                    error={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  />
                  {(formik.touched.phoneNumber || formik.submitCount > 0) &&
                    formik.errors.phoneNumber && (
                      <div className="text-red-500 text-sm pl-2 pt-2">
                        {formik.errors.phoneNumber}
                      </div>
                    )}
                </div>
                {props.flag === 'customer' &&
                  <div className="col-span-12">
                    <SelectBoxWithSearch
                      label="Address"
                      name="addressId"
                      className="!bg-white"
                      onChange={handleChange}
                      options={addressList}
                      value={formik.values.addressId}
                      onBlur={formik.handleBlur} />
                  </div>
                }
                <div className="col-span-6">
                  <Select
                    label="Status"
                    required={true}
                    name="status"
                    placeholder=""
                    onChange={handleSelectChange}
                    disabled={isprimary || !mainStatus}
                    className="!bg-white"
                    options={status}
                    value={formik.values.status}
                    onBlur={formik.handleBlur}
                    error={formik.touched.status && formik.errors.status}
                  />
                  {formik.touched.status && formik.errors.status && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {formik.errors.status}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid className="!grid-cols-5 my-5  px-8">
                <div className="col-span-2">
                  <InActiveButton
                    className="border w-full !text-sm !font-Regular"
                    onClick={() => closeModal2()}
                  >
                    Cancel
                  </InActiveButton>
                </div>

                <div className="col-span-3">
                  <Button type="submit" className="w-full">
                    Submit
                  </Button>
                </div>
              </Grid>
            </form>
          </div>
        )}
      </Modal>

      <Modal isOpen={isNotificationOpen} onClose={closeNotification} className="!w-[90%]">
        <Button
          onClick={closeNotification}
          className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
        >
          <img src={Cross} className="w-full h-full text-black rounded-full p-0" />
        </Button>
        <div className="py-3">
          <p className="text-3xl font-bold text-center mb-5">Notification Settings</p>
          <div className="overflow-y-scroll min-h-[200px] max-h-[400px]">
            <Grid className="!grid-cols-2 !gap-1">
              {Object.entries(notificationList || []).map(([key, value], i) => {

                if (!value) {
                  return null;
                }

                const { index, title, sections, apiFieldName } = value;
                const allStatusTrue = checkAllStatusTrue(notificationSettings, activeIndex1);

                return (
                  <div key={index} className="mb-1">
                    <CollapsibleDiv
                      key={index}
                      ShowData={showdata}
                      activeIndex={activeIndex1}
                      setActiveIndex={setActiveIndex1}
                      imageClass="w-10 h-10"
                      className="!my-2"
                      index={index}
                      title={
                        <SingleView className="border-Gray28 border px-4 py-2 rounded-t-[22px]">
                          <p className="text-lg font-bold">{title}</p>
                        </SingleView>
                      }
                    >

                      <div className="px-4 pt-2 pb-4 border">
                        <div className="text-end ml-auto mb-2">
                          <Button
                            type="button"
                            className="!text-sm"
                            onClick={() =>
                              handleToggleAll(allStatusTrue, setNotificationSettings, value.index)
                            }
                          >
                            {allStatusTrue ? "Unselect All" : "Select All"}
                          </Button>
                        </div>
                        <Grid className="!grid-cols-12 !gap-2">
                          {notificationSettings
                            .find((n) => n.index === activeIndex1)
                            ?.sections?.map(({ label, action }, itemIdx) => {
                              if (!label || !action) return null;

                              const status = notificationSettings
                                .find((n) => n.index === activeIndex1)
                                ?.sections.find((s) => s.action === action)?.status ?? false;

                              return (
                                <div className="col-span-6" key={itemIdx}>
                                  <Grid className="!gap-0">
                                    <div className="col-span-8 self-center">
                                      <p className="flex text-[12px] font-semibold justify-between">{label}</p>
                                    </div>
                                    <div className="col-span-4">
                                      <SwitchButton
                                        isOn={status}
                                        handleToggle={() =>
                                          handleAddOrUpdate1(action, allStatusTrue, setNotificationSettings, value.index)
                                        }
                                      />
                                    </div>
                                  </Grid>
                                </div>
                              );
                            })}
                        </Grid>

                      </div>
                    </CollapsibleDiv>
                  </div>
                );
              })}

            </Grid>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UserList;
