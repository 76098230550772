import React, { useEffect, useRef, useState } from 'react'
import Card from '../../../../common/card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '../../../../common/grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    faFileImage,
    faFilePdf,
    faFileWord,
    faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import Sendto from "../../../../assets/images/claim/paperPlane.png";
import Select from '../../../../common/select';
import Cross from "../../../../assets/images/Cross.png";
import upload from "../../../../assets/images/icons/upload.svg";
import Button from '../../../../common/button';
import arrowImage from "../../../../assets/images/dropdownArrow.png";
import { format } from "date-fns";
import download from '../../../../assets/images/download.png';
import { addClaimMessages, addUploadCommentImage, getClaimMessages, getSender } from '../../../../services/claimServices';
import SingleView from '../../../../common/singleView';
import { downloadFile } from '../../../../services/userServices';
import { useParams } from 'react-router-dom';
function Comment(props) {
    console.log(props, '-------------->>>');
    const { claimId } = useParams();
    const [sendto, setSendto] = useState(
        [
            {
                label: "Admin",
                value: "Super Admin",
            },
            {
                label: "Dealer",
                value: "Dealer",
            },
            props.data?.resellerId !== null && {
                label: "Reseller",
                value: "Reseller",
            },
            props.data?.servicerId !== null && {
                label: "Servicer",
                value: "Servicer",
            },
            {
                label: "Customer",
                value: "Customer",
            },
        ].filter(Boolean) // Removes falsy values (null, undefined, false)
    );
    const [previewImage, setPreviewImage] = useState(null);
    const [claimDetail, setClaimDetail] = useState(props.data);
    const [messageList, setMessageList] = useState([]);
    const [senderList, setSenderList] = useState([]);
    const initialValues2 = {
        content: "",
        orderId: "",
        type: "Dealer",
        messageFile: {},
        receiverId: ''
    };
    useEffect(() => {

        getClaimMessage();

    }, []);
    useEffect(() => {
        scrollToBottom();

    }, [messageList]);

    const getClaimMessage = () => {
        // setModelLoading(loader);
        getClaimMessages(claimId)
            .then((res) => {
                setMessageList(res.result);
            })
            .catch((error) => {
                console.error("Error fetching claim messages:", error);
            })
            .finally(() => {
                scrollToBottom();
            });
    };


    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });

    };

    const downloadImage = async (file) => {
        try {
            let data = {
                key: file.messageFile.fileName,
            };
            const binaryString = await downloadFile(data);
            const blob = new Blob([binaryString]);
            const blobUrl = URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = blobUrl;
            anchor.download = file.messageFile.fileName;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error("Error fetching the file:", error);
        }
    };

    const formik2 = useFormik({
        initialValues: initialValues2,
        validationSchema: Yup.object({
            content: Yup.string().required(),
        }),

        onSubmit: (values) => {
            values.orderId = claimDetail?.contractDetail?.orderId;

            const userInfo = JSON.parse(localStorage.getItem("userDetails"));
            const temporaryMessage = {
                _id: "temp-id",
                content: values.content,
                type: values.type || "Dealer",
                messageFile: {
                    fileName: values.fileName || "",
                    originalName: values.originalName || "",
                    size: values.size || "",
                    _id: "temp-file-id",
                },
                date: new Date().toISOString(),
                selfMessage: true,
                commentTo: {
                    firstName: values.commentToFirstName || "",
                    lastName: values.commentToLastName || "",
                },
                commentBy: {
                    firstName: userInfo?.userInfo?.firstName || "",
                    lastName: userInfo?.userInfo?.lastName || "",
                    roles: {
                        role: userInfo?.role || "",
                    },
                }
            };
            // Update the state with the temporary message
            setMessageList((prevClaimMessages) => [
                ...prevClaimMessages,
                temporaryMessage,
            ]);

            addClaimMessages(claimId, values).then((res) => {
                getClaimMessages(claimId, false);
            });
            formik2.setFieldValue("receiverId", "");
            formik2.setFieldValue("content", "");
            setPreviewImage(null);
            setFileType(null)
            const pathname = window.location.pathname;
            const type = pathname.includes("/dealer/claimList")
                ? "Dealer"
                : pathname.includes("/customer/claimList")
                    ? "Customer"
                    : pathname.includes("/reseller/claimList") ||
                        pathname.includes("/reseller/customerDetails")
                        ? "Reseller"
                        : pathname.includes("/servicer/claimList")
                            ? "Servicer"
                            : null;

            if (type) {
                formik2.setFieldValue("type", type);
            }
        },
    });

    useEffect(() => {

        fetchSenderData(props.flag);

    }, [props.flag]); // Dependency ensures API is called when type changes

    const fetchSenderData = async (value) => {
        const data = {
            role: value,
            roleId:
                value === "Super Admin" ? "idset" :
                    value === "Dealer" ? props.data.dealerId :
                        value === "Reseller" ? props.data.resellerId :
                            value === "Customer" ? props.data.customerId :
                                props.data.servicerId,
        };
        try {
            const response = await getSender(data);
            if (response.result?.length) {
                const addresses = response.result.map((address) => ({
                    label: `${address.metaData[0].firstName} ${address.metaData[0].lastName}`,
                    value: address.metaData[0]._id,
                }));

                console.log("API Response:", addresses);
                setSenderList(addresses);
            }
        } catch (error) {
            console.error("Error calling API:", error);
        }
    };

    const handleChange2 = async (name, value) => {
        formik2.setFieldValue(name, value);
        fetchSenderData(value); // Call the function inside handleChange2 as well
    };
    const handleChange = (name, value) => {
        formik2.setFieldValue(name, value);
    };


    const fileInputRef = useRef(null);
    const [fileType, setFileType] = useState(null);

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleInputClick = (event) => {
        setPreviewImage((prevFileValue) => {
            return null;
        });
        event.currentTarget.value = null;
        formik2.setFieldValue(`messageFile`, {});
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            const formData = new FormData();
            formData.append("file", file);

            addUploadCommentImage(formData).then((res) => {
                formik2.setFieldValue("messageFile", res.messageFile);
            });
            reader.onload = (event) => {
                // Get the data URL of the selected file
                const imageDataUrl = event.target.result;

                // Update the preview image state
                setPreviewImage(imageDataUrl);
                const fileType = getFileType(file.type);

                setFileType(fileType);
            };
            reader.readAsDataURL(file);
        }
    };

    const getFileType = (fileType) => {
        if (fileType.includes("image")) {
            return "image";
        } else if (fileType.includes("pdf")) {
            return "pdf";
        } else if (
            fileType.includes("spreadsheetml.sheet") ||
            fileType.includes("excel")
        ) {
            return "xlsx";
        } else if (fileType.includes("csv")) {
            return "csv";
        } else if (fileType.includes("ms-excel")) {
            return "xls";
        } else {
            return "file";
        }
    };

    console.log(sendto, "----------sendto");
    console.log(props.flag, "----------sendto");
    const filteredOptions = sendto.filter(option => option.value === props.flag);
    console.log(filteredOptions, "----------Option");
    // Ensure the selected value exists in the filtered options
    const selectedValue = filteredOptions.find(option => option.value === formik2.values.type) || null;


    return (
        <Card className="mt-6 border-[1px] border-Light-Grey rounded-xl shadow !px-[16px]">
            <p className="text-xl font-semibold">
                Comments Details
            </p>


            <div>
                <div className='min-h-[50vh] max-h-[60vh] overflow-y-scroll'>
                    {messageList && messageList.length != 0 ? (
                        messageList.map((msg, key) => (
                            <>
                                {msg.selfMessage === true ?
                                    <Grid className="my-3">

                                        <div className="col-span-11">
                                            <SingleView className=" rounded-md relative p-1">
                                                <div
                                                    style={{
                                                        maskImage: `url(${arrowImage})`,
                                                        WebkitMaskImage: `url(${arrowImage})`,
                                                        maskRepeat: "no-repeat",
                                                        WebkitMaskRepeat: "no-repeat",
                                                        maskPosition: "center",
                                                        WebkitMaskPosition: "center",
                                                        maskSize: "contain",
                                                        WebkitMaskSize: "contain",
                                                    }}
                                                    className="self-center absolute singleViewsbg -right-3 rotate-[90deg] top-2 h-4 w-4 "
                                                />
                                                <Grid>
                                                    {msg.messageFile.originalName !== "" && (
                                                        <div
                                                            className="col-span-1 self-center text-center"
                                                            onClick={() => downloadImage(msg)}
                                                        >
                                                            <div
                                                                style={{
                                                                    maskImage: `url(${download})`,
                                                                    WebkitMaskImage: `url(${download})`,
                                                                    maskRepeat: "no-repeat",
                                                                    WebkitMaskRepeat: "no-repeat",
                                                                    maskPosition: "center",
                                                                    WebkitMaskPosition: "center",
                                                                    maskSize: "contain",
                                                                    WebkitMaskSize: "contain",
                                                                }}
                                                                className="self-center bg-white top-2 h-5 w-5 "
                                                            />

                                                        </div>
                                                    )}
                                                    <div
                                                        className={` self-center flex justify-start ${msg.messageFile.originalName !== ""
                                                            ? "col-span-5"
                                                            : "col-span-6 text-left"
                                                            }`}
                                                    >
                                                        <p className="text-sm pr-3">
                                                            {" "}
                                                            {format(
                                                                new Date(msg.date ? msg?.date : new Date()),
                                                                "hh:mm aaaaa'm'"
                                                            )}
                                                        </p>
                                                        <p className="text-sm">
                                                            {format(
                                                                new Date(msg.date ? msg?.date : new Date()),
                                                                "MM/dd/yyyy"
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="col-span-6 text-right">
                                                        <p className="text-xl font-semibold">
                                                            {msg.commentBy.firstName}{" "}
                                                            {msg.commentBy.lastName}
                                                            <span className="text-[12px] pl-1">
                                                                ({msg.commentBy?.roles?.role})
                                                            </span>
                                                        </p>
                                                    </div>


                                                </Grid>
                                                <hr className="my-2" />
                                                <p className="text-sm text-right">{msg.content}</p>
                                                <p className="text-left">
                                                    <span className="text-[11px]">(To {msg.type})</span>
                                                </p>
                                            </SingleView>
                                        </div>
                                        <div className="col-span-1">
                                            <div className="bg-light-black border-2 w-12 h-12 flex justify-center border-Light-Grey rounded-full">
                                                <p className="text-white text-2xl self-center">A</p>
                                            </div>
                                        </div>
                                    </Grid>
                                    :
                                    <Grid className="my-3">
                                        <div className="col-span-1">
                                            <div className="bg-light-black border-2 w-12 h-12 flex justify-center border-Light-Grey rounded-full">
                                                <p className="text-white text-2xl self-center">A</p>
                                            </div>
                                        </div>
                                        <div className="col-span-11">
                                            <SingleView className=" rounded-md relative p-1">
                                                <div
                                                    style={{
                                                        maskImage: `url(${arrowImage})`,
                                                        WebkitMaskImage: `url(${arrowImage})`,
                                                        maskRepeat: "no-repeat",
                                                        WebkitMaskRepeat: "no-repeat",
                                                        maskPosition: "center",
                                                        WebkitMaskPosition: "center",
                                                        maskSize: "contain",
                                                        WebkitMaskSize: "contain",
                                                    }}
                                                    className="self-center absolute singleViewsbg -left-3 rotate-[270deg] top-2 h-4 w-4 "
                                                />
                                                <Grid>
                                                    <div className="col-span-6">
                                                        <p className="text-xl font-semibold">
                                                            {msg.commentBy.firstName}{" "}
                                                            {msg.commentBy.lastName}
                                                            <span className="text-[12px] pl-1">
                                                                ({msg.commentBy?.roles?.role})
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={` self-center flex justify-end ${msg.messageFile.originalName !== ""
                                                            ? "col-span-5"
                                                            : "col-span-6 text-right"
                                                            }`}
                                                    >
                                                        <p className="text-sm pr-3">
                                                            {" "}
                                                            {format(
                                                                new Date(msg.date ? msg?.date : new Date()),
                                                                "hh:mm aaaaa'm'"
                                                            )}
                                                        </p>
                                                        <p className="text-sm">
                                                            {format(
                                                                new Date(msg.date ? msg?.date : new Date()),
                                                                "MM/dd/yyyy"
                                                            )}
                                                        </p>
                                                    </div>
                                                    {msg.messageFile.originalName !== "" && (
                                                        <div
                                                            className="col-span-1 self-center text-center"
                                                            onClick={() => downloadImage(msg)}
                                                        >
                                                            <img
                                                                src={download}
                                                                className="w-5 h-5 mx-auto cursor-pointer"
                                                                alt="download"
                                                            />
                                                        </div>
                                                    )}
                                                </Grid>
                                                <hr className="my-2" />
                                                <p className="text-sm">{msg.content}</p>
                                                <p className="text-right">
                                                    <span className="text-[11px]">(To {msg.type})</span>
                                                </p>
                                            </SingleView>
                                        </div>
                                    </Grid>
                                }

                            </>
                        ))
                    ) : (
                        <p className="text-center">No Record Found</p>
                    )}
                </div>
                <hr />
            </div>
            <form onSubmit={formik2.handleSubmit}>
                <Grid>
                    <div className='col-span-12 mt-3'>
                        <p className="text-sm">
                            <b> Attachment : </b>{" "}
                            <span className="">
                                {" "}
                                Accepted file types: jpg, pdf, jpeg, doc, xls, xlxs, png, Max.
                                file size: 50 MB.{" "}
                            </span>
                        </p>
                    </div>
                    <div className="col-span-5">
                        <Select
                            name="type"
                            options={sendto}
                            disableFirstOption={true}
                            placeholder=""
                            label='Sender Name'
                            className="!bg-white "
                            classBox="w-full self-center"
                            className1="!p-2 w-full !pr-[40px]"
                            value={formik2.values.type}
                            onChange={handleChange2}
                            onBlur={formik2.handleBlur}
                        />
                    </div>
                    <div className="col-span-7">
                        <Select
                            name="receiverId"
                            options={senderList}
                            disableFirstOption={true}
                            placeholder=""
                            label='Sender User Name'
                            className="!bg-white "
                            classBox="w-full self-center"
                            className1="!p-2 w-full !pr-[40px]"
                            value={formik2.values.receiverId}
                            onChange={handleChange}
                            onBlur={formik2.handleBlur}
                        />
                    </div>
                    <div className="col-span-12 ">
                        <textarea
                            id="content"
                            rows="3"
                            name="content"
                            maxLength={150}
                            className={`
                                block px-2.5 pb-2.5 pt-1.5 w-full font-semibold text-light-black bg-transparent rounded-lg border-[1px] bg-white border-gray-300 appearance-none peer resize-none text-sm
                                `}
                            value={formik2.values.content}
                            onChange={formik2.handleChange}
                            onBlur={formik2.handleBlur}
                            placeholder='Enter Message'
                        ></textarea>
                    </div>
                    <div className="col-span-1">
                        <div className="border bg-white flex justify-center relative">

                            <img
                                src={upload}
                                className="self-center bg-white py-3 cursor-pointer"
                                alt="upload"
                                onClick={handleImageClick}
                            />

                            {/* Hidden file input */}
                            <input
                                type="file"
                                ref={fileInputRef}
                                multiple
                                accept=".jpg, .jpeg, .png, .csv, .pdf, .xls, .xlsx"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                                onClick={(event) => handleInputClick(event)}
                            />
                        </div>
                    </div>
                    <div className='col-span-6'>
                        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                            <div className='relative'>
                                {previewImage != null &&
                                    <div className="absolute -top-2 -right-2">
                                        <img
                                            src={Cross}
                                            alt="Preview"
                                            className="cursor-pointer "
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                marginTop: "5px",
                                            }}
                                            onClick={() => {
                                                setPreviewImage("");
                                                formik2.setFieldValue("messageFile", {});
                                            }}
                                        />
                                    </div>}

                                {fileType === "image" && (
                                    <img
                                        src={previewImage}
                                        alt="Preview"
                                        className="w-full h-auto"
                                    />
                                )}
                                {fileType === "pdf" && (
                                    <FontAwesomeIcon icon={faFilePdf} size="4x" />
                                )}
                                {(fileType === "csv" ||
                                    fileType === "xlsx" ||
                                    fileType === "xls") && (
                                        <FontAwesomeIcon icon={faFileImage} size="4x" />
                                    )}
                                {fileType === "word" && (
                                    <FontAwesomeIcon icon={faFileWord} size="4x" />
                                )}
                                {fileType === "excel" && (
                                    <FontAwesomeIcon icon={faFileExcel} size="4x" />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-5 self-center text-right">
                        <Button type="submit" className="self-center">
                            <img
                                src={Sendto}
                                className="self-center w-6 h-6 mr-2"
                                alt="Sendto"
                            />
                        </Button>
                    </div>
                </Grid>
            </form>

        </Card>
    )
}

export default Comment
